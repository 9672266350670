import React from 'react';
import Product_img1 from '../../images/icons/ico_people.png';
import product_img2 from '../../images/icons/ico_van.png';
//import product_img3 from '../../images/icons/ico_phone.png';
import { BrowserView, MobileOnlyView, TabletView, isIOS } from 'react-device-detect';


function ProductSupport() {
  return (
    <>
      <BrowserView>
        <div   className={(isIOS ? 'IpadViewNone' : 'product-support-box')}>
          <div className="product-support-sub" id="product-support-sub-first">
            <h4>
              <img src={Product_img1} height="50px" alt="Product" className="productSupportImg" />
              Contact Us
            </h4>
            <p>
              Get in touch with our friendly Customer Engagement Team using the below details. Our
              team are happy to take your call Monday to Friday, 9am to 5pm.
            </p>
            <a href="/contact-us" target="_blank" rel="noreferrer">
            <button className="btn-button">
                Contact us
            </button>
            </a>
          </div>
          <div className="product-support-sub" id="product-support-sub-second">
            <div className="product-support-sub-box">
              <h4>
                <img src={product_img2} height="25px" alt="Product" className="productSupportImg" />
                <span className="book-label">Book and Track</span>
              </h4>
              <div className="book-and-track"/>
              <p>If you have placed an order that is being fulfilled 
                by the Product Care Group, book your delivery slot or track your order here.</p>
                <a href="https://myorder.productcaregroup.com/login" target="_blank" rel="noreferrer">
                <button className="book-btn">
                  Book or track your delivery
              </button>
              </a>
            </div>
          </div>
        </div>
      </BrowserView>
      <TabletView>
        <div className="product-support-box">
          <div className="product-support-sub" id="product-support-sub-first">
            <h4>
              <img src={Product_img1} height="50px" alt="Product" className="productSupportImg" />
              Contact Us
            </h4>
            <p>
              Get in touch with our friendly Customer Engagement Team using the below details. Our
              team are happy to take your call Monday to Friday, 9am to 5pm.
            </p>
            <a href="/contact-us" target="_blank" rel="noreferrer">
            <button className="btn-button">
                Contact us
            </button>
            </a>
          </div>
          <div className="product-support-sub" id="product-support-sub-second">
            <div className="product-support-sub-box">
              <h4>
                <img src={product_img2} height="25px" alt="Product" className="productSupportImg" />
                <span className="book-label">Book and Track</span>
              </h4>
              <div className="book-and-track"/>
              <p>If you have placed an order that is being fulfilled 
                by the Product Care Group, book your delivery slot or track your order here.</p>
                <a href="https://myorder.productcaregroup.com/login" target="_blank" rel="noreferrer">
                <button className="book-btn">
                  Book or track your delivery
              </button>
              </a>
            </div>
          </div>
        </div>
      </TabletView>
      <MobileOnlyView>
        <div className="product-support-box">
          <div className="product-support-sub" id="product-support-sub-first">
            <h4>
              <img src={Product_img1} height="50px" alt="Product" className="productSupportImg" />
              Contact Us
            </h4>
            <p>
              Get in touch with our friendly Customer Engagement Team using the below details. Our
              team are happy to take your call Monday to Friday, 9am to 5pm.
            </p>
            <a href="/contact-us" target="_blank" rel="noreferrer">
            <button>
                Contact us
                </button>
              </a>
           
          </div>
          <div className="product-support-sub" id="product-support-sub-second">
            <div className="product-support-sub-box">
              <h4>
                <img src={product_img2} height="25px" alt="Product" className="productSupportImg" />
                <span className="book-label">Book and Track</span>
              </h4>
              <p>If you have placed an order that is being fulfilled 
                by the Product Care Group, book your delivery slot or track your order here.</p>
                <a href="https://myorder.productcaregroup.com/login" target="_blank" rel="noreferrer">
                <button className="book-btn">
                  Book or track your delivery
              </button>
              </a>
            </div>
          </div>
        </div>
      </MobileOnlyView>
    </>
  );
}

export default ProductSupport;
