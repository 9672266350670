import React from 'react';
import NavigationBar from "./navigation-bar";
import Banner from "../about-us/banner";
import red_bg from '../../images/backgrounds/bg_purple.png';
import {Link} from "react-router-dom";
function NotFound() {
  return (
      <div>
          <NavigationBar />
          <Banner />
          <section id="about-content" style={{ backgroundImage: 'url('+red_bg+')' }}>
              <div className="about-box">
                  <h4 className="custom-color">The page you were looking for was not found!</h4>
                  <p className="custom-color">
                      Please use the links above or in the footer to navigate our site and locate what you were looking for.
                  </p>
                  <p className="custom-color">
                      If you are still struggling, feel free to <Link to="/contact-us" style={{ color: 'white', fontWeight: 500 }}>contact us</Link>.
                  </p>
              </div>
          </section>
      </div>
  );
}

export default NotFound;
