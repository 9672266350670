import React from 'react';
import Banner from './banner';
import Booking from './booking';
import BannerContent from './banner-content';
import FindDeliveries from './find-deliveries';
import Services from './services';
import Tracking from './tracking';
import AboutUs from './about-us';
import Offer from './offer';
import CustomerExp from './customer-exp';
import Ratings from './ratings';
import NavigationBar from '../common/navigation-bar';
//import Reviews from '../common/reviews';
import TrustPilotWidget from '../trust-pilot-widget/trustPilotWidget';
function Home() {
  return (
    <div>
      <NavigationBar />
      <Banner />
      <BannerContent />
      <div className="about-us-box">
        <div className="item">
          <h4 className="custom-color">About Our Deliveries</h4>
        </div>
        <AboutUs />
        <Tracking />
      </div>
      <FindDeliveries />
      <div className="about-us-box">
        <Services />
        <Offer />
        <CustomerExp />
        <Ratings />
        {/* <Reviews /> */}
        <TrustPilotWidget/>
      </div>
      <Booking />
    </div>
  );
}

export default Home;
