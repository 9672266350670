import React from 'react';
import ArrowRight from '../../images/icons/arrow-right.png';
// import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function AdditionalServices() {
  return (
    <div className="additional-services-box">
      <h4>Additional Services</h4>
      <p>
        {' '}
        Services can be purchased through our delivery booking portal (restrictions may apply). If
        you have asked us to remove your old appliance, we’ve listed some things that you will need
        to do in order for us to take it with us when we leave:
      </p>
      <h6>
        {/* <FontAwesomeIcon icon={faArrowAltCircleRight} className="additional-services-box-icon" /> */}
        <img src={ArrowRight} alt="arrow-right" className="additional-services-box-icon" />
        <b className="popinFontS4">Washing Machines, Tumble Dryers, and Dishwashers:</b> Make sure
        they have been emptied, drained, and disconnected.
      </h6>
      <h6>
        {/* <FontAwesomeIcon icon={faArrowAltCircleRight} className="additional-services-box-icon" /> */}
        <img src={ArrowRight} alt="arrow-right" className="additional-services-box-icon" />
        <b className="popinFontS4">Refrigeration:</b> Make sure that they have been emptied,
        disconnected and where applicable, defrosted. Unfortunately, our Home Delivery Team will not
        be able to take away an old appliance if it has not been defrosted.
      </h6>
      <h6>
        {/* <FontAwesomeIcon icon={faArrowAltCircleRight} className="additional-services-box-icon" /> */}
        <img src={ArrowRight} alt="arrow-right" className="additional-services-box-icon" />
        <b className="popinFontS4">Cookers or Hard-wired Appliances: </b>Make sure they have been
        safely disconnected.
      </h6>
      <h6>
        {/* <FontAwesomeIcon icon={faArrowAltCircleRight} className="additional-services-box-icon" /> */}
        <img src={ArrowRight} alt="arrow-right" className="additional-services-box-icon" />
        <b className="popinFontS4">Built-in Appliances: </b>Make sure that they have been
        disconnected, removed from the unit and where applicable, detached from the door.
      </h6>
    </div>
  );
}

export default AdditionalServices;
