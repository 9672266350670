import React from 'react';
import Banner from './banner';
import OurBrand from './our-brand';
// import Rateing from '../common/reviews';
import Team from './team';
import Delivery from './delivery';
import Journey from './Journey';
import Challenging from './challenging';
import NavigationBar from '../common/navigation-bar';
import TrustPilotWidget from '../trust-pilot-widget/trustPilotWidget';


function Home() {
  return (
    <div>
      <NavigationBar />
      <div>
        <Banner />
      </div>

      <div className="about-us-box">
        <Challenging />
      </div>
      <Journey />
      <div className="about-us-box">
        <Team />
        {/* <Delivery /> */}
        {/* <Rateing /> */}
        <TrustPilotWidget/>
      </div>
      <OurBrand />
    </div>
  );
}

export default Home;
