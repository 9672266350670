import React, { Component } from "react";
import yesImg from "../../images/yes.png";
import NewContact from "./NewContact";
import Support from "./Support";
import ProductSupportSub from "./productSupportSub";

class SupportSub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showcontactus: false,
      showSupport: false,
      showproductRegistration: false,
      showproductSupportSub: false,
      brands: [],
    };
  }

  hideComponent(name) {
    switch (name) {
      case "showcontactus":
        this.setState({ showcontactus: !this.state.showcontactus });
        break;
      case "showSupport":
        this.setState({ showSupport: !this.state.showSupport });
        break;
      case "showproductSupportSub":
        this.setState({
          showproductSupportSub: !this.state.showproductSupportSub,
        });
        break;
      default:
        return null;
    }
  }
  render() {
    const { showSupport, showcontactus, showproductSupportSub } = this.state;
    return (
      <div>
        <div>
          {showcontactus && <NewContact />}
          {showSupport && <Support />}
          {showproductSupportSub && (
            <ProductSupportSub brand={this.props.brand} productRegistration={this.props.productRegistration}/>
          )}
        </div>
        {!showcontactus && !showSupport && !showproductSupportSub && (
          <div>
            <div>
              <article className="decision-tree">
                <div aria-hidden="false" className="delivery-decision">
                  <img src={yesImg} alt="yes" className="yesImg" />
                  <h6 className="deliveryText">Product Query</h6>
                  <button
                    className="change-box mobile-subsupport"
                    onClick={() =>
                      this.setState({ showcontactus: !showcontactus })
                    }
                  >
                    Change
                  </button>
                </div>
                <div
                  aria-hidden="false"
                  className="delivery-decision supportSub-decision"
                >
                  <img src={yesImg} alt="yes" className="yesImg" />
                  <h6 className="deliveryText font-weight-bold">
                    {this.props.brand}
                  </h6>
                  <button
                    className="change-box brand-change mobile-brand"
                    onClick={() => this.setState({ showSupport: !showSupport })}
                  >
                    Change
                  </button>
                </div>
              </article>
            </div>
            <div className="sub-support">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-5 support-box">
                    <span className="contact-txt">
                      {(this.props.productRegistration === '') ?
                        (<button
                          className="contact-box-sub sub-product-box"
                          onClick={(e) => {
                            if (this.props.productRegistration === '') {
                              document.getElementById('additional-message-possition').innerHTML =
                                `Good news! You don't need to register your ${this.props.brand} product.</br></br>
                          Make sure you keep a copy of your proof of purchase and if you have any issues with your product within 12 months of your purchase, please contact us.`;
                            } else {
                              document.getElementById('additional-message-possition').innerHTML = '';
                            }
                          }}
                        >
                         <p>Product Registration</p> 
                        </button>) :
                        (<a
                          href={this.props.productRegistration}
                          className="brandList-styles-supoortSub"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <button className="contact-box-sub sub-product-box"><p>Product Registration</p></button>
                        </a>)
                      }
                    </span>
                </div>
                <div className="col-md-5 support-box">
                  <button
                    className="contact-box-sub sub-product-box"
                    onClick={() =>
                      this.setState({
                        showproductSupportSub: !showproductSupportSub,
                      })
                    }
                  >
                    <span className="contact-txt"><p>Product Support</p></span>
                  </button>
                </div>
              </div>
            </div>
            <div className="additional-message-possition" id="additional-message-possition">
              <h6>{""}</h6>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SupportSub;
