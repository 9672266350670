import React from 'react';
import InformationSecurity1 from '../../images/page-ethical-social-resposinility/iso27001.png';
import HeadImg from '../../images/icons/ico_pad.png';
function InformationSecurity() {
  return (
    <div className="information-security-box">
      <div className="information-security-sub">
        <h4>
          <img src={HeadImg} alt="HeadImg" className="HeadImg" /> Information Security
        </h4>
        <p>
          We are committed to preserving the confidentiality, integrity and availability of all
          information and informational assets within our organisation, as well as complying with
          all applicable legislatory requirements such as GDPR and the Data Protection Act 2018. Our
          management system for Information Security has been subject to 3rd party verification and
          is certified as meeting the requirements of ISO/IEC 27001: 2013, which is the
          international standard for Information Security Management systems.
        </p>
      </div>
      <div className="information-security-sub">
        <div className="information-security-img">
          <img
            src={InformationSecurity1}
            className="InformationSecurity"
            alt="InformationSecurity"
          />
        </div>
      </div>
    </div>
  );
}

export default InformationSecurity;
