import React from 'react';
import OurBrands from '../../images/page-our-brands/products-collage.jpg';
import OurBrandsMobile from '../../images/page-our-brands/products-collage-mobile.jpg';
import { BrowserView, MobileView, isIOS } from 'react-device-detect';


function OurBrandsImage() {
  return (
    <>
      <BrowserView>
        <div  className={(isIOS ? 'IpadViewNone' : 'our-brand-img-box')}>
       
          <img src={OurBrands} alt="OurBrands"  width="90%" />
        </div>
      </BrowserView>
      <MobileView>
        <div className="our-brand-img-box">
          <img src={OurBrandsMobile} alt="OurBrands" width="90%" />
        </div>
      </MobileView>
    </>
  );
}

export default OurBrandsImage;
