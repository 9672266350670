import React from 'react';
import OurBrandWeb from '../../images/banners/our-brands.jpg';
import OurBrandMobile from '../../images/banners/our-brands-mobile.jpg';
import { BrowserView, MobileOnlyView, TabletView, isIOS } from 'react-device-detect';


function Banner() {
 
  return (
    <>
      <BrowserView>
        <section  className={(isIOS ? 'IpadViewNone' : 'our-brand-banner-box')}>
       
          <div className="our-brand-banner">
            <img src={OurBrandWeb} alt="img" width="100%" height="300px" className="our-brand-img " />
            {/* <div className="our-brand-banner-sub popinFontS1">OUR BRANDS</div> */}
          </div>
        </section>
      </BrowserView>
      <TabletView>
      <section className="our-brand-banner-box">
          <div className="our-brand-banner">
            <img src={OurBrandWeb} alt="img" width="100%" height="300px" className="our-brand-img" />
            {/* <div className="our-brand-banner-sub popinFontS1">OUR BRANDS</div> */}
          </div>
        </section>
      </TabletView>
      <MobileOnlyView>
        <section className="our-brand-banner-box">
          <div className="our-brand-banner">
            <img
              src={OurBrandMobile}
              alt="img"
              width="100%"
              height="100%"
              className="our-brand-img"
            />
            {/* <div className="our-brand-banner-sub popinFontS1">OUR BRANDS</div> */}
          </div>
        </section>
      </MobileOnlyView>
    </>
  );
}


export default Banner;
