import React from 'react'; //
import DistributionPartner_img from '../../images/page-our-brands/distribution.jpg';
import DistributionPartner_img1 from '../../images/brands/distribution-partners/distribution-partners-2022.png';
// import DistributionPartner_img3 from '../../images/page-our-brands/DISTRIBUTIONPARTNERS/point.png';
// import DistributionPartner_img8 from '../../images/brands/distribution-partners/whirlpool.png';
import DistributionPartner_img9 from '../../images/brands/distribution-partners/neff.png';
// import DistributionPartner_img10 from '../../images/brands/distribution-partners/indesit.jpg';
function DistributionPartners() {
  return (
    <div className="distribution-partners-box">
      <div className="distribution-partners-box-sub">
        <img
          src={ DistributionPartner_img }
          width="90%"
          alt="LicensedBrands_img"
          className="LicensedBrands-img"
        />
      </div>
      <div className="distribution-partners-box-sub distribution-partners-box-sec">
        <div className="distribution-partners-box-sub-sub ">
          <h4>Distribution Partners</h4>
          <p>
            We provide unique service solutions to a number of leading appliance brands, capitalising on our extensive account management capabilities to provide incremental growth, or by utilising our first-class white glove home delivery solution.
          </p>
          <p>
            Our custom supply chain and warehouse management systems empower us to accurately handle all our clients’ inventory and order management needs.
          </p>
          <p>
            We pride ourselves on our ability to integrate quickly and efficiently.
          </p>
          <img
            src={ DistributionPartner_img1 }
            alt="Distribution Partners"
            width="100%"
            height="100%"
            className="DistributionPartner_logo"
          />
        </div>
      </div>
    </div>
  );
}

export default DistributionPartners;
