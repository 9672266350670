import React from 'react';
import DerianHouse1 from '../../images/page-ethical-social-resposinility/photo_derian.jpg';
import HeadImg from '../../images/icons/ico_house.png';
import { BrowserView, MobileOnlyView, TabletView, isIOS } from 'react-device-detect';

function DerianHouse() {
  return (
    <>
      <BrowserView>
        <div  className={(isIOS ? 'IpadViewNone' : 'derian-house-box')}>
       
          <div className="derian-house-sub">
            <h4>
              <img src={HeadImg} alt="HeadImg" className="HeadImg" />
              2015 Derian House
            </h4>
            <p>
              Derian House is a charity that is very close to our hearts as the younger brother of one of our colleagues
              accessed their palliative and family support services at ‘Derian lodge’ for a number of years.
            </p>
            {/* <p>
              Derian Lodge has been able to provide these provisions and has been designed around
              the need to meet the medical, developmental and social needs of young people including
              their requirements for independence, privacy and recreation.
            </p> */}
            <p>
              The environment at Derian Lodge enables young people to have the freedom to choose how
              to spend their time and each young person has the opportunity to tell staff exactly
              how they would like their care to be delivered.
            </p>
          </div>
          <div className="derian-house-sub">
            <img src={DerianHouse1} alt="DerianHouse" className="DerianHouse" />
          </div>
        </div>
      </BrowserView>
      <TabletView>
        <div className="derian-house-box">
          <div className="derian-house-sub">
          <h4>
              <img src={HeadImg} alt="HeadImg" className="HeadImg" />
              2015 Derian House
            </h4>
            <p>
              Derian House is a charity that is very close to our hearts as the younger brother of one of our colleagues
              accessed their palliative and family support services at ‘Derian lodge’ for a number of years.
            </p>
            {/* <p>
              Derian Lodge has been able to provide these provisions and has been designed around
              the need to meet the medical, developmental and social needs of young people including
              their requirements for independence, privacy and recreation.
            </p> */}
            <p>
              The environment at Derian Lodge enables young people to have the freedom to choose how
              to spend their time and each young person has the opportunity to tell staff exactly
              how they would like their care to be delivered.
            </p>
          </div>
          <div className="derian-house-sub">
            <img src={DerianHouse1} alt="DerianHouse" className="DerianHouse" />
          </div>
        </div>
      </TabletView>
      <MobileOnlyView>
        <div className="derian-house-box">
          <div className="derian-house-sub">
          <h4>
              <img src={HeadImg} alt="HeadImg" className="HeadImg" />
              2015 Derian House
            </h4>
            <p>
              Derian House is a charity that is very close to our hearts as the younger brother of one of our colleagues
              accessed their palliative and family support services at ‘Derian lodge’ for a number of years.
            </p>
            {/* <p>
              Derian Lodge has been able to provide these provisions and has been designed around
              the need to meet the medical, developmental and social needs of young people including
              their requirements for independence, privacy and recreation.
            </p> */}
            <p>
              The environment at Derian Lodge enables young people to have the freedom to choose how
              to spend their time and each young person has the opportunity to tell staff exactly
              how they would like their care to be delivered.
            </p>
          </div>
        </div>
      </MobileOnlyView>
    </>
  );
}

export default DerianHouse;
