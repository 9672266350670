import React from 'react';
import { Link } from 'react-router-dom';

function Booking() {
  return (
    <section id="booking-box">
      <div className="booking-txt custom-name-color ">
        See more about booking a delivery and product support
      </div>
      <Link to="/customer-bookings">
        {' '}
        <button className="booking-btn">Customer bookings and support</button>
      </Link>
    </section>
  );
}

export default Booking;
