import React from 'react';
import { Link } from 'react-router-dom';
function OurBrand() {
  return (
    <section id="booking-box-our-brand">
      <div className="popinFontS2 custom-name-color ">Want to know more about our Brands?</div>
      <Link to="/our-brands">
        {' '}
        <button className="booking-btn">Our Brands</button>
      </Link>
    </section>
  );
}

export default OurBrand;
