import React, { useState } from 'react';
import john_cohring from '../../images/board/john-cohring.jpg';
import darren_veevers from '../../images/board/darren-veevers.jpg';
import gavin_hamer from '../../images/board/gavin-hamer.jpg';
import simone_fisher from '../../images/board/simone-fisher.jpg';
import lewis_moore from '../../images/board/lewis-moore.jpg';
import scott_benson from '../../images/board/scott-benson.jpg';
import ProfilePopup from './profile-popup';
function Director() {
  const [ isOpen, setIsOpen ] = useState(false);
  const [ title, setTitle ] = useState("")
  const [ position, setPosition ] = useState("")
  const [ profile, setProfile ] = useState("")
  const [ imgSrc, setImgSrc ] = useState("")
  const [ ulColor, setUlColor ] = useState("")

  const profileData = [
    `John joined Product Care Group in March 2020 with over 20 years of experience in the electrical and housewares industry. Since joining, John has been actively working in all areas of the business, driving growth, and shaping the company's strategic positioning. He promotes strong values of People, Trust, and Partnerships within the company’s culture and believes that people are its greatest asset. Outside of work, John is the "Dad Taxi" for his two daughters, ferrying them to and from their many clubs, events, and their ever-increasing social life! John enjoys walking his dogs, running, cycling, and hacking about on golf courses!`,
    "Darren joined Product Care Group in early 2019 bringing with him a wealth of experience. He is a qualified Accountant having spent 7 years in the accounting profession followed by 20+ years as Finance Director/CFO in Financial Services and Tech Businesses. Darren is married with a grown-up son, a keen competitive badminton player and a lifelong Liverpool fan.",
    "Gavin is one of the original founders of the group of companies and has over 20 years of experience working in the electronics industry. He values the business and its people as an extension of his family and often calls it his third child, being a father of twin girls. In 2021 Gavin emigrated to New Zealand with his wife Olivia, twin girls, and Black Labrador Rufus. Living happily in Tauranga Gavin still plays an active, albeit remote, role in the business.",
    "Simone founded Product Care Group (formerly G2S) alongside Gavin Hamer and Steve Smith in 2009. She has over 25 years’ experience working in the electrical sector across a variety of roles and businesses. In addition to her main group board position, she is responsible for the Ethical, Social & Governance areas, with a particular focus on Sustainability. Alongside being a Board Director and founder shareholder, she is a Mum of two boys and has a lovely Labradoodle called Remi.",
    "Lewis trained and qualified as an accountant in practice spending 9 years in that sector before joining the group in early 2012. Joining initially as the group’s management accountant over the last 11 years he has worked his way up to the role of CFO. Lewis has 2 young sons who keep him busy outside of work, and when he is not running around (or cleaning up) after them he enjoys the gym and the occasional round of golf.",
    "Scott joined Product Care Group in late 2020 bringing over 20 years’ experience in the technology sphere with him. Scott has worked in some of the largest technology companies and government departments within the financial markets. Scott is married with one young child and enjoys Karate and Husky mushing."
  ]

  const togglePopup = (title, position, profile, imgSrc, ulcolor,popupState) => {
    setIsOpen(popupState);
    setTitle(title)
    setPosition(position)
    setProfile(profile)
    setImgSrc(imgSrc)
    setUlColor(ulcolor)
  }

  return (
    <section className="director-box">
      <h4 className="custom-color">Meet the Board</h4>
      <div className="director">
        <div className="director-item">
          <img src={ john_cohring } width="112.88" height="112.88" alt="John" />
          <h6 className="custom-secondary-color director-item-head-txt">John Cohring</h6>
          <ul className="ul-one"></ul>
          <p className="member-color">Chief Executive Officer</p>
          <button
            onClick={ () => togglePopup("John Cohring", "Chief Executive Officer", profileData[ 0 ], john_cohring, "ul-one",true) }
          >
            See Profile
          </button>
        </div>
        <div className="director-item">
          <img src={ darren_veevers } width="112.88" height="112.88" alt="Darren" />
          <h6 className="custom-secondary-color director-item-head-txt">Darren Veevers</h6>
          <ul className="ul-six"></ul>
          <p className="member-color">Chief Operating Officer</p>
          <button
            onClick={ () => togglePopup("Darren Veevers", "Chief Operating Officer", profileData[ 1 ], darren_veevers, "ul-six",true) }
          >
            See Profile
          </button>
        </div>
        <div className="director-item">
          <img src={ gavin_hamer } width="112.88" height="112.88" alt="Gavin" />
          <h6 className="custom-secondary-color director-item-head-txt">Gavin Hamer</h6>
          <ul className="ul-one ul-two"></ul>
          <p className="member-color">Chairman</p>
          <button
            onClick={ () => togglePopup("Gavin Hamer", "Chairman", profileData[ 2 ], gavin_hamer, "ul-one ul-two",true) }
          >
            See Profile
          </button>
        </div>
        <div className="director-item">
          <img src={ simone_fisher } width="112.88" height="112.88" alt="Simone" />
          <h6 className="custom-secondary-color director-item-head-txt">Simone Fisher</h6>
          <ul className="ul-one ul-three"></ul>
          <p className="member-color">Executive Director</p>
          <button
            onClick={ () => togglePopup("Simone Fisher", "Executive Director", profileData[ 3 ], simone_fisher, "ul-one ul-three",true) }
          >
            See Profile
          </button>
        </div>
        <div className="director-item">
          <img src={ lewis_moore } width="112.88" height="112.88" alt="Lewis" />
          <h6 className="custom-secondary-color director-item-head-txt">Lewis Moore</h6>
          <ul className="ul-one ul-four"></ul>
          <p className="member-color">Chief Finance Officer</p>
          <button
            onClick={ () => togglePopup("Lewis Moore", "Chief Finance Officer", profileData[ 4 ], lewis_moore, "ul-one ul-four",true) }
          >
            See Profile
          </button>
        </div>
        <div className="director-item">
          <img src={ scott_benson } width="112.88" height="112.88" alt="Scott" />
          <h6 className="custom-secondary-color director-item-head-txt">Scott Benson</h6>
          <ul className="ul-one ul-five"></ul>
          <p className="member-color">Chief Technology Officer</p>
          <button
            onClick={ () => togglePopup("Scott Benson", "Chief Technology Officer", profileData[ 5 ], scott_benson, "ul-one ul-five",true) }
          >
            See Profile
          </button>
        </div>
      </div>
      { isOpen && <ProfilePopup
        content={
          <div className="profile-popup-item">
            <div className="info-item">
              <img src={ imgSrc } width="112.88" height="112.88" alt="John" />
              <h6 className="custom-secondary-color director-item-head-txt">{ title }</h6>
              <ul className={ ulColor }></ul>
              <p className="member-color">{ position }</p>
            </div>
            <div className='profile'>
              <p>{ profile }</p>
            </div>
          </div> }
        handleClose={ togglePopup }
      /> }
    </section>
  );
}

export default Director;
