export const sendEmail = async (data) => {
  let requestbody = {
    from: process.env.REACT_APP_CONTACT_US_SEND_EMAIL_FROM_EMAIL,
    to: process.env.REACT_APP_CONTACT_US_SEND_EMAIL_TO_EMAILS.split(","),
    cc: [data.Email],
    replyTo: [data.Email],
    subject:
      process.env.REACT_APP_CONTACT_US_SEND_EMAIL_SUBJECT + data.DynamicSubject,
  };
  let trth = "";
  for (let x in data) {
    if (x !== "Email" && x !== "images" && x !== "DynamicSubject") {
      trth =
        trth +
        `<tr><th style="text-align: left;">${x}</th><td>: ${data[x]}</td></tr>`;
    }
  }
  if (Object.keys(data).includes("images")) {
    let attachments = [];
    for (let x in Object.keys(data["images"])) {
      attachments.push({
        filename: Object.keys(data["images"])[x],
        content: data["images"][Object.keys(data["images"])[x]],
      });
    }
    requestbody["attachments"] = attachments;
  }
  const emailBody = `<table>${trth}</table>`;
  requestbody["body"] = emailBody;
  console.log("requestBody>>>>>", requestbody)
  const response = await fetch(
    `${process.env.REACT_APP_CONTACT_US_SEND_EMAIL_URL}/send/email`,
    {
      method: "POST",
      body: JSON.stringify(requestbody),
      headers: {
        apikey: process.env.REACT_APP_CONTACT_US_SEND_EMAIL_API_KEY,
      },
    }
  );

  if (!response.ok) {
    throw new Error(`HTTP Error ${response.status}`);
  }
};
