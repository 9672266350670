import React from 'react';
import customerBannerWeb from '../../images/banners/booking-and-support.jpg'
import customerBannerMobile from '../../images/banners/booking-and-support-mobile.jpg'
import { BrowserView, MobileOnlyView, TabletView, isIOS } from 'react-device-detect';


function Banner() {
  return (
   <>
   <BrowserView>
   <section  className={(isIOS ? 'IpadViewNone' : 'customer-bookings-banner-box')}>
      <div className="customer-bookings-banner">
             <img
                src={customerBannerWeb}
                alt="what we do banner"
                width="100%"
                height="600px"
                className="our-brand-img"
              />
      </div>
    </section>
   </BrowserView>
   <TabletView>
   <section className="customer-bookings-banner-box">
      <div className="customer-bookings-banner">
             <img
                src={customerBannerWeb}
                alt="what we do banner"
                width="100%"
                height="600px"
                className="our-brand-img"
              />
      </div>
    </section>
   </TabletView>
   <MobileOnlyView>
   <section className="customer-bookings-banner-box">
      <div className="customer-bookings-banner">
      <img
                src={customerBannerMobile}
                alt="what we do banner"
                width="100%"
                height="600px"
                className="our-brand-img"
              />
      </div>
    </section>
   </MobileOnlyView>
   </>
  );
}


export default Banner;
