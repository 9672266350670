import Banner from "../about-us/banner";
import NavigationBar from '../common/navigation-bar';
import { Link } from "react-router-dom";
import React from 'react'
import './styles.scss'

export default function Cookies() {
    return (
        <div>
            <NavigationBar />
            <Banner />
            <div className="generic-page-content privacy-policy-box">
                <div className="margindata">
                    <div className="pro-text">
                        <h1>THE PRODUCT CARE GROUP COOKIES POLICY</h1>
                        <h2>1. How and why we use cookies</h2>
                        <p>1.1. Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site.</p>
                        <p>1.2. We may change this Policy from time to time. You should check this policy occasionally to ensure that you are aware of the most recent version that will apply each time you access the website</p>
                        <h2>2. What are cookies?</h2>
                        <p>2.1. A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer's hard drive.</p>
                        <p>2.2. Cookies are used for the reasons set out below. Please note that some of the functions that cookies perform can also be achieved using similar technologies (such as browser’s local storage). This Policy refers to 'cookies' throughout, however it also covers these alternate mechanisms.</p>
                        <p>2.3. The information we obtain from our use of cookies may include information about your computer (or other electronic device) such as your IP address, your browser and/or other internet log information, and personal information about you, for example when you purchase goods via our site or create an account on our site.</p>
                        <p>2.4. More information about cookies can be found at www.aboutcookies.org or www.allaboutcookies.org.</p>

                        <h2>3. The types of cookies we use</h2>
                        <p>3.1. We use the following cookies:</p>
                        <ul>
                            <li><strong>Strictly necessary cookies.</strong> These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website, use a shopping cart or make use of e-billing services.</li>
                            <li><strong>Analytical or Performance cookies.</strong> These allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.</li>
                            <li><strong>Targeting cookies.</strong> These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests</li>
                        </ul>
                        <p>3.2. We use Google Analytics in order to optimise our site and user experience. Google Analytics is a web analytics service provided by Google Inc. which uses cookies to provide us with information about the usage of our site by individual visitors, including the total number of visits, how long the visitors have stayed on our site, what pages the visitors have visited and any interactions the visitors have had with our site. Further information on Google Analytics can be found here.</p>
                        <p>3.3. You can find more information about the individual cookies we use and the purposes for which we use them in the table below:</p>

                        <table>
                            <thead>
                                <tr>
                                    <th>Cookie Type</th>
                                    <th>Cookie Provider</th>
                                    <th>Cookie Name</th>
                                    <th>Cookie Description</th>
                                    <th>Cookie Lifetime</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Strictly necessary</td>
                                    <td>Product Care Group Ltd</td>
                                    <td>_ctl</td>
                                    <td>Used to save your cookie preferences</td>
                                    <td>Session</td>
                                </tr>
                                <tr>
                                    <td>Analytical or Performance</td>
                                    <td>Google</td>
                                    <td>_ga</td>
                                    <td>Used to distinguish users</td>
                                    <td>2 years</td>
                                </tr>
                                <tr>
                                    <td>Analytical or Performance</td>
                                    <td>Google</td>
                                    <td>{`_ga_{container - id}`}</td>
                                    <td>Used to persist session state</td>
                                    <td>2 years</td>
                                </tr>
                            </tbody>
                        </table>
                        <h2>4. Third Parties</h2>
                        <p>4.1. We only use third party cookies from our trusted partners, who provide important functionality to our site and who help us improve our site, such Google Analytics.</p>
                        <p>4.2. Please note that third parties (including, for example, advertising networks and providers of external services such as web traffic analysis services) may also set cookies on our site, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies. If you want to block these external cookies, you would need to do so at the original source or through your web browser settings.</p>
                        <p>4.3. When we include links on our site to other websites, please bear in mind that these third-party websites will have their own privacy and cookie policies that will govern the use of any information you submit on those websites. We recommend you read their policies as we are not responsible or liable for the privacy practices of third parties.</p>
                        <p>4.4. We do not share the information collected by the cookies with any third parties.</p>
                        <h2>5. Accepting Cookies</h2>
                        <p>5.1. You can choose which analytical, functionality and targeting cookies we can set by clicking on the button(s):</p>
                        <ul>
                            <li><strong>Continue with necessary cookies only.</strong> Allows cookies marked as <em>Strictly nececcary</em></li>
                            <li><strong>Accept all cookies.</strong> Allows cookies marked as <em>Strictly necessary</em>, <em>Analytical or Performance</em> and <em>Targeting</em></li>
                        </ul>
                        <p>5.2. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our website.</p>
                        <h2>6. Privacy</h2>
                        <p>6.1. The information we obtain from the use of cookies will, to the extent that it is considered to be personal data, be used by us in accordance with our Privacy Policy. We do not share your personal data with other third parties except to the extent set out in our <a href="https://www.productcaregroup.com/privacy-policy" target="_new">Privacy Policy</a>. Third parties may also process your personal data on our behalf and in accordance with our instructions to provide services to us (such as hosted IT systems, payment providers and data analytics). To find out more about how we protect your privacy when using our website, please refer to our <a href="https://www.productcaregroup.com/privacy-policy" target="_new">Privacy Policy</a>.</p>
                        <hr />
                        <p><small>Last Updated: May 2024</small></p>
                    </div>
                </div>
            </div>
        </div>
    )
}
