import React from 'react';
import mark_ogden from '../../images/board/mark-ogden.jpg';
import nasrin_selant from '../../images/board/nasrin-selant.jpg';
import emma_chapman from '../../images/board/emma-chapman.jpg';
//emma-chapman.jpg
function BoardSub() {
  return (
    <section className="BoardSub-box">
      <div className="BoardSub">
        <div className="BoardSub-item">
          <img src={mark_ogden} width="70%" height="70%" alt="Mark" />
          <h6 className="custom-secondary-color">Mark Ogden</h6>
          <ul className="ul-orange"></ul>
          <p className="member-color">Buying Director</p>
        </div>
        <div className="BoardSub-item">
          <img src={nasrin_selant} width="70%" height="70%" alt="Max" />
          <h6 className="custom-secondary-color">Nasrin Selant</h6>
          <ul className="ul-one"></ul>
          <p className="member-color">Commercial Analytics Director</p>
        </div>
        <div className="BoardSub-item">
          <img src={emma_chapman} width="70%" height="70%" alt="Emma" />
          <h6 className="custom-secondary-color">Emma Danson</h6>
          <ul className="ul-one ul-two"></ul>
          <p className="member-color">HR Director</p>
        </div>
      </div>
    </section>
  );
}

export default BoardSub;
