import React from 'react';
import ProprietaryBrand from '../../images/page-our-brands/proprietary-brands.jpg';
import ProprietaryBrand_img1 from '../../images/brands/our-brands/kuhla.png';
import ProprietaryBrand_img2 from '../../images/brands/our-brands/lowry.png';
import ProprietaryBrand_img3 from '../../images/brands/our-brands/holme.png';
import ProprietaryBrand_img4 from '../../images/brands/our-brands/abode.png';
//
//
function ProprietaryBrands() {
  return (
    <div className="proprietary-brands">
      <div className="proprietary-brands-sub">
        <img
          src={ProprietaryBrand}
          width="90%"
          alt="ProprietaryBrand"
          className="proprietary-brands-sub-img"
        />
      </div>
      <div className="proprietary-brands-sub proprietary-brands-sec">
        <div className="proprietary-brands-sub-sub">
          <h4>Proprietary Brands</h4>
          <p>
            We have a portfolio of our own brands which offers us the flexibility to react quickly to
            ever-changing customer needs and market trends. Our brands provide us with the scope to
            launch a broad range of consumer products across various licensed categories.
          </p>
          <p>
            We make certain that our brands have their own distinct identity and purpose, to bring
            quality, practical solutions to homes across the UK and beyond.
          </p>
            <img
              src={ProprietaryBrand_img1}
              alt="KuhlaBrandLogos"
              width="50%"
              height="30%"
              className="ProprietaryBrand_logo"
            />
            <img
              src={ProprietaryBrand_img2}
              alt="LowryBrandLogos"
              width="50%"
              height="30%"
              className="ProprietaryBrand_logo"
            />
            <img
              src={ProprietaryBrand_img3}
              alt="HolmeBrandLogos"
              width="50%"
              height="30%"
              className="ProprietaryBrand_logo"
            />
            <img
              src={ProprietaryBrand_img4}
              alt="AbodeBrandLogos"
              width="50%"
              height="30%"
              className="ProprietaryBrand_logo"
            />
        </div>
      </div>
    </div>
  );
}

export default ProprietaryBrands;
