import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// CSS
import './App.scss';

// Components
import Footer from '../components/common/footer';
import Service from '../components/services/home';
import AboutUs from '../components/about-us/home';
import whatwedo from '../components/what-we-do/home';
import OurBrand from '../components/our-brands/home';
import NotFound from '../components/common/not-found';
import ScrollToTop from '../components/common/ScrollToTop';
import { OurPeople } from '../components/our-people/home';
import Etical from '../components/ethical-social-responsibility/home';
import Customer from '../components/customer-bookings/home';
import News from '../components/news/home';
import Message from '../components/Contact-us/home';
import PrivacyPolicy from '../components/privacy-policy/index';
import CoreManagementPolicy from '../components/core-management-system-policy/home';
import Cookies from '../components/cookie-policy';

const App = () => {
  return (
    <Router>
      <div className="App">
        <ScrollToTop />
        <Switch>
          <Route path="/services" component={Service} exact />
          <Route path="/what-we-do" component={whatwedo} exact />
          <Route path="/our-brands" component={OurBrand} exact />
          <Route path="/our-people" component={OurPeople} exact />
          <Route path="/customer-bookings" component={Customer} exact />
          <Route path="/ethical-social-responsibility" component={Etical} exact />
          <Route path={['/news', '/news/:id']} component={News} exact />
          <Route path="/" component={AboutUs} exact />
          <Route path="/contact-us" component={Message} exact />
          <Route path="/privacy-policy" component={PrivacyPolicy} exact />
          <Route path="/core-management-system-policy" component={CoreManagementPolicy} exact />
          <Route path="/cookie-policy" component={Cookies} exact />
          <Route component={NotFound} exact />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
