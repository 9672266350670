import React from 'react';
import HeadImg from '../../images/icons/ico_people3.png';
import { BrowserView, MobileOnlyView, TabletView, isIOS } from 'react-device-detect';

function Present() {
    return (
        <>
            <BrowserView>
                <div className={ (isIOS ? 'IpadViewNone' : 'present-box') }>
                    <div className="present-box-sub">
                        <h4>
                            <img src={ HeadImg } alt="HeadImg" className="HeadImg" /> 2021- Present
                        </h4>
                        <p>In  2021,  we  surveyed  our  circa.  200  colleagues  and  asked  them  to  pick  from  a  selection  of  both
                            community  and  national  charities.   As  a  result,  we  opted  to  support  Mind  and  Dementia  UK,  and
                            continue to fundraise on their behalf through both personal and group activities, including the Welsh
                            coastal  path  walking  marathon  (which  raised  over  £4,000),  The  Great  North  run  and  the  Chester
                            Marathon. We also invite colleagues to make a monthly salary sacrifice to either or both charities, if
                            they so wish.  We have managed to raise.</p>
                        <p>
                            Mind is a mental health charity, working across England & Wales. When you're experiencing a
                            mental health problem, supportive and reliable information can change your life. That's what MIND
                            do. They empower people to understand their condition and the choices available to them.
                            They believe no one should face a mental health problem alone.  They’re there for you. Whether
                            you’re stressed, depressed or in crisis. They’ll listen, give support & advice, & fight your corner.
                        </p>
                        <p>
                            Dementia UK is the specialist dementia nurse charity. Their nurses, called Admiral Nurses – who they
                            continually support and develop – provide life-changing care for families affected by all forms of
                            dementia, including Alzheimer’s disease. </p>
                        <p>
                            Their nurses are there when people need help. They have the time to listen and the knowledge to
                            solve problems. As dementia specialists, Admiral Nurses help families manage complex needs –
                            considering the person living with dementia and the people around them – and they can advise
                            other healthcare professionals.
                        </p>
                        <p>
                            For families affected by dementia, they can be a lifeline.
                        </p>
                    </div>
                </div>
            </BrowserView>
            <TabletView>
                <div className='present-box'>
                    <div className="present-box-sub">
                        <h4>
                            <img src={ HeadImg } alt="HeadImg" className="HeadImg" /> 2021- Present
                        </h4>
                        <p>In  2021,  we  surveyed  our  circa.  200  colleagues  and  asked  them  to  pick  from  a  selection  of  both
                            community  and  national  charities.   As  a  result,  we  opted  to  support  Mind  and  Dementia  UK,  and
                            continue to fundraise on their behalf through both personal and group activities, including the Welsh
                            coastal  path  walking  marathon  (which  raised  over  £4,000),  The  Great  North  run  and  the  Chester
                            Marathon. We also invite colleagues to make a monthly salary sacrifice to either or both charities, if
                            they so wish.  We have managed to raise.</p>
                        <p>
                            Mind is a mental health charity, working across England & Wales. When you're experiencing a
                            mental health problem, supportive and reliable information can change your life. That's what MIND
                            do. They empower people to understand their condition and the choices available to them.
                            They believe no one should face a mental health problem alone.  They’re there for you. Whether
                            you’re stressed, depressed or in crisis. They’ll listen, give support & advice, & fight your corner.
                        </p>
                        <p>
                            Dementia UK is the specialist dementia nurse charity. Their nurses, called Admiral Nurses – who they
                            continually support and develop – provide life-changing care for families affected by all forms of
                            dementia, including Alzheimer’s disease. </p>
                        <p>
                            Their nurses are there when people need help. They have the time to listen and the knowledge to
                            solve problems. As dementia specialists, Admiral Nurses help families manage complex needs –
                            considering the person living with dementia and the people around them – and they can advise
                            other healthcare professionals.
                        </p>
                        <p>
                            For families affected by dementia, they can be a lifeline.
                        </p>
                    </div>
                    <div className="inspire-chorley-sub">
                    </div>
                </div>
            </TabletView>
            <MobileOnlyView>
                <div className='present-box'>
                    <div className="present-box-sub">
                        <h4>
                            <img src={ HeadImg } alt="HeadImg" className="HeadImg" /> 2021- Present
                        </h4>
                        <p>In  2021,  we  surveyed  our  circa.  200  colleagues  and  asked  them  to  pick  from  a  selection  of  both
                            community  and  national  charities.   As  a  result,  we  opted  to  support  Mind  and  Dementia  UK,  and
                            continue to fundraise on their behalf through both personal and group activities, including the Welsh
                            coastal  path  walking  marathon  (which  raised  over  £4,000),  The  Great  North  run  and  the  Chester
                            Marathon. We also invite colleagues to make a monthly salary sacrifice to either or both charities, if
                            they so wish.  We have managed to raise.</p>
                        <p>
                            Mind is a mental health charity, working across England & Wales. When you're experiencing a
                            mental health problem, supportive and reliable information can change your life. That's what MIND
                            do. They empower people to understand their condition and the choices available to them.
                            They believe no one should face a mental health problem alone.  They’re there for you. Whether
                            you’re stressed, depressed or in crisis. They’ll listen, give support & advice, & fight your corner.
                        </p>
                        <p>
                            Dementia UK is the specialist dementia nurse charity. Their nurses, called Admiral Nurses – who they
                            continually support and develop – provide life-changing care for families affected by all forms of
                            dementia, including Alzheimer’s disease. </p>
                        <p>
                            Their nurses are there when people need help. They have the time to listen and the knowledge to
                            solve problems. As dementia specialists, Admiral Nurses help families manage complex needs –
                            considering the person living with dementia and the people around them – and they can advise
                            other healthcare professionals.
                        </p>
                        <p>
                            For families affected by dementia, they can be a lifeline.
                        </p>
                    </div>
                </div>
            </MobileOnlyView>
        </>
    );
}

export default Present;
