import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Delivery_1 from '../../images/icons/del_1_yellow.png';
import Delivery_2 from '../../images/icons/del_2_blue.png';
import Delivery_3 from '../../images/icons/del_3_purple.png';
import Delivery_4 from '../../images/icons/del_4_green.png';
import Delivery_5 from '../../images/icons/del_5_purple.png';
import Delivery_6 from '../../images/icons/del_6_orange.png';
import Delivery_7 from '../../images/page-customer-bookings-and-support/delivery-timeline-1.jpg';
//import Delivery_8 from '../../images/page-customer-bookings-and-support/delivery-timeline-3b.jpg';
import Delivery_9 from '../../images/page-customer-bookings-and-support/delivery-timeline-2a.jpg';
import Delivery_10 from '../../images/page-customer-bookings-and-support/delivery-timeline-3a.jpg';
import Delivery_11 from '../../images/page-customer-bookings-and-support/delivery-timeline-3b.jpg';
import Delivery_12 from '../../images/page-customer-bookings-and-support/delivery-timeline-2b.jpg';
import { BrowserView, MobileOnlyView, TabletView, isIOS } from 'react-device-detect';

function DeliveryTimeline() {
  const DeliveryTimeline = useRef();
  const history = useHistory();
  
  useEffect(() => {
    const linkState = history.location ? history.location.state : null;
    const isScroll = linkState ? linkState.isScroll : null;
    if (DeliveryTimeline.current && isScroll) {
      DeliveryTimeline.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [history.location]);
  return (
    <div className="delivery-timeline-box" id ="delivery">
      <div className="delivery-timeline-main">
        <h4>Your Delivery Timeline</h4>
        <p>
          We take pride in the service we offer to you. As home delivery experts, we always aim to
          provide you with high standards. We want your delivery experience to be a positive one.
        </p>
      </div>
      <>
        <BrowserView>
          <div className={(isIOS ? 'IpadViewNone' : 'delivery-timeline-sub-main')}>
          
            <div className="delivery-timeline-sub-box-one">
              <img src={Delivery_7} alt="Delivery_7" className="Delivery_7" width="70%" />
              <img src={Delivery_12} alt="Delivery_8" className="Delivery_8" width="30%" />
              <img src={Delivery_9} alt="Delivery_9" className="Delivery_9" width="70%" />
              <img src={Delivery_11} alt="Delivery_8" className="Delivery_11" width="70%" />
              <img src={Delivery_10} alt="Delivery_9" className="Delivery_10" width="40%" />
            </div>
            <div className="delivery-timeline-sub">
              <div className="delivery-timeline-main">
                <div className="delivery-timeline-sub-one">
                  <img src={Delivery_1} alt="Delivery_1" width="35%" />
                </div>
                <div className="delivery-timeline-sub-two">
                  <h4>Notification to Book</h4>
                  <h3>
                    You will receive an SMS/email to notify you that your order is ready to be
                    booked. Follow the link to our delivery booking portal and book in your delivery
                    date. Optional services can also be selected as required.
                  </h3>
                  <a
                    href="https://myorder.productcaregroup.com"
                    className="customer-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Book or amend your delivery here
                  </a>
                </div>
              </div>

              <div className="delivery-timeline-main">
                <div className="delivery-timeline-sub-one">
                  <img src={Delivery_2} alt="Delivery_2" width="35%" />
                </div>
                <div className="delivery-timeline-sub-two">
                  <h4>Booking Confirmation</h4>
                  <h3>
                    Once you have booked in your delivery you will receive an SMS/email confirming
                    your delivery date and pre-delivery information.
                  </h3>
                </div>
              </div>

              <div className="delivery-timeline-main" id="space-top">
                <div className="delivery-timeline-sub-one">
                  <img src={Delivery_3} alt="Delivery_1" width="35%" />
                </div>
                <div className="delivery-timeline-sub-two">
                  <h4>Tracking your Order</h4>
                  <h3>
                    The day before your delivery, you will receive an SMS/email confirming the 2-
                    hour time slot which your order will arrive on your delivery date.
                  </h3>
                  <h3>
                    The evening before your delivery date, you will also receive a reminder of your
                    delivery by SMS/email. This will include a live tracking link.
                  </h3>
                  <h3>
                    On the day of delivery, you will receive a further live tracking link by
                    SMS/email.
                  </h3>
                  <a
                    href="https://myorder.productcaregroup.com"
                    className="customer-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Track your order here
                  </a>
                </div>
              </div>

              <div className="delivery-timeline-main">
                <div className="delivery-timeline-sub-one">
                  <img src={Delivery_4} alt="Delivery_1" width="35%" />
                </div>
                <div className="delivery-timeline-sub-two">
                  <h4>Delivery Technician Call</h4>
                  <h3>
                    Approximately 1 hour before your expected delivery time, our delivery technician
                    will give you a courtesy call and confirm you are available to receive the
                    delivery.
                  </h3>
                </div>
              </div>

              <div className="delivery-timeline-main" id="space-top">
                <div className="delivery-timeline-sub-one">
                  <img src={Delivery_5} alt="Delivery_1" width="35%" />
                </div>
                <div className="delivery-timeline-sub-two">
                  <h4>Delivery</h4>
                  <h3>
                    Our delivery technician will arrive at your home to deliver your product and
                    install it (where applicable).
                  </h3>
                </div>
              </div>

              <div className="delivery-timeline-main" id="space-top">
                <div className="delivery-timeline-sub-one">
                  <img src={Delivery_6} alt="Delivery_1" width="35%" />
                </div>
                <div className="delivery-timeline-sub-two">
                  <h4>Customer Engagement Team</h4>
                  <h3>
                    Support with all post-delivery enquiries is available via our Customer
                    Engagement Team (
                    <a
                      href="/contact-us"
                      className="customer-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Contact us here
                    </a>{' '}
                    for more information).{' '}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </BrowserView>
        <TabletView>
          <div className="delivery-timeline-sub-main">
            <div className="delivery-timeline-sub-box-one"></div>
            <div className="delivery-timeline-sub">
              <div className="delivery-timeline-main">
                <div className="delivery-timeline-sub-one">
                  <img src={Delivery_1} alt="Delivery_1" width="35%" />
                </div>
                <div className="delivery-timeline-sub-two">
                  <h4>Notification to Book</h4>
                  <img src={Delivery_7} alt="Delivery_7" className="Delivery_7" width="60%" />
                  <h3>
                    You will receive an SMS/email to notify you that your order is ready to be
                    booked. Follow the link to our delivery booking portal and book in your delivery
                    date. Optional services can also be selected as required.
                  </h3>
                  <a
                    href="https://myorder.productcaregroup.com"
                    className="customer-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Book or amend your delivery here
                  </a>
                </div>
              </div>

              <div className="delivery-timeline-main">
                <div className="delivery-timeline-sub-one">
                  <img src={Delivery_2} alt="Delivery_2" width="35%" />
                </div>
                <div className="delivery-timeline-sub-two">
                  <h4>Booking Confirmation</h4>
                  <h3>
                    Once you have booked in your delivery you will receive an SMS/email confirming
                    your delivery date and pre-delivery information.
                  </h3>
                </div>
              </div>

              <div className="delivery-timeline-main" id="space-top">
                <div className="delivery-timeline-sub-one">
                  <img src={Delivery_3} alt="Delivery_1" width="35%" />
                </div>
                <div className="delivery-timeline-sub-two">
                  <h4>Tracking your Order</h4>
                  {/* <div className="delivery-timeline-sub-three"> */}
                    <img src={Delivery_12} alt="Delivery_8" className="Delivery_8" width="60%" />
                    
                  {/* </div> */}
                  <h3>
                    The day before your delivery, you will receive an SMS/email confirming the 2
                    hour time slot which your order will arrive on your delivery date.
                  </h3>
                  <h3>
                    The evening before your delivery date, you will also receive a reminder of your
                    delivery by SMS/email. This will include a live tracking link.
                  </h3>
                  <h3>
                    On the day of delivery, you will receive a further live tracking link by
                    SMS/email.
                  </h3>
                  <a
                    href="https://myorder.productcaregroup.com"
                    className="customer-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Track your order here
                  </a>
                </div>
              </div>

              <div className="delivery-timeline-main">
                <div className="delivery-timeline-sub-one">
                  <img src={Delivery_4} alt="Delivery_1" width="35%" />
                </div>
                <div className="delivery-timeline-sub-two">
                  <h4>Delivery Technician Call</h4>
                  <img src={Delivery_9} alt="Delivery_9" className="Delivery_9" width="60%" />
                  <h3>
                    Approximately 1 hour before your expected delivery time, our delivery technician
                    will give you a courtesy call and confirm you are available to receive the
                    delivery.
                  </h3>
                </div>
              </div>

              <div className="delivery-timeline-main" id="space-top">
                <div className="delivery-timeline-sub-one">
                  <img src={Delivery_5} alt="Delivery_1" width="35%" />
                </div>
                <div className="delivery-timeline-sub-two">
                  <h4>Delivery</h4>
                    <img src={Delivery_10} alt="Delivery_9" className="Delivery_10" width="60%" />
                  <h3>
                    Our delivery technician will arrive at your home to deliver your product and
                    install it (where applicable).
                  </h3>
                </div>
              </div>

              <div className="delivery-timeline-main" id="space-top">
                <div className="delivery-timeline-sub-one">
                  <img src={Delivery_6} alt="Delivery_1" width="35%" />
                </div>
                <div className="delivery-timeline-sub-two">
                  <h4>Customer Engagement Team</h4>
                  <h3>
                    Support with all post-delivery enquiries is available via our Customer
                    Engagement Team (
                    <a
                      href="/contact-us"
                      className="customer-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Contact us here
                    </a>{' '}
                    for more information).{' '}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </TabletView>
        <MobileOnlyView>
          <div className="delivery-timeline-sub-main">
            <div className="delivery-timeline-sub-box-one"></div>
            <div className="delivery-timeline-sub">
              <div className="delivery-timeline-main">
                <div className="delivery-timeline-sub-one">
                  <img src={Delivery_1} alt="Delivery_1" width="35%" />
                </div>
                <div className="delivery-timeline-sub-two">
                  <h4>Notification to Book</h4>
                  <img src={Delivery_7} alt="Delivery_7" className="Delivery_7" width="60%" />
                  <h3>
                    You will receive an SMS/email to notify you that your order is ready to be
                    booked. Follow the link to our delivery booking portal and book in your delivery
                    date. Optional services can also be selected as required.
                  </h3>
                  <a
                    href="https://myorder.productcaregroup.com"
                    className="customer-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Book or amend your delivery here
                  </a>
                </div>
              </div>

              <div className="delivery-timeline-main">
                <div className="delivery-timeline-sub-one">
                  <img src={Delivery_2} alt="Delivery_2" width="35%" />
                </div>
                <div className="delivery-timeline-sub-two">
                  <h4>Booking Confirmation</h4>
                  <h3>
                    Once you have booked in your delivery you will receive an SMS/email confirming
                    your delivery date and pre-delivery information.
                  </h3>
                </div>
              </div>

              <div className="delivery-timeline-main" id="space-top">
                <div className="delivery-timeline-sub-one">
                  <img src={Delivery_3} alt="Delivery_1" width="35%" />
                </div>
                <div className="delivery-timeline-sub-two">
                  <h4>Tracking your Order</h4>
                  {/* <div className="delivery-timeline-sub-three"> */}
                    <img src={Delivery_12} alt="Delivery_8" className="Delivery_8" width="60%" />
                    
                  {/* </div> */}
                  <h3>
                    The day before your delivery, you will receive an SMS/email confirming the 2
                    hour time slot which your order will arrive on your delivery date.
                  </h3>
                  <h3>
                    The evening before your delivery date, you will also receive a reminder of your
                    delivery by SMS/email. This will include a live tracking link.
                  </h3>
                  <h3>
                    On the day of delivery, you will receive a further live tracking link by
                    SMS/email.
                  </h3>
                  <a
                    href="https://myorder.productcaregroup.com"
                    className="customer-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Track your order here
                  </a>
                </div>
              </div>

              <div className="delivery-timeline-main">
                <div className="delivery-timeline-sub-one">
                  <img src={Delivery_4} alt="Delivery_1" width="35%" />
                </div>
                <div className="delivery-timeline-sub-two">
                  <h4>Delivery Technician Call</h4>
                  <img src={Delivery_9} alt="Delivery_9" className="Delivery_9" width="60%" />
                  <h3>
                    Approximately 1 hour before your expected delivery time, our delivery technician
                    will give you a courtesy call and confirm you are available to receive the
                    delivery.
                  </h3>
                </div>
              </div>

              <div className="delivery-timeline-main" id="space-top">
                <div className="delivery-timeline-sub-one">
                  <img src={Delivery_5} alt="Delivery_1" width="35%" />
                </div>
                <div className="delivery-timeline-sub-two">
                  <h4>Delivery</h4>
                    <img src={Delivery_10} alt="Delivery_9" className="Delivery_10 subimg" width="60%" />
                  <h3>
                    Our delivery technician will arrive at your home to deliver your product and
                    install it (where applicable).
                  </h3>
                </div>
              </div>

              <div className="delivery-timeline-main" id="space-top">
                <div className="delivery-timeline-sub-one">
                  <img src={Delivery_6} alt="Delivery_1" width="35%" />
                </div>
                <div className="delivery-timeline-sub-two">
                  <h4>Customer Engagement Team</h4>
                  <h3>
                    Support with all post-delivery enquiries is available via our Customer
                    Engagement Team (
                    <a
                      href="/contact-us"
                      className="customer-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Contact us here
                    </a>{' '}
                    for more information).{' '}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </MobileOnlyView>
      </>
    </div>
  );
}

export default DeliveryTimeline;
