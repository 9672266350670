import React from 'react';
import { Link } from 'react-router-dom';
function WhatWeDo() {
  return (
    <section id="whatwedo">
      <div className="booking-txt">Find out more about what we do</div>
      <Link to="/what-we-do">
        <button className="booking-btn">What we do</button>
      </Link>
    </section>
  );
}

export default WhatWeDo;
