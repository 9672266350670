import React from 'react';
import EthicalBannerWeb from '../../images/banners/ethical-social-resposinility.jpg';
import EthicalBannerMobile from '../../images/banners/ethical-social-resposinility-mobile.jpg';
import { BrowserView, MobileOnlyView, TabletView, isIOS } from 'react-device-detect';



function Banner() {
  return (
    <>
      <BrowserView>
        <section   className={(isIOS ? 'IpadViewNone' : 'ethical-social-banner-box')}>
      
          <div className="ethical-social-banner">
            <img
              src={EthicalBannerWeb}
              alt="img"
              width="100%"
              height="300px"
              className="our-brand-img"
            />
          </div>
        </section>
      </BrowserView>
      <TabletView>
      <section className="ethical-social-banner-box">
          <div className="ethical-social-banner">
            <img
              src={EthicalBannerWeb}
              alt="img"
              width="100%"
              height="300px"
              className="our-brand-img"
            />
          </div>
        </section>
      </TabletView>
      <MobileOnlyView>
        <section className="ethical-social-banner-box">
          <div className="ethical-social-banner">
            <img
              src={EthicalBannerMobile}
              alt="img"
              width="100%"
              height="300px"
              className="our-brand-img"
            />
          </div>
        </section>
      </MobileOnlyView>
    </>
  );
}


export default Banner;
