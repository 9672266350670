import React, { Component } from "react";
import yesImg from "../../images/yes.png";
import { Alert } from "reactstrap";
import NewContact from "./NewContact";
const { sendEmail } = require("./sendEmail");

class generalEnquiry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContactUs: false,
      userSuccess: false,
      isSubmitting: false,
      formDataDefaults: {
        Name: "",
        AddressLine1: "",
        AddressLine2: "",
        Town: "",
        Postcode: "",
        Telephone: "",
        Email: "",
        Comments: "",
        DynamicSubject: "General Enquiry",
      },
    };
  }

  hideComponent(name) {
    switch (name) {
      case "showContactUs":
        this.setState({ showContactUs: !this.state.showContactUs });
        break;
      default:
        return null;
    }
  }

  handleSubmit = async () => {
    this.setState({
      isSubmitting: true,
    });

    const validationFields = {
      name: false,
      telephone: false,
      email: false,
      comments: false,
    };

    validationFields.name = /^\s*$/.test(this.state.formDataDefaults.Name);

    validationFields.telephone = /^\s*$/.test(
      this.state.formDataDefaults.Telephone
    );

    validationFields.email =
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.state.formDataDefaults.Email
      );

    validationFields.comments = /^\s*$/.test(
      this.state.formDataDefaults.Comments
    );

    document.getElementById("message-form-name-validation").innerHTML =
      validationFields.name ? "required" : "";

    document.getElementById("message-form-telephone-validation").innerHTML =
      validationFields.telephone ? "required" : "";

    document.getElementById("message-form-email-validation").innerHTML =
      validationFields.email
        ? /^\s*$/.test(this.state.formDataDefaults.Email)
          ? "required"
          : "invalid email"
        : "";

    document.getElementById("message-form-comments-validation").innerHTML =
      validationFields.comments ? "required" : "";

    if (Object.values(validationFields).every((v) => !v)) {
      try {
        await sendEmail(this.state.formDataDefaults);
        this.setState({
          userSuccess: true,
        });
      } catch (error) {
        console.log(error);
        this.setState({
          userSuccess: false,
        });
        document.getElementById("message-form-submit-response").innerHTML =
          "Failed to send email";
      }
    }
    this.setState({
      isSubmitting: false,
    });
  };

  showSuccess() {
    const { userSuccess } = this.state;
    if (userSuccess) {
      return (
        <Alert color="success">
          <p>Thank you, our friendly team will get back to you.</p>
        </Alert>
      );
    }
  }

  render() {
    const { showContactUs } = this.state;
    return (
      <div>
        <div>{showContactUs && <NewContact />}</div>
        {!showContactUs && (
          <>
            <article className="decision-tree">
              <div aria-hidden="false" className="delivery-decision">
                <img src={yesImg} alt="yes" className="yesImg" />
                <h6 className="deliveryText generalEnquiry font-weight-bold">
                  General Enquiry
                </h6>{" "}
                <div></div>
                <button
                  className="change-box mobile-delivery"
                  onClick={() =>
                    this.setState({ showContactUs: !showContactUs })
                  }
                >
                  Change
                </button>
              </div>
            </article>
            {this.showSuccess()}
            {this.showSuccess() === undefined ? (
              <div className="box-padding box contact-us-form">
                <div className="row">
                  <div className="addBorder">
                    <div className="col-md-12 mt-3 ">
                      <div className="form-group">
                        <label htmlFor="Name" className="text-dark">
                          Name
                          <span style={{ color: "#ff0000" }}> * </span>
                          <span
                            id="message-form-name-validation"
                            className="message-form-name-validation"
                            style={{ color: "#ff0000" }}
                          ></span>
                        </label>
                        <div className="cstm-fld mb-2 mr-sm-2 mb-sm-0">
                          <input
                            type="text"
                            className="form-control"
                            data-testid="name-input"
                            required
                            onChange={(e) => {
                              const newFormData = {
                                ...this.state.formDataDefaults,
                              };
                              newFormData["Name"] = e.target.value;
                              this.setState({ formDataDefaults: newFormData });
                            }}
                            value={this.state.formDataDefaults["Name"]}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="form-group">
                          <label htmlFor="AddressLine1" className="text-dark">
                            Address Line 1
                          </label>
                          <div className="cstm-fld mb-2 mr-sm-2 mb-sm-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength=""
                              onChange={(e) => {
                                const newFormData = {
                                  ...this.state.formDataDefaults,
                                };
                                newFormData["AddressLine1"] = e.target.value;
                                this.setState({
                                  formDataDefaults: newFormData,
                                });
                              }}
                              value={
                                this.state.formDataDefaults["AddressLine1"]
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="AddressLine2" className="text-dark">
                            Address Line 2
                          </label>
                          <div className="cstm-fld mb-2 mr-sm-2 mb-sm-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength=""
                              onChange={(e) => {
                                const newFormData = {
                                  ...this.state.formDataDefaults,
                                };
                                newFormData["AddressLine2"] = e.target.value;
                                this.setState({
                                  formDataDefaults: newFormData,
                                });
                              }}
                              value={
                                this.state.formDataDefaults["AddressLine2"]
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="Town" className="text-dark">
                            Town
                          </label>
                          <div className="cstm-fld mb-2 mr-sm-2 mb-sm-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength=""
                              onChange={(e) => {
                                const newFormData = {
                                  ...this.state.formDataDefaults,
                                };
                                newFormData["Town"] = e.target.value;
                                this.setState({
                                  formDataDefaults: newFormData,
                                });
                              }}
                              value={this.state.formDataDefaults["Town"]}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="Postcode" className="text-dark">
                            Postcode
                          </label>
                          <div className="cstm-fld mb-2 mr-sm-2 mb-sm-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength=""
                              onChange={(e) => {
                                const newFormData = {
                                  ...this.state.formDataDefaults,
                                };
                                newFormData["Postcode"] = e.target.value;
                                this.setState({
                                  formDataDefaults: newFormData,
                                });
                              }}
                              value={this.state.formDataDefaults["Postcode"]}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="Telephone" className="text-dark">
                            Telephone
                            <span style={{ color: "#ff0000" }}> * </span>
                            <span
                              id="message-form-telephone-validation"
                              className="message-form-telephone-validation"
                              style={{ color: "#ff0000" }}
                            ></span>
                          </label>
                          <div className="cstm-fld mb-2 mr-sm-2 mb-sm-0">
                            <input
                              type="tel"
                              className="form-control"
                              data-testid="telephone-input"
                              required
                              onChange={(e) => {
                                const newFormData = {
                                  ...this.state.formDataDefaults,
                                };
                                newFormData["Telephone"] = e.target.value;
                                this.setState({
                                  formDataDefaults: newFormData,
                                });
                              }}
                              value={this.state.formDataDefaults["Telephone"]}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="Email" className="text-dark">
                            Email <span style={{ color: "#ff0000" }}>*</span>{" "}
                            <span
                              id="message-form-email-validation"
                              className="message-form-email-validation"
                              style={{ color: "#ff0000" }}
                            >
                              {" "}
                            </span>
                          </label>
                          <div className="cstm-fld mb-2 mr-sm-2 mb-sm-0">
                            <input
                              type="email"
                              className="form-control"
                              data-testid="email-input"
                              required
                              onChange={(e) => {
                                const newFormData = {
                                  ...this.state.formDataDefaults,
                                };
                                newFormData["Email"] = e.target.value;
                                this.setState({
                                  formDataDefaults: newFormData,
                                });
                              }}
                              value={this.state.formDataDefaults["Email"]}
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label htmlFor="Comments" className="text-dark">
                            Comments
                            <span style={{ color: "#ff0000" }}> * </span>
                            <span
                              id="message-form-comments-validation"
                              className="message-form-comments-validation"
                              style={{ color: "#ff0000" }}
                            ></span>
                          </label>
                          <div className="cstm-fld mb-2 mr-sm-2 mb-sm-0">
                            <textarea
                              id="subject"
                              name="subject"
                              data-testid="comments-input"
                              className="form-control commentArea"
                              required
                              onChange={(e) => {
                                const newFormData = {
                                  ...this.state.formDataDefaults,
                                };
                                newFormData["Comments"] = e.target.value;
                                this.setState({
                                  formDataDefaults: newFormData,
                                });
                              }}
                              value={this.state.formDataDefaults["Comments"]}
                            ></textarea>
                          </div>
                          <div className="mt-3">
                            <span style={{ color: "#ff0000" }}>*</span> Fields
                            are mandatory
                          </div>
                          <div className="message-submit mt-5">
                            <button
                              className="submit-button"
                              data-testid="submit-button"
                              onClick={this.handleSubmit}
                              disabled={this.state.isSubmitting}
                            >
                              {this.state.isSubmitting ? "SENDING" : "SEND"}
                            </button>
                            <h4
                              id="message-form-submit-response"
                              className="message-form-submit-response"
                            >
                              {" "}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
    );
  }
}

export default generalEnquiry;
