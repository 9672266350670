import React from 'react';
import jim_donaghy from '../../images/board/jim-donaghy.jpg';
import lisa_carroll from '../../images/board/lisa-carroll.jpg';
import max_patel from '../../images/board/max-patel.jpg';
import matt_maddock from '../../images/board/matt-maddock.jpg';

function Bord() {
  return (
    <section className="Board-box">
      <h4 className="custom-color">Our Directors</h4>
      <div className="Board">
        <div className="Board-item">
          <img src={jim_donaghy} width="70%" height="70%" alt="Jim" />
          <h6 className="custom-secondary-color Jim-Donaghy">Jim Donaghy</h6>
          <ul className="ul-one"></ul>
          <p className="member-color">Operations Director</p>
        </div>
        <div className="Board-item">
          <img src={lisa_carroll} width="70%" height="70%" alt="Lisa" />
          <h6 className="custom-secondary-color">Lisa Carroll</h6>
          <ul className="ul-one ul-two"></ul>
          <p className="member-color">Business Systems Director</p>
        </div>
        <div className="Board-item">
          <img src={max_patel} width="70%" height="70%" alt="Max" />
          <h6 className="custom-secondary-color Max-Patel">Max Patel</h6>
          <ul className="ul-one ul-three"></ul>
          <p className="member-color">Retail Sales Director</p>
        </div>
        <div className="Board-item">
          <img src={matt_maddock} width="70%" height="70%" alt="Matt" />
          <h6 className="custom-secondary-color Matt-Maddock">Matt Maddock</h6>
          <ul className="ul-one ul-four"></ul>
          <p className="member-color">Business Development Director</p>
        </div>
      </div>
    </section>
  );
}

export default Bord;
