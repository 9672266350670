import React from 'react';

// Components
import Banner from './banner';
import News from './news';
import NewsDetail from './news-detail';
import NavigationBar from '../common/navigation-bar';

import { Switch, Route } from 'react-router-dom';

function Home() {
  return (
    <div>
      <NavigationBar />
      <Banner />
      <div className="about-us-box">
        <Switch>
          <Route path="/news" component={News} exact />
          <Route path="/news/:id" component={NewsDetail} exact />
        </Switch>
      </div>
    </div>
  );
}

export default Home;
