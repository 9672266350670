import React from 'react';
import img_1 from '../../images/icons/ico_bagge.png';
function WorkLife() {
  return (
    <div className="work-life-box">
      <h4 className="popinFontH2">
        <img src={img_1} alt="work" />
        Work-life Balance and Benefits{' '}
      </h4>
      <p className="popinFontH6">
        We acknowledge that our people are most engaged when they find a balance that provides both job
        satisfaction and personal fulfilment outside of work.
        We provide a hybrid working environment allowing for colleagues to be highly productive where they work best, whilst creating the opportunity for collaboration and efficiency across teams.
      </p>
      <p>
        <b className="popinFontH4">
          To encourage a positive work-life balance, we provide a selection of benefits to employees
          which aim to support this:
        </b>
      </p>
      <ul>
        <li>
          <span className="popinFontH5">Hybrid flexible working (including working from home)</span>
        </li>
        <li>
          <span className="popinFontH5">
            Generous annual leave entitlements and long service awards
          </span>
        </li>
        <li>
          <span className="popinFontH5">
            Regular team social and charity fundraising events throughout the year
          </span>
        </li>
        <li>
          <span className="popinFontH5">Cash health care plan</span>
        </li>
        <li>
          <span className="popinFontH5">Cycle to work scheme</span>
        </li>
        <li>
          <span className="popinFontH5">Enhanced maternity/paternity leave</span>
        </li>
        <li>
          <span className="popinFontH5">Contributory pension</span>
        </li>
        <li>
          <span className="popinFontH5">Life Cover (Death in Service Payments)</span>
        </li>
        <li>
          <span className="popinFontH5">Company bonus (discretionary based on performance)</span>
        </li>
      </ul>
    </div>
  );
}

export default WorkLife;
