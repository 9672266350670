import React from 'react';


function DeliveryBox() {
  return (
    <section className="delivery-box-customer">
      <h4>Your Delivery Timeline</h4>
      <p>To find out more about delivery journey...</p>
      <a href='#delivery'>
      <button>
        {' '}
          Click here
      </button>
      </a>
    </section>
  );
}

export default DeliveryBox;
