import React from 'react';
import BoltonNeonatal1 from '../../images/page-ethical-social-resposinility/photo_hospital_1.jpg';
import HeadImg from '../../images/icons/ico_pram.png';
import { BrowserView, MobileOnlyView, TabletView, isIOS } from 'react-device-detect';
import pdf from '../../images/icons/icon_pdf.png';
import { Link } from 'react-router-dom';

function BoltonNeonatal() {
  return (
    <>
      <BrowserView>
        <div  className={(isIOS ? 'IpadViewNone' : 'bolton-neonatal-box')}>
       
          <div className="bolton-neonatal-sub">
            <div className="bolton-style">
              <h4>
                <img src={HeadImg} alt="HeadImg" className="HeadImg" /> 2016-2017 - Bolton <br></br>
                Neonatal/Special Care Baby Unit
              </h4>
            </div>
            <p>
              Bolton  Hospital’s  Special  Care  Baby  Unit  did  a  fantastic  job  of  looking  after  our  Chairman’s  twin
              daughter Chloe following her difficult birth in 2014. Chloe required treatment for jaundice, tube feeding
              and other medical intervention for the 9-days following her birth. The team at the Hospital were fantastic
              with the support they provided Chloe and also our family through what was a very difficult time. This
              support continued at home after discharge until the Medical team were comfortable that Chloe and our
              family were ‘thriving’ in the community.
            </p>
            {/* <p>
              Public funding for hospital services is limited but the team at Bolton Hospital do a
              great job with the funding they are given; this only stretches far enough to provide
              basic care. Parent’s and their families spend long hours on the ward with their poorly
              baby and facilities are limited.
            </p> */}
          </div>
          <div className="bolton-neonatal-sub">
            <img src={BoltonNeonatal1} alt="BoltonNeonatal1" className="BoltonNeonatal1" />
          </div>
        </div>
      </BrowserView>
      <TabletView>
        <div className="bolton-neonatal-box">
          <div className="bolton-neonatal-sub">
            <div className="bolton-style">
              <h4>
                <img src={HeadImg} alt="HeadImg" className="HeadImg" /> 2016-2017 - Bolton <br></br>
                Neonatal/Special Care Baby Unit
              </h4>
            </div>
            <p>
              Bolton  Hospital’s  Special  Care  Baby  Unit  did  a  fantastic  job  of  looking  after  our  Chairman’s  twin
              daughter Chloe following her difficult birth in 2014. Chloe required treatment for jaundice, tube feeding
              and other medical intervention for the 9-days following her birth. The team at the Hospital were fantastic
              with the support they provided Chloe and also our family through what was a very difficult time. This
              support continued at home after discharge until the Medical team were comfortable that Chloe and our
              family were ‘thriving’ in the community.
            </p>
            {/* <p>
              Public funding for hospital services is limited but the team at Bolton Hospital do a
              great job with the funding they are given; this only stretches far enough to provide
              basic care. Parent’s and their families spend long hours on the ward with their poorly
              baby and facilities are limited.
            </p> */}
          </div>
          <div className="bolton-neonatal-sub">
            <img src={BoltonNeonatal1} alt="BoltonNeonatal1" className="BoltonNeonatal1" />
          </div>
        </div>
      </TabletView>
      <MobileOnlyView>
        <div className="bolton-neonatal-box">
          <div className="bolton-neonatal-sub">
            <div className="bolton-style">
              <h4>
                <img src={HeadImg} alt="HeadImg" className="HeadImg" /> 2016-2017 - Bolton <br></br>
                Neonatal/Special Care Baby Unit
              </h4>
            </div>
            <div className="bolton-neonatal-sub">
              <img src={BoltonNeonatal1} alt="BoltonNeonatal1" className="BoltonNeonatal1" />
            </div>
            <p>
              Bolton  Hospital’s  Special  Care  Baby  Unit  did  a  fantastic  job  of  looking  after  our  Chairman’s  twin
              daughter Chloe following her difficult birth in 2014. Chloe required treatment for jaundice, tube feeding
              and other medical intervention for the 9-days following her birth. The team at the Hospital were fantastic
              with the support they provided Chloe and also our family through what was a very difficult time. This
              support continued at home after discharge until the Medical team were comfortable that Chloe and our
              family were ‘thriving’ in the community.
            </p>
            {/* <p>
              Public funding for hospital services is limited but the team at Bolton Hospital do a
              great job with the funding they are given; this only stretches far enough to provide
              basic care. Parent’s and their families spend long hours on the ward with their poorly
              baby and facilities are limited.
            </p> */}
          </div>
        </div>
      </MobileOnlyView>
    </>
  );
}

export default BoltonNeonatal;
