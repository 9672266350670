import React from 'react';
import img1 from '../../images/page-about-us/people.jpg';
import { Link } from 'react-router-dom';

function OurPeople() {
  return (
    <section id="our-people">
      <div className="ourpeople-box">
        <div className="ourpeople-item">
          <img src={img1} width="97%" height="80%" alt="people one" />
        </div>
        <div className="ourpeople-item">
          <h4 className="custom-color">Our People</h4>
          <p className="custom-color">
            Our people mean everything to us, and we aim to support and encourage them to develop
            and grow within the company. We provide collaborative working environments and flexible
            solutions, including working from home.
          </p>
          <p className="custom-color">
            {' '}
            Our open working culture allows colleagues to be flexible in their own approach and our
            group values reflect what we stand for.
          </p>
          <Link to="/our-people">
            <button className="btn-two">Find out more</button>
          </Link>
          {/*
          <button
           onClick={() => {
               window.open("/our-people", "");
             }}
          >
            Find out more
          </button>
          */}
        </div>
      </div>
    </section>
  );
}

export default OurPeople;
