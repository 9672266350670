import React from 'react';
import Message from '../customer-bookings/message';
import NavigationBar from '../common/navigation-bar';

function Home() {
  return (
    <div>
      <NavigationBar />
      <div className="about-us-box">
        <Message />
      </div>
    </div>
  );
}

export default Home;
