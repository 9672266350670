import React, { useState } from 'react';
import Banner from './banner';
import { Culture } from './culture';
import { Values } from './values';
import { OurPeopleDetails } from './our-people-details';
import WorkLife from './work-life';
import EqualityDiversity from './equality-diversity';
import Careers from './careers';
import CurrentVacanciesLambda from './current-vacancies-lambda';
import NavigationBar from '../common/navigation-bar';

export const OurPeople = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <>
      <NavigationBar />
      <Banner />
      <div className="main-container">
        <Culture />
        <Values />
        <OurPeopleDetails />
        <WorkLife />
        <EqualityDiversity isLoaded={isLoaded} />
        <Careers />
        <CurrentVacanciesLambda setIsLoaded={setIsLoaded} />
      </div>
    </>
  );
};
