import React from 'react';
import Evoluation_img from '../../images/page-about-us/timeline.png';

function Evoluation() {
  return (
    <div className="Evoluation">
      <h4 className="custom-color">Evolution of the Product Care Group</h4>
      <div className="Evoluation-scroll">
        <img src={Evoluation_img} alt="Evoluation" />
      </div>
    </div>
  );
}

export default Evoluation;
