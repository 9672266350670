import React from 'react';
import img1 from '../../images/page-about-us/chart-employee-growth.png';
import img2 from '../../images/page-about-us/chart-employee-satisfaction.png';

function Employee() {
  return (
    <div className="employee">
      <img src={img1} alt="employee" />
      <img src={img2} alt="employee" />
    </div>
  );
}

export default Employee;
