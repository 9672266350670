import React from 'react';
import { Link } from 'react-router-dom';


function FindDeliveries() {
  return (
    <section id="finddeliver-content">
      <div className="finddeliver-txt">
        <h4>Find Out More About Our Deliveries</h4>
        <p>
          See our clear and simple process for booking, amending or tracking a product delivery. Our
          customer delivery
        </p>
        <p>
          timeline gives the customer a step-by-step guide of what happens from the moment they have
          purchased their product.
        </p>
        {/* <button className="finddeliver-btn"><Link to = "/customer-bookings">Customer bookings and support</Link></button> */}
        <Link to = "/customer-bookings"><button className="finddeliver-btn">Customer bookings and support</button></Link>
      </div>
    </section>
  );
}

export default FindDeliveries;
