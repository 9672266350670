import React from 'react';
import { Link } from 'react-router-dom';
function ServicesWeOffer() {
  return (
    <section id="services-we-offer-box">
      <div className="services-we-offer custom-name-color popinFontS2 ">
        Take a look at the services we offer
      </div>
      <Link to="/services">
        {' '}
        <button className="services-we-offer-btn">Services</button>
      </Link>
    </section>
  );
}

export default ServicesWeOffer;
