import { DynamoDBClient, QueryCommand } from "@aws-sdk/client-dynamodb";
import { marshall} from "@aws-sdk/util-dynamodb";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";

const region = process.env.REACT_APP_REGION;
const client = new DynamoDBClient({
    region: region,
    credentials: fromCognitoIdentityPool({
      identityPoolId: process.env.REACT_APP_COGNITO_POOL,
      clientConfig: { region: region },
    }),
  })
export const getNews = async (id='', extraSymbol='') => {
  try {
  const params = {
    TableName: process.env.REACT_APP_DB_TABLE,
    KeyConditionExpression: 'pk = :hkey and begins_with(sk, :rkey)',
    ExpressionAttributeValues: marshall({
      ':hkey': 'productCareGroup',
      ':rkey': `website#news${extraSymbol}${id}`,
    }),
  };
  
  const command = new QueryCommand(params)
  const response = await client.send(command);
 
  
  return response 
}
catch(error){
  console.log(" get error in dynamoDB>>>>>>>>>>", error);
  throw error;
}
}