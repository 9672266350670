import React from 'react';
import NewsBannerWeb from '../../images/banners/news.jpg';
import NewsBannerMobile from '../../images/banners/news-mobile.jpg';
import { BrowserView, MobileOnlyView, TabletView, isIOS } from 'react-device-detect';



function Banner() {
  return (
    <>
      <BrowserView>
        <section  className={(isIOS ? 'IpadViewNone' : 'news-banner-box')}>
       
          <div className="news-banner">
            <img
              src={NewsBannerWeb}
              alt="img"
              width="100%"
              height="300px"
              className="our-brand-img"
            />
          </div>
        </section>
      </BrowserView>
      <TabletView>
      <section className="news-banner-box">
          <div className="news-banner">
            <img
              src={NewsBannerWeb}
              alt="img"
              width="100%"
              height="300px"
              className="our-brand-img"
            />
          </div>
        </section>
      </TabletView>
      <MobileOnlyView>
        <section className="news-banner-box">
          <div className="news-banner">
            <img
              src={NewsBannerMobile}
              alt="img"
              width="100%"
              height="300px"
              className="our-brand-img"
            />
          </div>
        </section>
      </MobileOnlyView>
    </>
  );
}


export default Banner;
