import React from 'react';
import OurPeopleWeb from '../../images/banners/our-people.jpg';
import OurPeopleMobile from '../../images/banners/our-people-mobile.jpg';
import { BrowserView, MobileOnlyView, TabletView, isIOS} from 'react-device-detect';

function Banner() {
  return (
    <>
      <BrowserView>
        <section className={(isIOS ? 'IpadViewNone' : 'our-people-banner-box')}>
        
          <div className="our-people-banner">
            <img
              src={OurPeopleWeb}
              alt="img"
              width="100%"
              height="300px"
              className="our-brand-img"
            />
          </div>
        </section>
      </BrowserView>
      <TabletView>
      <section className="our-people-banner-box">
          <div className="our-people-banner">
            <img
              src={OurPeopleWeb}
              alt="img"
              width="100%"
              height="300px"
              className="our-brand-img"
            />
          </div>
        </section>
      </TabletView>
      <MobileOnlyView>
        <section className="our-people-banner-box">
          <div className="our-people-banner">
            <img
              src={OurPeopleMobile}
              alt="img"
              width="100%"
              height="300px"
              className="our-brand-img"
            />
          </div>
        </section>
      </MobileOnlyView>
    </>
  );
}

export default Banner;
