import React from 'react';

function BannerContent() {
  return (
    <section id="banner-content-box">
      <div className="banner-content-txt">
        <h4 className="custom-color">White Glove Home Delivery</h4>
        <p className="custom-color">
          We are your nationwide B2B trusted partner of choice. 
          Acting on your behalf we offer exceptional, bespoke, 
          customer focused services to your valued customers.
        </p>

        <p className="custom-color">
          Operating out of multiple hubs and combining our fleet of vehicles 
          with our Trusted Local Partner Network, we are able to service 100% 
          of UK mainland households. We deliver Monday to Saturday, 7am to 7pm, 
          and keep customers informed through every step of the delivery process 
          using our state of the art booking portal.  
        </p>
      </div>
    </section>
  );
}

export default BannerContent;
