import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Product_img1 from '../../images/brands/licensed-brands/russell-hobbs.png';
import Product_img2 from '../../images/brands/licensed-brands/george-foreman.png';
import Product_img3 from '../../images/brands/licensed-brands/rangemaster.png';
import Product_img4 from '../../images/brands/licensed-brands/zanussi.png';
import Product_img5 from '../../images/brands/our-brands/kuhla.png';
import Product_img6 from '../../images/brands/our-brands/lowry.png';
import Product_img7 from '../../images/brands/our-brands/abode.png';
import Product_img8 from '../../images/brands/our-brands/holme.png';
import Product_supp_img1 from '../../images/icons/ico_heart.png';

function Product({ isLoaded }) {
  const ProductSupport = useRef();
  const history = useHistory();
  
  useEffect(() => {
    const linkState = history.location ? history.location.state : null;
    const isScroll = linkState ? linkState.isScroll : null;
    if (ProductSupport.current && isScroll && isLoaded) {
      ProductSupport.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [history.location, isLoaded]);

  return (
    <div className="Product-box" ref={ProductSupport}>
      <h4>
        <img src={Product_supp_img1} height="50px" alt="Product" />
        Product Support
      </h4>
      <p>
        For product support including manuals, spare parts, and product registrations please click
        on the relevant brand below.
      </p>
      <div className="Product-sub">
        <div className="Product-sub-one">
          <img src={Product_img1} alt="Product" />
        </div>
        <div className="Product-sub-two">
          <h6>Russell Hobbs</h6>
          <p>
            For Russell Hobbs spare parts, instruction booklets, and information about registering
            your appliance, go to the Russell Hobbs Major Domestic Appliance website.
          </p>
         
            <a href="https://mda.russellhobbs.com/ " target="_blank" rel="noreferrer">
            <button>
              Visit Russell Hobbs
              </button>
            </a>
         
        </div>
      </div>

      <div className="Product-sub">
        <div className="Product-sub-one">
          <img src={Product_img2} alt="Product" />
        </div>
        <div className="Product-sub-two">
          <h6>George Foreman</h6>
          <p>
            For instruction booklets and support on your George Foreman product, go to the George
            Foreman website.
          </p>
        
            <a href="https://www.georgeforeman.co.uk " target="_blank" rel="noreferrer">
            <button>
              Visit George Foreman
              </button>
            </a>
         
        </div>
      </div>

      <div className="Product-sub">
        <div className="Product-sub-one">
          <img src={Product_img3} alt="Product" />
        </div>
        <div className="Product-sub-two">
          <h6>Rangemaster</h6>
          <p>
            For additional information and support on your Rangemaster product, please contact us.
          </p>
         
            <a href="/contact-us" target="_blank" rel="noreferrer">
            <button>
              Contact us
              </button>
            </a>
         
        </div>
      </div>

      <div className="Product-sub">
        <div className="Product-sub-one">
          <img src={Product_img4} alt="Product" />
        </div>
        <div className="Product-sub-two">
          <h6>Zanussi</h6>
          <p>
            For instruction booklets and support on your Zanussi product, go to the Zanussi website.
          </p>
            <a href="https:///www.zanussi.co.uk/" target="_blank" rel="noreferrer">
            <button>
              Visit Zanussi
              </button>
            </a>
         
        </div>
      </div>

      <div className="Product-sub">
        <div className="Product-sub-one">
          <img src={Product_img5} alt="Product" />
        </div>
        <div className="Product-sub-two">
          <h6>Kuhla</h6>
          <p>
            For instruction booklets and support on your Kuhla product, go to the Kuhla website.
          </p>
         
            <a href="https://kuhla.co.uk/ " target="_blank" rel="noreferrer">
            <button>
              Visit Kuhla
              </button>
            </a>
         
        </div>
      </div>

      <div className="Product-sub">
        <div className="Product-sub-one">
          <img src={Product_img6} alt="Product" />
        </div>
        <div className="Product-sub-two">
          <h6>Lowry</h6>
          <p>For additional information and support on your Lowry product, please contact us.</p>
          
            <a href="https://lowryappliances.co.uk/ " target="_blank" rel="noreferrer">
            <button>
              Visit Lowry
              </button>
            </a>
       
        </div>
      </div>

      <div className="Product-sub">
        <div className="Product-sub-one">
          <img src={Product_img7} alt="Product" />
        </div>
        <div className="Product-sub-two">
          <h6>Abode</h6>
          <p>For instruction booklets and support on your Abode product, go to the Abode website.</p>
          
            <a href="https://www.thisabode.co.uk/" target="_blank" rel="noreferrer">
            <button>
              Visit Abode
              </button>
            </a>
          
        </div>
      </div>

      <div className="Product-sub">
        <div className="Product-sub-one">
          <img src={Product_img8} alt="Product" />
        </div>
        <div className="Product-sub-two">
          <h6>Holme</h6>
          <p>For additional information and support on your Holme product, please contact us.</p>
         
            <a href="/contact-us" target="_blank" rel="noreferrer">
            <button>
              Contact us
              </button>
            </a>
         
        </div>
      </div>
    </div>
  );
}

export default Product;
