import { useEffect, useRef } from "react";

function TrustPilotWidget() {
	const ref = useRef(null);
	useEffect(() => {
		if (window.Trustpilot) {
			window.Trustpilot.loadFromElement(ref.current, true);
		}
	}, []);
	return (
		<div
			ref={ref}
			className='trustpilot-widget'
			data-locale='en-GB'
			data-template-id='5419b6a8b0d04a076446a9ad'
			data-businessunit-id='5dfa968f645327000167b5a4'
			data-style-height='80px'
			data-style-width='100%'
			data-theme='light'
		>
			<a
				href='https://uk.trustpilot.com/review/www.productcare.co.uk'
				target='_blank'
				rel='noreferrer'
				className="fs-1"
			>
				{" "}
				Trustpilot
			</a>
		</div>
	);
}

export default TrustPilotWidget;
