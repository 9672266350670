import React from "react";
import logo from "../../images/image11.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="product-care-footer">
        <div className="product-care-footer-sub">
          <div className="product-care-footer-start">
            <Link to="/">
              <h6 className="product-care-footer-txt">About Us</h6>
            </Link>
            <Link to="/what-we-do">
              <h6 className="product-care-footer-txt">What We Do</h6>
            </Link>
            <Link to="/our-brands">
              <h6 className="product-care-footer-txt">Our Brands</h6>
            </Link>
            <Link to="/services">
              <h6 className="product-care-footer-txt">Services</h6>
            </Link>
            <Link to="/our-people">
              <h6 className="product-care-footer-txt">Our People</h6>
            </Link>
            <Link to="/ethical-social-responsibility">
              <h6 className="product-care-footer-txt">
                Ethical Social Responsibility
              </h6>
            </Link>
          </div>
        </div>
        <div className="product-care-footer-sub">
          <div className="product-care-footer-middle">
            <h6 className="product-care-footer-txt">
              <Link to="/contact-us" target="_blank" className="footer-color">
                Contact Us
              </Link>
            </h6>
            <h6 className="product-care-footer-txt">
              <a
                href="https://myorder.productcaregroup.com/login"
                className="footer-color"
                target="_blank"
                rel="noreferrer"
              >
                Book or Track a Delivery
              </a>
            </h6>
            <h6 className="product-care-footer-txt">
              <Link
                to={ {
                  pathname: "/customer-bookings",
                  state: { isScroll: true },
                } }
                className="footer-color"
              >
                Product Support
              </Link>
            </h6>
            <h6 className="product-care-footer-txt">
              {/* <Link
                to={{
                  pathname: '/our-people',
                  state: { isScroll: true },
                }}
                className="footer-color"
                id='#careers'
              >
                Careers
              </Link> */}
              <a href="our-people#careers">Careers</a>
            </h6>
            <h6 className="product-care-footer-txt">
              <Link
                to={ {
                  pathname: "/our-people",
                  state: { isScroll: true },
                } }
                className="footer-color"
              >
                Diversity Policy
              </Link>
            </h6>
            <h6 className="product-care-footer-txt">
              <Link to={ "/privacy-policy" } className="footer-color">
                Privacy Policy
              </Link>
            </h6>
            <h6 className="product-care-footer-txt">
              <Link to={ "/core-management-system-policy" } className="footer-color">
                Core Management System Policy
              </Link>
            </h6>
            <h6 className="product-care-footer-txt">
              <Link to={ { pathname: '/documents/PCG-Core-Policy-Statements-v1.6-March-2024.pdf' } } target="_blank">
                { ' ' }
                Modern Slavery Statement
              </Link>
            </h6>
            <h6 className="product-care-footer-txt">
              <Link to={ { pathname: '/documents/Latest-Modern-Slavery-and-Human-Trafficking-Performance-Review.pdf' } } target="_blank">
                { ' ' }
                Latest MSHT Performance Review
              </Link>
            </h6>
          </div>
        </div>
        <div className="product-care-footer-sub">
          <div className="product-care-footer-end">
            <Link to="/">
              { " " }
              <img src={ logo } alt="logo" />
            </Link>
            <a
              href="https://www.linkedin.com/company/product-care-group/"
              className="footer-color"
              target="_blank"
              rel="noreferrer"
            >
              { " " }
              <i className="fa fa-linkedin-square"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="product-care-footer-sub">
        <div className="product-care-footer-sub-two">
          <div>
            © 2021 Product Care Group, All Rights Reserved. Website managed by
            Product Care Group Limited Wigan, WN2 4AY <br />
            Product Care Group is made up of the following companies: Product
            Care Trading Ltd., Product Care Online Ltd., Product Care Services
            Ltd., Product Care Hub Ltd.
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
