import React from 'react';
import { Link } from 'react-router-dom';
import customer from '../../images/page-services/customer-experience.jpg';
function CustomerExp() {
  return (
    <div className="customer-exp">
      <div className="customer-exp-item">
        <h4 className="custom-color">Customer Experience</h4>
        <p className="customer-txt custom-color">
          The customer is at the centre of everything we do. We focus on delivery, customer service and caring for your product.
          </p>
          <p>
          Our Customer Engagement Team are trained to provide best-in-class customer service every time. They support customers with all elements of their journey from booking, through to delivery.
        </p>
        <p className="customer-txt custom-color">
          Our friendly and dedicated UK team are also on hand to support with all post-delivery customer enquiries
          including troubleshooting, spare part orders, engineering enquiries and product information.
        </p>
        <span className="customer-exp-btn">
          <Link to="/contact-us" target="_blank"><button className="customer-btn">Contact us</button></Link>
        </span>
      </div>
      <div className="customer-exp-item">
        <img src={customer} alt="Customer" className="custm-img" />
      </div>
    </div>
  );
}

export default CustomerExp;
