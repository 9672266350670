import React from 'react';
import EmploymentHealthSafetyImg from '../../images/page-ethical-social-resposinility/employment-health-safety.png';
import HeadImg from '../../images/icons/ico_people2.png';
import { BrowserView, MobileOnlyView, TabletView, isIOS } from 'react-device-detect';

function EmploymentStatement() {
  return (
    <>
      <BrowserView>
        <div className={ (isIOS ? 'IpadViewNone' : 'employment-statement-box') }>
          <h4>
            <img src={ HeadImg } alt="HeadImg" className="HeadImg" /> Employment, Health and Safety & Information Security
          </h4>
        </div>
        <div className={ (isIOS ? 'IpadViewNone' : 'employment-statement-box') }>
          <div className="employment-statement-sub">
            <p>
            The Product Care Group are committed to doing business that is conducted honestly, fairly and with respect for people, their views, opinions and their human rights.
            </p>
            <p>
              Our people are our greatest asset and contribute significantly to the growth and stability of our business.
              The health, safety and human rights of all our employees at work must be respected and protected.
            </p>
            <p>
              Our continuous efforts in the Health and Safety arena, have manifested into a management system that is
              now certified to the internationally recognised Standard BS ISO 45001: 2018, the new international standard for Health & Safety.
            </p>
            <p>
              Our commitment to an ethical approach is governed by key policies in areas such as recruitment & selection, bribery & corruption, health & safety, equality & diversity and employment standards (all available on request) We are committed to preserving the confidentiality, integrity and availability of all information and informational assets within our organisation, as well as complying with all applicable legislative requirements such as GDPR and the Data Protection Act 2018.
            </p>
            <p>
              Our management system for Information Security has been subject to 3rd party verification and is certified as meeting the requirements of ISO/IEC 27001: 2013, which is the international standard for Information Security Management systems.
            </p>
          </div>
          <div className="employment-statement-sub">
            <img
              src={ EmploymentHealthSafetyImg }
              alt="EmploymentStatement1"
              className="EmploymentStatement1"
            />
            {/* <div className="employment-statement-img">
              <img
                src={EmploymentStatement2}
                alt="EmploymentStatement2"
                className="EmploymentStatement2"
              />
              <img
                src={EmploymentStatement3}
                alt="EmploymentStatement3"
                className="EmploymentStatement3"
              />
            </div> */}
          </div>
        </div>
      </BrowserView>
      <TabletView>
        <div className={ (isIOS ? 'IpadViewNone' : 'employment-statement-box') }>
          <h4>
            <img src={ HeadImg } alt="HeadImg" className="HeadImg" /> Employment, Health and Safety & Information Security
          </h4>
        </div>
        <div className="employment-statement-box">
          <div className="employment-statement-sub">
            <p>
              The MM Group are committed to doing business that is conducted honestly, fairly and with respect for
              people, their views, opinions and their human rights.
            </p>
            <p>
              Our people are our greatest asset and contribute significantly to the growth and stability of our business.
              The health, safety and human rights of all our employees at work must be respected and protected.
            </p>
            <p>
              Our continuous efforts in the Health and Safety arena, have manifested into a management system that is
              now certified to the internationally recognised Standard BS ISO 45001: 2018, the new international standard for Health & Safety.
            </p>
            <p>
              Our commitment to an ethical approach is governed by key policies in areas such as recruitment & selection, bribery & corruption, health & safety, equality & diversity and employment standards (all available on request) We are committed to preserving the confidentiality, integrity and availability of all information and informational assets within our organisation, as well as complying with all applicable legislative requirements such as GDPR and the Data Protection Act 2018.
            </p>
            <p>
              Our management system for Information Security has been subject to 3rd party verification and is certified as meeting the requirements of ISO/IEC 27001: 2013, which is the international standard for Information Security Management systems.
            </p>
          </div>
          <div className="employment-statement-sub">
            <img
              src={ EmploymentHealthSafetyImg }
              alt="EmploymentStatement1"
              className="EmploymentStatement1"
            />
            {/* <div className="employment-statement-img">
              <img
                src={EmploymentStatement2}
                alt="EmploymentStatement2"
                className="EmploymentStatement2"
              />
              <img
                src={EmploymentStatement3}
                alt="EmploymentStatement3"
                className="EmploymentStatement3"
              />
            </div> */}
          </div>
        </div>
      </TabletView>
      <MobileOnlyView>
        <div className={ (isIOS ? 'IpadViewNone' : 'employment-statement-box') }>
          <h4>
            <img src={ HeadImg } alt="HeadImg" className="HeadImg" /> Employment, Health and Safety & Information Security
          </h4>
        </div>
        <div className="employment-statement-box">
          <div className="employment-statement-sub">
            <div className="employment-statement-sub">
              <img
                src={ EmploymentHealthSafetyImg }
                alt="EmploymentStatement1"
                className="EmploymentStatement1"
              />
              {/* <div className="employment-statement-img">
                <img
                  src={EmploymentStatement2}
                  alt="EmploymentStatement2"
                  className="EmploymentStatement2"
                />
                <img
                  src={EmploymentStatement3}
                  alt="EmploymentStatement3"
                  className="EmploymentStatement3"
                />
              </div> */}
            </div>
            <p>
              The MM Group are committed to doing business that is conducted honestly, fairly and with respect for
              people, their views, opinions and their human rights.
            </p>
            <p>
              Our people are our greatest asset and contribute significantly to the growth and stability of our business.
              The health, safety and human rights of all our employees at work must be respected and protected.
            </p>
            <p>
              Our continuous efforts in the Health and Safety arena, have manifested into a management system that is
              now certified to the internationally recognised Standard BS ISO 45001: 2018, the new international standard for Health & Safety.
            </p>
            <p>
              Our commitment to an ethical approach is governed by key policies in areas such as recruitment & selection, bribery & corruption, health & safety, equality & diversity and employment standards (all available on request) We are committed to preserving the confidentiality, integrity and availability of all information and informational assets within our organisation, as well as complying with all applicable legislative requirements such as GDPR and the Data Protection Act 2018.
            </p>
            <p>
              Our management system for Information Security has been subject to 3rd party verification and is certified as meeting the requirements of ISO/IEC 27001: 2013, which is the international standard for Information Security Management systems.
            </p>
          </div>
        </div>
      </MobileOnlyView>
    </>
  );
}

export default EmploymentStatement;
