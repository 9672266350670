import React from 'react';
import team from '../../images/page-what-we-do/china-team.jpg';
import { Link } from 'react-router-dom';

function Team() {
  return (
    <section className="team-box">
      <div className="team">
        <h4>Our China Team</h4>
        <p>
          Based in Shunde, Foshan, we have a dedicated team of Merchandisers, Quality Assurance
          Engineers and Inspectors. Our team is responsible for detailed supplier audits and
          production inspections, as well as order management, logistics and container shipments.
        </p>
        <p>
          We are working closely with our factories to re-engineer our products and packaging as
          part of our sustainability journey, as we strive to reduce our carbon footprint in line
          with our targets.
        </p>
        <p>
          The relationship between our UK and China teams is absolutely paramount in ensuring we
          deliver great quality products in line with our customers’ high standards and
          expectations.
        </p>
        <img src={team} width="100%" height="50%" alt="Team" />
        <h4>
          <br />
          Specialist delivery, third-party logistics solutions and more…
        </h4>
        <p>
          We take pride in delivering bespoke, customer-focused services nationwide.
          With our <b>“Right First Time”</b> philosophy we aim to provide a quality, value-added
          service every time. Our specialist home delivery and third-party logistics services offer
          unique solutions throughout the lifetime of your product.
        </p>
        <p>
          We are focused on providing customer excellence in everything we do. Our people are
          company employed, DBS checked, extensively trained and KPI monitored to ensure high
          standards are consistently achieved.
        </p>
      </div>
      <Link to="/services">
                <button className='more-btn'>Find out more about our Services</button>
          </Link>
    </section>
  );
}

export default Team;
