import React, { Component } from "react";
import DeliveryQueryIcon from "../../images/Delivery-Query-Icon.png";
import GeneralEnquiryIcon from "../../images/General-Query-Icon-New.png";
import ProductQueryIcon from "../../images/Product-Query-Icon-New.png";
// import { ReactComponent as General } from "../../images/General Query Icon .svg";
import Support from "./Support";
import GeneralEnquiry from "./generalEnquiry";
import ContactDelivery from "./contactDeliveryQuery";
import {
  BrowserView,
  MobileOnlyView,
  TabletView,
  isIOS,
} from "react-device-detect";

class NewContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showcontactDelivery: false,
      showProductSupport: false,
      showGeneralEnquiry: false,
    };
  }
  hideComponent(name) {
    switch (name) {
      case "showcontactDelivery":
        this.setState({ showcontactDelivery: !this.state.showcontactDelivery });
        break;
      case "showProductSupport":
        this.setState({ showProductSupport: !this.state.showProductSupport });
        break;
      case "showGeneralEnquiry":
        this.setState({ showGeneralEnquiry: !this.state.showGeneralEnquiry });
        break;
      default:
        return null;
    }
  }
  render() {
    const { showcontactDelivery, showProductSupport, showGeneralEnquiry } =
      this.state;
    return (
      <>
        <BrowserView>
          <section className={isIOS ? "IpadViewNone" : "Query-New-box"}>
            <div className="Query-box">
              <div>
                {showcontactDelivery && <ContactDelivery />}
                {showProductSupport && <Support />}
                {showGeneralEnquiry && <GeneralEnquiry />}
              </div>
              {!showcontactDelivery &&
                !showProductSupport &&
                !showGeneralEnquiry && (
                  <div className="row">
                    <div className="col-md-4 support-box">
                      <button
                        className="contact-box-sub"
                        onClick={() =>
                          this.setState({
                            showcontactDelivery: !showcontactDelivery,
                          })
                        }
                      >
                        <span className="contact-txt">
                          <div className="truckImg">
                            <img
                              src={DeliveryQueryIcon}
                              alt="dev"
                              width="61%"
                            ></img>
                          </div>
                          <h6>Delivery Query</h6>
                        </span>
                      </button>
                    </div>
                    <div className="col-md-4 support-box">
                      <button
                        className="contact-box-sub"
                        onClick={() =>
                          this.setState({
                            showProductSupport: !showProductSupport,
                          })
                        }
                      >
                        <span className="contact-txt">
                          <div className="truckImg">
                            <img
                              src={ProductQueryIcon}
                              alt="productQuery"
                              width="61%"
                            ></img>
                          </div>
                          <h6>Product Query</h6>
                        </span>
                      </button>
                    </div>
                    <div className="col-md-4 support-box">
                      <button
                        className="contact-box-sub"
                        onClick={() =>
                          this.setState({
                            showGeneralEnquiry: !showGeneralEnquiry,
                          })
                        }
                      >
                        <span className="contact-txt">
                          <div className="truckImg">
                            <img
                              src={GeneralEnquiryIcon}
                              alt="general"
                              width="61%"
                            ></img>
                          </div>
                          <h6>General Enquiry</h6>
                        </span>
                      </button>
                    </div>
                  </div>
                )}
            </div>
          </section>
        </BrowserView>
        <TabletView>
          <section className="Query-New-box">
            <div className="Query-box">
              <div>
                {showcontactDelivery && <ContactDelivery />}
                {showProductSupport && <Support />}
                {showGeneralEnquiry && <GeneralEnquiry />}
              </div>
              {!showcontactDelivery &&
                !showProductSupport &&
                !showGeneralEnquiry && (
                  <div className="row">
                    <div className="col-md-4 support-box">
                      <button
                        className="contact-box-sub tablet-box"
                        onClick={() =>
                          this.setState({
                            showcontactDelivery: !showcontactDelivery,
                          })
                        }
                      >
                        <span className="contact-txt">
                          <div className="truckImg">
                            <img
                              src={DeliveryQueryIcon}
                              alt="delivery"
                              width="61%"
                            ></img>
                          </div>
                          <h6>Delivery Query</h6>
                        </span>
                      </button>
                    </div>
                    <div className="col-md-4 support-box">
                      <button
                        className="contact-box-sub tablet-box"
                        onClick={() =>
                          this.setState({
                            showProductSupport: !showProductSupport,
                          })
                        }
                      >
                        <span className="contact-txt">
                          <div className="truckImg">
                            <img
                              src={ProductQueryIcon}
                              alt="productQuery"
                              width="61%"
                            ></img>
                          </div>
                          <h6>Product Query</h6>
                        </span>
                      </button>
                    </div>
                    <div className="col-md-4 support-box">
                      <button
                        className="contact-box-sub tablet-box"
                        onClick={() =>
                          this.setState({
                            showGeneralEnquiry: !showGeneralEnquiry,
                          })
                        }
                      >
                        <span className="contact-txt">
                          <div className="truckImg">
                            <img
                              src={GeneralEnquiryIcon}
                              alt="general"
                              width="61%"
                            ></img>
                          </div>
                          <h6>General Enquiry</h6>
                        </span>
                      </button>
                    </div>
                  </div>
                )}
            </div>
          </section>
        </TabletView>
        <MobileOnlyView>
          <section className="Query-New-box">
            <div className="Query-box">
              <div>
                {showcontactDelivery && <ContactDelivery />}
                {showProductSupport && <Support />}
                {showGeneralEnquiry && <GeneralEnquiry />}
              </div>
              {!showcontactDelivery &&
                !showProductSupport &&
                !showGeneralEnquiry && (
                  <div className="contact-query">
                    <div className="mobile-support">
                      <div className="mobile-box">
                        <button
                          className="contact-box-sub mobile-sub"
                          onClick={() =>
                            this.setState({
                              showcontactDelivery: !showcontactDelivery,
                            })
                          }
                        >
                          <span className="contact-txt">
                            <div className="truckImg">
                              <img
                                src={DeliveryQueryIcon}
                                alt="delivery"
                                width="61%"
                              ></img>
                            </div>
                            <h6>Delivery Query</h6>
                          </span>
                        </button>
                      </div>
                      <div className="mobile-box">
                        <button
                          className="contact-box-sub mobile-sub"
                          onClick={() =>
                            this.setState({
                              showProductSupport: !showProductSupport,
                            })
                          }
                        >
                          <span className="contact-txt">
                            <div className="truckImg">
                              <img
                                src={ProductQueryIcon}
                                alt="productQuery"
                                width="61%"
                              ></img>
                            </div>
                            <h6>Product Query</h6>
                          </span>
                        </button>
                      </div>
                      <div className="mobile-box">
                        <button
                          className="contact-box-sub mobile-sub"
                          onClick={() =>
                            this.setState({
                              showGeneralEnquiry: !showGeneralEnquiry,
                            })
                          }
                        >
                          <span className="contact-txt">
                            <div className="truckImg">
                              <img
                                src={GeneralEnquiryIcon}
                                alt="general"
                                width="61%"
                              ></img>
                            </div>
                            <h6>General Enquiry</h6>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </section>
        </MobileOnlyView>
      </>
    );
  }
}

export default NewContact;
