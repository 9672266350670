import React from 'react';
import AboutUsBannerWeb from '../../images/banners/about-us.jpg'
import AboutUsBannerMobile from '../../images/banners/about-us-mobile.jpg'
import { BrowserView, MobileOnlyView, TabletView, isIOS} from 'react-device-detect';

function Banner() {
  return (
   <>
   <BrowserView>
   <section className={(isIOS ? 'IpadViewNone' : 'banner-box')}>
      <div className="banner">
        <img
              src={AboutUsBannerWeb}
              alt="img"
              width="100%"
              height="100%"
              className="our-brand-img "
            />
      </div>
    </section>
   </BrowserView>
   <TabletView>
   <section className="banner-box">
      <div className="banner">
        <img
              src={AboutUsBannerWeb}
              alt="img"
              width="100%"
              height="100%"
              className="our-brand-img "
            />
        {/* </div> */}
      </div>
    </section>
   </TabletView>
   <MobileOnlyView>
   <section className="banner-box">
      <div className="banner">
        <img
              src={AboutUsBannerMobile}
              alt="img"
              width="100%"
              height="100%"
              className="our-brand-img"
            />
      </div>
    </section>
   </MobileOnlyView>
   </>
  );
}


export default Banner;
