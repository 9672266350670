import React, { useState, useEffect } from 'react';
//import { lambda } from '../../index';
import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
// Images
import plusIcon from '../../images/icons/plus-icon.png';
import booksIcon from '../../images/icons/books-icon.png';
import locationIcon from '../../images/icons/location-icon.png';

const region = process.env.REACT_APP_REGION;
const client = new LambdaClient({
  region: region,
  credentials: fromCognitoIdentityPool({
    identityPoolId: process.env.REACT_APP_COGNITO_POOL,
    clientConfig: { region: region },
  }),
});
export const fetchVacancies = async () => {
  let params = {
    FunctionName: process.env.REACT_APP_JOB_FUNCTION,
   
  };

  const command = new InvokeCommand(params);
  const response = await client.send(command);

  const arrayToConvert = response.Payload;
  const decodedResponse = new TextDecoder("utf-8");
  const stringResponse = decodedResponse.decode(arrayToConvert);
  const responseObject = JSON.parse(stringResponse);

  return responseObject;
};

function CurrentVacanciesLambda({ setIsLoaded }) {
  const [display, setDisplay] = useState([]);
  const [gotdata, setGotdata] = useState({
    data: false,
  });
  useEffect(() => {
    fetchVacancies().then(response => {
      setGotdata({
        data: true,
      });
      setDisplay(response.body);
      setIsLoaded(true);
    }).catch(error => {
      console.log('AWS Lambda returned with - error ', error);
    })
    
    
  }, [setIsLoaded]);

  if (gotdata.data) {
    return (
      <div className="current-vacancies-box" id="vacancies">
        <h4 className="popinFontH2">Current Vacancies</h4>
        {display.map((job, index) => (
          <div className="current-vacancies-sub" key={index}>
            <div className="current-vacancies-sub-sub">
              <div className="current-vacancies-first">
                <h5 className="vacancies-title popinFontH1">{job.title}</h5>
              </div>
              <div className="current-vacancies-second">
                <img className="plus-icon" src={locationIcon} alt="plus icon" />
                <span className="popinFontH6">{job.location}</span>
              </div>
            </div>
            <br></br>
            <div className="current-vacancies-line"></div>
            <br></br>
            <div className="current-vacancies-buttons">
              <div
                className="current-vacancies-buttons-sub"
                onClick={() => {
                  window.open(`${job.link}`, '_blank');
                }}
              >
                <span className="popinFontH6">
                  <img className="plus-icon" src={plusIcon} alt="plus icon" />
                  See More Info
                </span>
              </div>
              <div
                className="current-vacancies-buttons-sub popinFontH6"
                onClick={() => {
                  window.open(`${job.jobURL}`, '_blank');
                }}
              >
                <img className="plus-icon" src={booksIcon} alt="book" />
                Apply For This Position
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return <div />;
  }
}

export default CurrentVacanciesLambda;
