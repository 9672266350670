import React from 'react';
import { Link } from 'react-router-dom';

function AboutUsCard() {
  return (
    <section id="aboutus-card-box">
      <div className="aboutus-card">
        <div className="aboutus-card-item">
          <h5 className="custom-color">What We Do</h5>
          <p className="custom-color">
            We offer supply chain services to the UK and international retailers, managing a range
            of household electrical products under a number of leading global brands. We work
            closely with all our retailers and brand partners. We are flexible and adaptable, and we
            are able to consider new product categories to meet our clients' changing needs.
          </p>
          <Link to="/what-we-do">
            <button className="btn-pink">Find out more</button>
          </Link>
        </div>
        <div className="aboutus-card-item">
          <h5 className="custom-color">Our Brands</h5>
          <p className="custom-color">
            We're proud to work with a range of household appliance brands, including Russell Hobbs.
            We also offer a range of products and categories under our proprietary brands. We focus
            on developing products tailored to consumers' needs and we create innovative, engaging
            content to support them.
          </p>
          <Link to="/our-brands">
            <button className="btn-orange">Find out more</button>
          </Link>
        </div>
        <div className="aboutus-card-item">
          <h5 className="custom-color">Services</h5>
          <p className="custom-color">
            We pride ourselves on being specialists in delivery and white glove servicing of large
            parcel household electrical appliances. we also offer a range of delivery and
            third-party logistics services. We also offer customer services and product support for
            your customers and deliver a "Right First Time" approach.
          </p>
          <Link to="/services">
            <button className="btn-three">Find out more</button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default AboutUsCard;
