import React, { Fragment, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { unmarshall} from "@aws-sdk/util-dynamodb";
import LoadingImage from '../../images/loader/loading.gif';
import { BrowserView, MobileView, isIOS } from 'react-device-detect';
import { getNews } from '../../getNews';






function NewsDetail() {
  let { id } = useParams();
  const [news, setNews] = useState();
  useEffect(() => {
    getNews(id,"#").then(response => {
      if (response.Items.length !== 0) {
      
        const unmarshalledItems = response.Items.map(Item => {
          return unmarshall(Item)
        })
        setNews(unmarshalledItems);
      }
      else {
        console.log('Dynamodb returned - error response ', response);
      }
    }).catch(error => {
      console.log('Dynamodb returned - error ', error);
    })
  }, [id]);

  return (
    <>
      {Array.isArray(news) && news.length > 0 ? (
        <>
          {news.map((item, i) => {
            let news_link = `${item.slug}`;
            let detail_list = item.longDesc.replace(/(\r\n|\n|\r)/gm, '');
            if (news_link === id) {
              return (
                <Fragment key={i}>
                  <>
                    <BrowserView>
                      <div  className={(isIOS ? 'IpadViewNone' : 'news-box')}>
                     
                        <div className="news-detail-head-container">
                          <div className="news-detail-head popinFontS2">
                            <h4>{item.title}</h4>
                            <div className="LatoFontS5">{item.date}</div>
                          </div>
                          <div className="news-detail-back">
                            <Link
                              className="back-link LatoFontS5"
                              style={{ textDecoration: 'none' }}
                              to="/news"
                            >
                              Back
                            </Link>
                          </div>
                        </div>
                        <div className="news-box-sub">
                          <div dangerouslySetInnerHTML={{ __html: detail_list }}></div>
                        </div>
                        <div className="news-box-sub">
                          <img src={item.image} alt="news img" className="news_img" />
                        </div>
                      </div>
                    </BrowserView>
                    <MobileView>
                      <div className="news-box">
                        <div className="news-detail-back">
                          <Link
                            className="back-link LatoFontS5"
                            style={{ textDecoration: 'none' }}
                            to="/news"
                          >
                            Back
                          </Link>
                        </div>
                        <div className="news-detail-head-container">
                          <div className="news-detail-head popinFontS2">
                            <h4>{item.title}</h4>
                            <div className="LatoFontS5">{item.date}</div>
                          </div>
                        </div>
                        <div className="news-box-sub">
                          <div dangerouslySetInnerHTML={{ __html: detail_list }}></div>
                        </div>
                        <div className="news-box-sub">
                          <img src={item.image} alt="news img" className="news_img" />
                        </div>
                      </div>
                    </MobileView>
                  </>
                </Fragment>
              );
            }

            return null;
          })}
        </>
      ) : (
        <img className="custom-loading" src={LoadingImage} alt="loader for news" />
      )}
    </>
  );
}

export default React.memo(NewsDetail);
