import React from 'react';
import { Link } from 'react-router-dom';
import ServicesWeb from '../../images/banners/services-banner.jpg';
import ServicesMobile from '../../images/banners/services-mobile.jpg';
import { BrowserView, MobileOnlyView, TabletView, isIOS } from 'react-device-detect';


function Banner() {
  return (
    <>
      <BrowserView>
        <section className={(isIOS ? 'IpadViewNone' : 'service-banner-box')}>
        
          <div className="service-banner-box">
            <Link to="/services">
              <img
                src={ServicesWeb}
                alt="img"
                width="100%"
                height="300px"
                className="our-brand-img"
              />
            </Link>
          </div>
        </section>
      </BrowserView>
      <TabletView>
      <section className="service-banner-box">
          <div className="service-banner-box">
            <Link to="/services">
              <img
                src={ServicesWeb}
                alt="img"
                width="100%"
                height="300px"
                className="our-brand-img"
              />
            </Link>
          </div>
        </section>
      </TabletView>
      <MobileOnlyView>
        <section className="service-banner-box">
          <div className="service-banner-box">
            <Link to="/services">
              <img
                src={ServicesMobile}
                alt="img"
                width="100%"
                height="300px"
                className="our-brand-img"
              />
            </Link>
          </div>
        </section>
      </MobileOnlyView>
    </>
  );
}


export default Banner;
