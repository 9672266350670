import React from 'react';
import whatWeDoBanner from "../../images/banners/what-we-do.jpg"
import whatWeDoMobile from '../../images/banners/what-we-do-mobile.jpg';
import { BrowserView, MobileOnlyView,TabletView, isIOS } from 'react-device-detect';



function Banner() {
  return (
    <>
      <BrowserView>
        <section className={(isIOS ? 'IpadViewNone' : 'waht-we-do-banner-box')}>
        
          <div className="waht-we-do-banner">
            <div className="waht-we-do-sub-one">
              <img
                src={whatWeDoBanner}
                alt="what we do banner"
                width="100%"
                height="600px"
                className="our-brand-img"
              />
            </div>
          </div>
        </section>
      </BrowserView>
      <TabletView>
      <section className="waht-we-do-banner-box">
          <div className="waht-we-do-banner">
            <div className="waht-we-do-sub-one">
              <img
                src={whatWeDoBanner}
                alt="what we do banner"
                width="100%"
                height="600px"
                className="our-brand-img"
              />
            </div>
          </div>
        </section>
      </TabletView>
      <MobileOnlyView>
        <section className="waht-we-do-banner-box">
          <div className="waht-we-do-banner">
            <div className="waht-we-do-sub-one">
              <img
                src={whatWeDoMobile}
                alt="what we do banner"
                width="100%"
                height="600px"
                className="our-brand-img"
              />
            </div>
          </div>
        </section>
      </MobileOnlyView>
    </>
  );
}


export default Banner;
