import People_img1 from "../../images/icons/ico_people.png";
import Call_del from "../../images/icons/del_4_green.png";
import Blue_del from "../../images/icons/del_2_blue.png";
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ContactUsWeb from "../../images/banners/contact-us.jpg";
import ContactUsMobile from "../../images/banners/contact-us-mobile.jpg";
import { BrowserView, MobileView, isIOS } from "react-device-detect";
import NewContact from "./NewContact";

class Message extends Component {
  render() {
    return (
      <section>
        <>
          <BrowserView>
            <div className={isIOS ? "IpadViewNone" : "message-box-banner"}>
              <img
                src={ContactUsWeb}
                alt="what we do banner"
                width="100%"
                height="600px"
                className="our-brand-img"
              />
            </div>
          </BrowserView>
          <MobileView>
            <div className="message-box-banner">
              <img
                src={ContactUsMobile}
                alt="what we do banner"
                width="100%"
                height="600px"
                className="our-brand-img"
              />
            </div>
          </MobileView>
        </>
        <div className="message-box">
          <div className="message-box-grettings-message">
            <p>
              Get in touch with our friendly Customer Engagement Team using the
              below details. Our team are happy to take your call Monday to
              Friday. 9am to 5pm.
            </p>
          </div>
          <div className="message-box-contact-info">
            <div className="delivery-timeline-sub">
              <div className="delivery-timeline-main">
                <div className="delivery-timeline-sub-one">
                  <img src={People_img1} alt="Delivery_1" width="60%" />
                </div>
                <div className="delivery-timeline-sub-two">
                  <div>
                    <img src={Call_del} alt="Delivery_2" width="6%" />
                    <p>0345 209 7461</p>
                  </div>
                  <div className="clearBth"></div>
                  <div>
                    <img src={Blue_del} alt="Delivery_3" width="6%" />
                    <p>
                      Greenbank Business Park, Swan Lane, Hindley Green, Wigan,
                      WN2 4AY
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="message-box-sendmessage">
            <div className="message-box-grettings-message">
              <h4 className="send-us">How Can We Help?</h4>
              {/* <span className="send-email-area">Help & Support</span> */}
            </div>
            <div className="message-box-sendmessage-form">
              <NewContact />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Message;
