import React, { Component } from "react";
import yesImg from "../../images/yes.png";
import SupportSub from "./SupportSub";
import NewContact from "./NewContact";
import Support from "./Support";
import { Alert } from "reactstrap";

const { sendEmail } = require("./sendEmail");

class productSupportSub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContactUs: false,
      showSupport: false,
      showSupportSub: false,
      selectedFile: null,
      brands: [],
      sendRetryCount: 0,
      userSuccess: false,
      isSubmitting: false,
      formDataDefaults: {
        OrderNumber: "",
        Name: "",
        AddressLine1: "",
        AddressLine2: "",
        Town: "",
        Postcode: "",
        Telephone: "",
        Email: "",
        ModelNumber: "",
        BatchNumber: "",
        SerialNumber: "",
        Descriptionofissue: "",
        DynamicSubject: "Product Query",
        images: {},
      },
    };
  }

  hideComponent(name) {
    switch (name) {
      case "showContactUs":
        this.setState({ showContactUs: !this.state.showContactUs });
        break;
      case "showSupport":
        this.setState({ showSupport: !this.state.showSupport });
        break;
      case "showproductRegistration":
        this.setState({ showSupportSub: !this.state.showSupportSub });
        break;
      default:
        return null;
    }
  }

  handleSubmit = async () => {
    this.setState({
      isSubmitting: true,
    });

    const validationFields = {
      name: false,
      addressline1: false,
      postcode: false,
      telephone: false,
      email: false,
      modelnumber: false,
      batchnumber: false,
      descriptionofissue: false,
    };

    validationFields.name = /^\s*$/.test(this.state.formDataDefaults.Name);
    validationFields.addressline1 = /^\s*$/.test(
      this.state.formDataDefaults.AddressLine1
    );
    validationFields.postcode = /^\s*$/.test(
      this.state.formDataDefaults.Postcode
    );
    validationFields.telephone = /^\s*$/.test(
      this.state.formDataDefaults.Telephone
    );
    console.log("email", this.state.formDataDefaults.Email);
    validationFields.email =
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.state.formDataDefaults.Email
      );

    validationFields.modelnumber = /^\s*$/.test(
      this.state.formDataDefaults.ModelNumber
    );
    validationFields.batchnumber = /^\s*$/.test(
      this.state.formDataDefaults.BatchNumber
    );
    validationFields.descriptionofissue = /^\s*$/.test(
      this.state.formDataDefaults.Descriptionofissue
    );

    document.getElementById("message-form-name-validation").innerHTML =
      validationFields.name ? "required" : "";

    document.getElementById("message-form-addressline1-validation").innerHTML =
      validationFields.addressline1 ? "required" : "";

    document.getElementById("message-form-postcode-validation").innerHTML =
      validationFields.postcode ? "required" : "";

    document.getElementById("message-form-telephone-validation").innerHTML =
      validationFields.telephone ? "required" : "";

    document.getElementById("message-form-email-validation").innerHTML =
      validationFields.email
        ? /^\s*$/.test(this.state.formDataDefaults.Email)
          ? "required"
          : "invalid email"
        : "";

    document.getElementById("message-form-modelnumber-validation").innerHTML =
      validationFields.modelnumber ? "required" : "";

    document.getElementById("message-form-batchnumber-validation").innerHTML =
      validationFields.batchnumber ? "required" : "";

    document.getElementById(
      "message-form-descriptionofissue-validation"
    ).innerHTML = validationFields.descriptionofissue ? "required" : "";

    if (Object.values(validationFields).every((v) => !v)) {
      try {
        await sendEmail(this.state.formDataDefaults);
        this.setState({
          userSuccess: true,
        });
      } catch (error) {
        console.log(error);
        this.setState({
          userSuccess: false,
        });
        document.getElementById("message-form-submit-response").innerHTML =
          "Failed to send email";
      }
    }
    this.setState({
      isSubmitting: false,
    });
  };

  imageOnChange = async (e) => {
    const imagesDate = {};
    for (let i = 0; i < e.target.files.length; i++) {
      const base64 = await this.convertBase64(e.target.files[i]);
      const resultString = base64.split(";base64,")[1];
      imagesDate[e.target.files[i].name] = resultString;
    }
    const newformdata = { ...this.state.formDataDefaults };
    newformdata["images"] = { ...newformdata.images, ...imagesDate };
    this.setState({ formDataDefaults: newformdata });
  };

  removeImgageOnChange = async (imagekey) => {
    const newimages = { ...this.state.formDataDefaults.images };
    delete newimages[imagekey];
    const newformdata = { ...this.state.formDataDefaults };
    newformdata["images"] = { ...newimages };
    this.setState({ formDataDefaults: newformdata });
  };

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const filereader = new FileReader();
      filereader.readAsDataURL(file);
      filereader.onload = () => {
        resolve(filereader.result);
      };
      filereader.onerror = (error) => {
        reject(error);
      };
    });
  };

  showSuccess() {
    const { userSuccess } = this.state;
    if (userSuccess) {
      return (
        <Alert color="success">
          <p>Thank you, our friendly team will get back to you.</p>
        </Alert>
      );
    }
  }

  render() {
    const { showContactUs, showSupport, showSupportSub } = this.state;
    return (
      <div>
        <div>
          {showContactUs && <NewContact />}
          {showSupport && <Support />}
          {showSupportSub && (
            <SupportSub
              brand={this.props.brand}
              productRegistration={this.props.productRegistration}
            />
          )}
        </div>
        {!showContactUs && !showSupport && !showSupportSub && (
          <div>
            <article className="decision-tree">
              <div aria-hidden="false" className="delivery-decision">
                <img src={yesImg} alt="yes" className="yesImg" />
                <h6 className="contact-txt product-registration">
                  Product Query
                </h6>
                <button
                  className="change-box"
                  onClick={() =>
                    this.setState({ showContactUs: !showContactUs })
                  }
                >
                  Change
                </button>
              </div>
              <div
                aria-hidden="false"
                className="delivery-decision supportSub-decision"
              >
                <img src={yesImg} alt="yes" className="yesImg" />
                <h6 className="contact-txt product-registration">
                  {this.props.brand}
                </h6>
                <button
                  className="change-box brand-change-sub"
                  onClick={() => this.setState({ showSupport: !showSupport })}
                >
                  Change
                </button>
              </div>
              <div
                aria-hidden="false"
                className="delivery-decision supportSub-decision"
              >
                <img src={yesImg} alt="yes" className="yesImg" />
                <h6 className="contact-txt product-registration font-weight-bold">
                  Product Support
                </h6>
                <button
                  className="change-box product-change mobile-productsupport"
                  onClick={() =>
                    this.setState({ showSupportSub: !showSupportSub })
                  }
                >
                  Change
                </button>
              </div>
            </article>
            {this.showSuccess()}
            {this.showSuccess() === undefined ? (
              <div className="box-padding box contact-us-form">
                <div className="row">
                  <div className="addBorder">
                    <div className="col-md-12 mt-3 ">
                      <div className="form-group">
                        <label htmlFor="OrderNumber" className="text-dark">
                          Order Number
                        </label>
                        <div className="cstm-fld mb-2 mr-sm-2 mb-sm-0">
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              const newFormData = {
                                ...this.state.formDataDefaults,
                              };
                              newFormData["OrderNumber"] = e.target.value;
                              this.setState({ formDataDefaults: newFormData });
                            }}
                            value={this.state.formDataDefaults["OrderNumber"]}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="Name" className="text-dark">
                          Name <span style={{ color: "#ff0000" }}>*</span>{" "}
                          <span
                            id="message-form-name-validation"
                            className="message-form-name-validation"
                            style={{ color: "#ff0000" }}
                          >
                            {" "}
                          </span>
                        </label>
                        <div className="cstm-fld mb-2 mr-sm-2 mb-sm-0">
                          <input
                            type="text"
                            className="form-control"
                            data-testid="name-input"
                            required
                            onChange={(e) => {
                              const newFormData = {
                                ...this.state.formDataDefaults,
                              };
                              newFormData["Name"] = e.target.value;
                              this.setState({ formDataDefaults: newFormData });
                            }}
                            value={this.state.formDataDefaults["Name"]}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="form-group">
                          <label htmlFor="AddressLine1" className="text-dark">
                            Address Line 1{" "}
                            <span style={{ color: "#ff0000" }}>*</span>{" "}
                            <span
                              id="message-form-addressline1-validation"
                              className="message-form-addressline1-validation"
                              style={{ color: "#ff0000" }}
                            >
                              {" "}
                            </span>
                          </label>
                          <div className="cstm-fld mb-2 mr-sm-2 mb-sm-0">
                            <input
                              type="text"
                              className="form-control"
                              data-testid="address-input"
                              required
                              onChange={(e) => {
                                const newFormData = {
                                  ...this.state.formDataDefaults,
                                };
                                newFormData["AddressLine1"] = e.target.value;
                                this.setState({
                                  formDataDefaults: newFormData,
                                });
                              }}
                              value={
                                this.state.formDataDefaults["AddressLine1"]
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="AddressLine2" className="text-dark">
                            Address Line 2
                          </label>
                          <div className="cstm-fld mb-2 mr-sm-2 mb-sm-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength=""
                              onChange={(e) => {
                                const newFormData = {
                                  ...this.state.formDataDefaults,
                                };
                                newFormData["AddressLine2"] = e.target.value;
                                this.setState({
                                  formDataDefaults: newFormData,
                                });
                              }}
                              value={
                                this.state.formDataDefaults["AddressLine2"]
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="Town" className="text-dark">
                            Town
                          </label>
                          <div className="cstm-fld mb-2 mr-sm-2 mb-sm-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength=""
                              onChange={(e) => {
                                const newFormData = {
                                  ...this.state.formDataDefaults,
                                };
                                newFormData["Town"] = e.target.value;
                                this.setState({
                                  formDataDefaults: newFormData,
                                });
                              }}
                              value={this.state.formDataDefaults["Town"]}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="Postcode" className="text-dark">
                            Postcode <span style={{ color: "#ff0000" }}>*</span>{" "}
                            <span
                              id="message-form-postcode-validation"
                              className="message-form-postcode-validation"
                              style={{ color: "#ff0000" }}
                            >
                              {" "}
                            </span>
                          </label>
                          <div className="cstm-fld mb-2 mr-sm-2 mb-sm-0">
                            <input
                              type="text"
                              className="form-control"
                              data-testid="postcode-input"
                              required
                              onChange={(e) => {
                                const newFormData = {
                                  ...this.state.formDataDefaults,
                                };
                                newFormData["Postcode"] = e.target.value;
                                this.setState({
                                  formDataDefaults: newFormData,
                                });
                              }}
                              value={this.state.formDataDefaults["Postcode"]}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="Telephone" className="text-dark">
                            Telephone{" "}
                            <span style={{ color: "#ff0000" }}>*</span>{" "}
                            <span
                              id="message-form-telephone-validation"
                              className="message-form-telephone-validation"
                              style={{ color: "#ff0000" }}
                            >
                              {" "}
                            </span>
                          </label>
                          <div className="cstm-fld mb-2 mr-sm-2 mb-sm-0">
                            <input
                              type="tel"
                              className="form-control"
                              data-testid="telephone-input"
                              required
                              onChange={(e) => {
                                const newFormData = {
                                  ...this.state.formDataDefaults,
                                };
                                newFormData["Telephone"] = e.target.value;
                                this.setState({
                                  formDataDefaults: newFormData,
                                });
                              }}
                              value={this.state.formDataDefaults["Telephone"]}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="Email" className="text-dark">
                            Email <span style={{ color: "#ff0000" }}>*</span>{" "}
                            <span
                              id="message-form-email-validation"
                              className="message-form-email-validation"
                              style={{ color: "#ff0000" }}
                            >
                              {" "}
                            </span>
                          </label>
                          <div className="cstm-fld mb-2 mr-sm-2 mb-sm-0">
                            <input
                              type="email"
                              className="form-control"
                              data-testid="email-input"
                              required
                              onChange={(e) => {
                                const newFormData = {
                                  ...this.state.formDataDefaults,
                                };
                                newFormData["Email"] = e.target.value;
                                this.setState({
                                  formDataDefaults: newFormData,
                                });
                              }}
                              value={this.state.formDataDefaults["Email"]}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="ModelNumber" className="text-dark">
                            Model Number{" "}
                            <span style={{ color: "#ff0000" }}>*</span>{" "}
                            <span
                              id="message-form-modelnumber-validation"
                              className="message-form-modelnumber-validation"
                              style={{ color: "#ff0000" }}
                            >
                              {" "}
                            </span>
                          </label>
                          <div className="cstm-fld mb-2 mr-sm-2 mb-sm-0">
                            <input
                              type="text"
                              className="form-control"
                              data-testid="model-input"
                              required
                              onChange={(e) => {
                                const newFormData = {
                                  ...this.state.formDataDefaults,
                                };
                                newFormData["ModelNumber"] = e.target.value;
                                this.setState({
                                  formDataDefaults: newFormData,
                                });
                              }}
                              value={this.state.formDataDefaults["ModelNumber"]}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="BatchNumber" className="text-dark">
                            Batch Number{" "}
                            <span style={{ color: "#ff0000" }}>*</span>{" "}
                            <span
                              id="message-form-batchnumber-validation"
                              className="message-form-batchnumber-validation"
                              style={{ color: "#ff0000" }}
                            >
                              {" "}
                            </span>
                          </label>
                          <div className="cstm-fld mb-2 mr-sm-2 mb-sm-0">
                            <input
                              type="text"
                              className="form-control"
                              data-testid="batch-input"
                              required
                              onChange={(e) => {
                                const newFormData = {
                                  ...this.state.formDataDefaults,
                                };
                                newFormData["BatchNumber"] = e.target.value;
                                this.setState({
                                  formDataDefaults: newFormData,
                                });
                              }}
                              value={this.state.formDataDefaults["BatchNumber"]}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="SerialNumber" className="text-dark">
                            Serial Number
                          </label>
                          <div className="cstm-fld mb-2 mr-sm-2 mb-sm-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength=""
                              onChange={(e) => {
                                const newFormData = {
                                  ...this.state.formDataDefaults,
                                };
                                newFormData["SerialNumber"] = e.target.value;
                                this.setState({
                                  formDataDefaults: newFormData,
                                });
                              }}
                              value={
                                this.state.formDataDefaults["SerialNumber"]
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="Descriptionofissue"
                            className="text-dark"
                          >
                            Description of issue{" "}
                            <span style={{ color: "#ff0000" }}>*</span>{" "}
                            <span
                              id="message-form-descriptionofissue-validation"
                              className="message-form-descriptionofissue-validation"
                              style={{ color: "#ff0000" }}
                            >
                              {" "}
                            </span>
                          </label>
                          <div className="cstm-fld mb-2 mr-sm-2 mb-sm-0">
                            <textarea
                              id="subject"
                              name="subject"
                              className="form-control commentArea"
                              data-testid="description-input"
                              required
                              onChange={(e) => {
                                const newFormData = {
                                  ...this.state.formDataDefaults,
                                };
                                newFormData["Descriptionofissue"] =
                                  e.target.value;
                                this.setState({
                                  formDataDefaults: newFormData,
                                });
                              }}
                              value={
                                this.state.formDataDefaults[
                                  "Descriptionofissue"
                                ]
                              }
                            ></textarea>
                          </div>
                          <div className="form-group">
                            <label htmlFor="images" className="text-dark mt-4">
                              Image upload
                            </label>
                          </div>
                          <div className="multipleImage mb-3">
                            <label className="btn btn-default p-0">
                              <input
                                type="file"
                                multiple
                                accept="image/*"
                                onChange={(e) => {
                                  this.imageOnChange(e);
                                }}
                                style={{ color: "transparent" }}
                              />
                            </label>
                            <br />
                            <div
                              id="message-form-images-count"
                              style={{
                                marginLeft: "0px",
                                textAlign: "left",
                              }}
                            >
                              {Object.keys(this.state.formDataDefaults.images)
                                .length > 0 ? (
                                <table>
                                  {Object.entries(
                                    this.state.formDataDefaults.images
                                  ).map((value, key) => {
                                    return (
                                      <tr>
                                        <td
                                          style={{
                                            padding: "0px -10px 10px 20px",
                                          }}
                                        >
                                          {value[0]}
                                        </td>
                                        <td
                                          style={{
                                            padding: "0px 0px 10px 20px",
                                          }}
                                        >
                                          <input
                                            type="button"
                                            onClick={(e) => {
                                              this.removeImgageOnChange(
                                                value[0]
                                              );
                                            }}
                                            value="Remove"
                                          />
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </table>
                              ) : (
                                <>
                                  Please attach any images of the product that
                                  may help our Customer Service Team assist in
                                  resolving the issue
                                </>
                              )}
                            </div>
                          </div>
                          <div className="mt-4">
                            <span style={{ color: "#ff0000" }}>*</span> Fields
                            are mandatory
                          </div>
                          <div className="message-submit mt-5">
                            <button
                              className="submit-button"
                              data-testid="submit-button"
                              onClick={this.handleSubmit}
                              disabled={this.state.isSubmitting}
                            >
                              {this.state.isSubmitting ? "SENDING" : "SEND"}
                            </button>
                            <h4
                              id="message-form-submit-response"
                              className="message-form-submit-response"
                            >
                              {" "}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default productSupportSub;
