import React from 'react';
import Banner from './banner';
import ServicesWeOffer from './services-we-offer';
import OurBrandsImage from './our-brands-image';
import Testimonials from './testimonials';
import OfferingQuality from './offering-quality';
import ProprietaryBrands from './proprietary-brands';
import LicensedBrands from './licensed-brands';
import DistributionPartners from './distribution-partners';
import NavigationBar from '../common/navigation-bar';

function home() {
  return (
    <div>
      <NavigationBar />
      <Banner />
      <div className="about-us-box">
        <OfferingQuality />
        <ProprietaryBrands />
        <LicensedBrands />
        <DistributionPartners />
        <OurBrandsImage />
        {/* <Testimonials /> */}
      </div>
      <ServicesWeOffer />
    </div>
  );
}

export default home;
