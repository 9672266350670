import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { unmarshall} from "@aws-sdk/util-dynamodb";
import LoadingImage from '../../images/loader/loading.gif';
import { getNews } from '../../getNews';



const News = () => {
  const [news, setNews] = useState([]);
  const [isEmptyNews, setIsEmptyNews] = useState(false);

  useEffect(() => {
    getNews().then(response => {
      if (response.Items.length !== 0) {
        const unmarshalledItems = response.Items.map(Item => {
          return unmarshall(Item)
        })
        setNews(unmarshalledItems);
      }
      else {
        setIsEmptyNews(news.length === 0);
      }
    }).catch(error => {
      console.log('Dynamodb returned - error ', error);
    })
 
  }, []);

 news.sort(function (a, b) {
   var keyA = '';
   var keyB = '';
   if (a.date.search('/') !== -1 && b.date.search('/') !== -1) {
     keyA = Date.parse(
       a.date.split('/')[1] + '/' + a.date.split('/')[0] + '/' + a.date.split('/')[2],
     );
     keyB = Date.parse(
       b.date.split('/')[1] + '/' + b.date.split('/')[0] + '/' + b.date.split('/')[2],
     );
   } else {
     keyA = Date.parse(a.date.replace(/(th|st)/i, ''));
     keyB = Date.parse(b.date.replace(/(th|st)/i, ''));
   }
   if (keyA < keyB) return 1;
   if (keyA > keyB) return -1;
   return 0;
 });

  const NewsBoxRendere = () =>
    news.map((item, index) => (
      <div className="news-box" key={index}>
        <div className="news-box-sub" style={{ width: !item.image && '95%' }}>
          <h4>{item.title}</h4>
          <div className="LatoFontS5Space">{item.date}</div>
          <p>{item.shortDesc}</p>
          {item.longDesc && (
            <Link to={`/news/${item.slug}`} className="news-link ">
              more…
            </Link>
          )}
        </div>
        {item.image && (
          <div className="news-box-sub">
            <img src={item.image} alt="news img" className="news_img" />
          </div>
        )}
        {news.length - 1 !== index && <hr className="news-border" />}
      </div>
    ));

  const LoadingComponent = () => {
    // There could be possible senerios where data comes empty in that case if we check only length
    // that will cause serious bug of showing loading when no data

    return isEmptyNews ? <h1 className='news-no-data'> </h1> : <img className='custom-loading' src={LoadingImage} alt='loader for news'/>;
  }

  return (Array.isArray(news) && news.length > 0) ? <NewsBoxRendere /> : <LoadingComponent />;

};

export default News;
