import React from 'react';

function AboutUs() {
  return (
    <section id="about-content">
      <div className="about-box">
        <h4>About Us</h4>
        <p>
          Product Care Group is a privately owned, dynamic business, focused on providing home appliance
          solutions to retailers, consumers and business to business customers.
        </p>
        <p>
          We take care of the complete supply chain from product sourcing and factory management to home
          delivery and everything in between. We take the headache out of getting the right product, to the
          right place, at the right time.
        </p>
        <p>
          Since 2009 we have been delighting retailers with our exceptional service and innovative solutions.
          Our energetic team boast vast experience of working in this sector, both in the UK and China.
        </p>
      </div>
    </section>
  );
}

export default AboutUs;
