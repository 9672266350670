import React from 'react';
import rating from '../../images/page-services/rating-2.png';
function Ratings() {
  return (
    <div className="rating-box">
      <img src={rating} alt="rateing" width="90%" height="100%" />
    </div>
  );
}

export default Ratings;
