import React from 'react';
import OurSustainabilityimg from '../../images/page-ethical-social-resposinility/sustainability-misison-2.png';
import HeadImg from '../../images/icons/ico_recycle.png';
import { BrowserView, MobileOnlyView, TabletView, isIOS } from 'react-device-detect';
function OurSustainability() {
  return (
    <>
      <BrowserView>
        <div className={ (isIOS ? 'IpadViewNone' : 'our-sustainability-box') }>
          <h4>
            <img src={ HeadImg } height="56px" width="56px" alt="HeadImg" /> Environmental Social Governance Framework Statement
          </h4>
        </div>
        <div className={ (isIOS ? 'IpadViewNone' : 'our-sustainability-box') }>
          <div className="our-sustainability-sub">
            <p>Our Environmental Social Governance Framework Statement demonstrates our commitment and culture towards ‘doing the right thing’. This framework helps us to continually consider relevant sustainability issues and challenges during the complete lifecycle of a product, how we run our business from an ethical perspective as well as how we work alongside our teams and support and develop our people.</p>
          </div>
          <img
            src={ OurSustainabilityimg }
            alt="OurSustainabilityimg"
            className="OurSustainabilityimg"
          />
        </div>
      </BrowserView>
      <TabletView>
        <div className={ (isIOS ? 'IpadViewNone' : 'our-sustainability-box') }>
          <h4>
            <img src={ HeadImg } height="56px" width="56px" alt="HeadImg" /> Environmental Social Governance Framework Statement
          </h4>
        </div>
        <div className="our-sustainability-box">
          <div className="our-sustainability-sub">
            <p>Our Environmental Social Governance Framework Statement demonstrates our commitment and culture towards ‘doing the right thing’. This framework helps us to continually consider relevant sustainability issues and challenges during the complete lifecycle of a product, how we run our business from an ethical perspective as well as how we work alongside our teams and support and develop our people.</p>
          </div>
          <img
            src={ OurSustainabilityimg }
            alt="OurSustainabilityimg"
            className="OurSustainabilityimg"
          />
        </div>
      </TabletView>
      <MobileOnlyView>
        <div className={ (isIOS ? 'IpadViewNone' : 'our-sustainability-box') }>
          <h4>
            <img src={ HeadImg } height="56px" width="56px" alt="HeadImg" /> Environmental Social Governance Framework Statement
          </h4>
        </div>
        <div className="our-sustainability-box">
        <div className="our-sustainability-sub">
              <p>Our Environmental Social Governance Framework Statement demonstrates our commitment and culture towards ‘doing the right thing’. This framework helps us to continually consider relevant sustainability issues and challenges during the complete lifecycle of a product, how we run our business from an ethical perspective as well as how we work alongside our teams and support and develop our people.</p>
            </div>
            <img
              src={ OurSustainabilityimg }
              alt="OurSustainabilityimg"
              className="OurSustainabilityimg"
            />
        </div>
      </MobileOnlyView>
    </>
  );
}

export default OurSustainability;
