import React from 'react';
import service_1 from '../../images/page-services/storage-1.jpg';
import service_2 from '../../images/page-services/storage-2.jpg';
import service_3 from '../../images/page-services/storage-3.jpg';

function Services() {
  return (
    <section id="Services-content">
      <div className="service-box">
        <div className="service-item">
          <div className="service-item-sub-div">
            <img
              src={service_1}
              alt="service"
              height="100%"
              width="100%"
              className="service-img"
            />
            <div className="service-img">
              <img
                src={service_2}
                alt="service"
                height="135px"
                width="100%"
                className="service-img1"
              />
              <img
                src={service_3}
                alt="service"
                height="135px"
                width="100%"
                className="service-img2"
              />
            </div>
          </div>
        </div>
        <div className="service-item">
          <h4 className="custom-color">Storage & Logistics Solutions</h4>
          <p className="custom-color">
            Warehousing facilities offer flexible, short-term and
            long-term inventory management options. This includes
            palletised and floor stacked areas.
          </p>
          <h5 className="custom-color">Systems</h5>
          <p className="custom-color">
            Our custom supply chain and warehouse
            management systems empower us to accurately and
            efficiently handle all our clients’ inventory and order
            management needs.
          </p>
          <h5 className="custom-color">Services</h5>
          <p className="custom-color">
            We have two major distribution centres located in the
            North and the Midlands, enabling us to provide a
            full-service solution.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Services;
