import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Image
import equality from '../../images/icons/ico_scales.png';

function EqualityDiversity({ isLoaded }) {
  const equalityAndDiversity = useRef();
  const history = useHistory();

  useEffect(() => {
    const linkState = history.location ? history.location.state : null;
    const isScroll = linkState ? linkState.isScroll : null;
    if (equalityAndDiversity.current && isScroll && isLoaded) {
      equalityAndDiversity.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ history.location, isLoaded ]);

  return (
    <div className="equality-diversity-box" ref={ equalityAndDiversity }>
      <h4 className="popinFontH2">
        <img src={ equality } alt="equality" />
        Equality & Diversity
      </h4>
      <p className="popinFontH6">
        The Product Care Group are committed to equal opportunities for all. The aim is for our team to be truly
        representative of all sections of society, and for each colleague to feel respected and able to give their best.
      </p>
      <p className="popinFontH6">
        We aim to ensure that no one is discriminated against, directly or indirectly, on the grounds of ethnicity,
        sexual orientation, gender, age, disability, religion or any other characteristic.
      </p>
      <p>
        <b className="popinFontH4">To support this, we:</b>
      </p>
      <ul>
        <li>
          <span className="popinFontH5">Ensure everyone has equal opportunities when applying for vacancies</span>
        </li>
        <li>
          <span className="popinFontH5"> Review employment practices and recruitment procedures when necessary to ensure fairness</span>
        </li>
        <li>
          <span className="popinFontH5">Monitor the make-up of the team regarding age, sex, ethnic background, sexual orientation,
            religion or belief, and disability in encouraging equality, diversity and inclusion</span>
        </li>
        <li ><span className="popinFontH5">Carry out gender pay gap reporting</span></li>
        <li >
          <span className="popinFontH5">Assess how our equality, diversity and inclusion policy, and any supporting action plans,
            are working in practice and we take action to address any issues</span>
        </li>
      </ul>
    </div>
  );
}

export default EqualityDiversity;
