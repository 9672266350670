import React from 'react';
import LicensedBrands_img from '../../images/page-our-brands/licensed-brands.jpg';
import LicensedBrands_img1 from '../../images/brands/licensed-brands/russell-hobbs.png';
import LicensedBrands_img2 from '../../images/brands/licensed-brands/george-foreman.png';
import LicensedBrands_img3 from '../../images/brands/licensed-brands/zanussi.png';
import LicensedBrands_img4 from '../../images/brands/licensed-brands/rangemaster.png';

function LicensedBrands() {
  return (
    <div className="licensed-brands-box">
      <div className="licensed-brands-box-sub licensed-brands-sec">
        <div className="licensed-brands-box-sub-sub ">
          <h4>Licensed Brands</h4>
          <p>
            We pride ourselves on working closely with our licenced partners across a number of product categories, as well as being the exclusive licence holder of the Russell Hobbs brand for Microwaves, Large Appliances,
            Floorcare, Heating, Cooling and Air Treatment.
          </p>
          <p>
            Our licensed ranges include George Foreman BBQs, Rangemaster Small Appliances and a variety of Heating, Cooling, Air Treatment, Patio Heaters, BBQs and Pizza Ovens under the Zanussi brand.
          </p>
          <img
            src={ LicensedBrands_img1 }
            alt="RussellHobbsBrandLogos"
            width="50%"
            height="30%"
            className="LicensedBrands_logo"
          />
          <img
            src={ LicensedBrands_img2 }
            alt="GeorgeForemanBrandLogos"
            width="50%"
            height="30%"
            className="LicensedBrands_logo"
          />
          <img
            src={ LicensedBrands_img3 }
            alt="ZanussiBrandLogos"
            width="50%"
            height="30%"
            className="LicensedBrands_logo"
          />
          <img
            src={ LicensedBrands_img4 }
            alt="RangemasterBrandLogos"
            width="50%"
            height="30%"
            className="LicensedBrands_logo"
          />
        </div>
      </div>
      <div className="licensed-brands-box-sub">
        <img
          src={ LicensedBrands_img }
          width="90%"
          alt="LicensedBrands_img"
          className="LicensedBrands-img"
        />
      </div>
    </div>
  );
}

export default LicensedBrands;
