import React from "react";

const ProfilePopup = props => {

  return (
    <div className="popup-box" onClick={ (event) => {
      const parentElement = document.querySelector(".box");
      if (!parentElement.contains(event.target)) {
        props.handleClose("", "", "", "", '', false)
      }
    } }>
      <div className="box" onClick={ (event) => {
        const parentElement = document.querySelector(".box");
        if (!parentElement.contains(event.target)) {
          props.handleClose("", "", "", "", '', false)
        }
      } }>
        <span className="close-icon" onClick={ (event) => {
          console.log('close button', event.target)
          props.handleClose("", "", "", "", '', false)
        } }>x</span>
        { props.content }
      </div>
    </div>
  );
};

export default ProfilePopup;