import Banner from "../about-us/banner";
import NavigationBar from '../common/navigation-bar';
import React from "react";
import sign from '../../images/core-management-sign/sign.png';

function Home() {
  return (
    <div>
      <NavigationBar />
      <Banner />
      <div className="generic-page-content about-us-box core-management-policy" style={{marginBottom:'50px'}}>
        <div style={ { display: "flex", alignItems: "center", flexDirection: "column" } }>
          <h1 style={ { textAlign: "center" } }>PRODUCT CARE GROUP</h1>
          <h1 style={ { textAlign: "center" } }>CORE MANAGEMENT SYSTEM POLICY STATEMENTS</h1>
          <h1 style={ { textAlign: "center" } }>v.1.4</h1>
        </div>
        <div>
          <h4>Contents</h4>
          <ul>
            <li >Revision Control</li>
            <li>Occupational Health & Safety</li>
            <li>Health & Safety</li>
            <li>Safeguarding</li>
            <li>Drug & Alcohol</li>
            <li>Information Security & Data Protection</li>
            <li>Modern Slavery & Human Trafficking</li>
          </ul>

          <h4>Product Care Group consists of</h4>
          <ul>
            <li>Product Care Trading Limited</li>
            <li>Product care Services Limited</li>
            <li>Product Care Online Limited</li>
          </ul>
        </div>
        <div id="revision-control">
          <h2 >REVISION CONTROL</h2>
          <h2>Revision Log</h2>
          <table>
            <tr>
              <th>Version</th>
              <th>Status</th>
              <th>Date Issued</th>
              <th>Details of Change</th>
              <th>Pages Affected</th>
              <th>Next planned review</th>
            </tr>
            <tr>
              <td>1</td>
              <td>Published</td>
              <td>25/01/2022</td>
              <td>All policies previously held as separate documents. Now combined to reflect organisational restructure and rebranding</td>
              <td></td>
              <td>Jan 2024</td>
            </tr>
            <tr>
              <td>1.1</td>
              <td>Published</td>
              <td>29/03/2022</td>
              <td>Environmental Policy added to document</td>
              <td>Page 8 added</td>
              <td>March 2024</td>
            </tr>
            <tr>
              <td>1.2</td>
              <td>Published</td>
              <td>06/05/2022</td>
              <td>
                <ul>
                  <li style={ { listStyle: 'decimal' } }>Minor wording change to Drug & Alcohol policy</li>
                  <li style={ { listStyle: 'decimal' } }>Page numbers and version control added to footer</li>
                </ul>
              </td>
              <td>
                Page 5 <br />
                All
              </td>
              <td>March 2024</td>
            </tr>
            <tr>
              <td>1.3</td>
              <td>Published</td>
              <td>09/05/2022</td>
              <td>Terminology changed within drug policy (“illegal” replaced with “non-prescribed”)</td>
              <td>Page 5</td>
              <td>March 2024</td>
            </tr>
            <tr>
              <td>1.4</td>
              <td>Published</td>
              <td>06/07/2022</td>
              <td>Minor wording change to Information Security policy statement</td>
              <td>Page 6</td>
              <td>March 2024</td>
            </tr>
          </table>
        </div>
        <div>
          <h2>OCCUPATIONAL HEALTH & SAFETY</h2>
          <p>Product Care Group operates as a leading competitor in the global supply chain and logistics market, and as such recognises its duty under law to establish a working environment for its staff, conducive to sustainable, safe and healthy employment. This legal duty to adhere to the Health & Safety at Work Act 1974 has led to the establishment of an Occupational Health & Safety Management System aligned with the requirements of BS ISO 45001: Occupational Health & Safety Management System.  The group has therefore established 3 policies, set to follow, to provide high level governance of our approach to</p>
          <ul>
            <li>Health & Safety</li>
            <li>Safeguarding of Vulnerable People</li>
            <li>Drug & Alcohol</li>
          </ul>
          <p>All policy information for Health & Safety is supplementary to the Occupational Health & Safety Management System held and managed by the Group Risk & Compliance Manager, and is authorised by the Board of Directors.</p>
          <div>
            <h5>HEALTH & SAFETY</h5>
            <p>The management system implemented by the company is under the continual direction and management of a Group Risk & Compliance Manager with the support and leadership of the senior management team who are committed to </p>
            <ul>
              <li>The provision of safe and healthy working conditions for its employees</li>
              <li>The prevention of work-related ill-health and injury</li>
              <li>Using the widely recognised hierarchy of controls to identify and control all foreseeable risks</li>
              <li>The allocation of resources such as financial, time and personnel, to ensure the continual improvement of an effective and sustainable OH&S Management System</li>
              <li>The participation of, and consultation with all staff on matters relating to OH&S</li>
            </ul>
            <p>The Group Risk & Compliance Manager under the leadership of the senior management team is responsible for, but not limited to</p>
            <ul>
              <li>Implementing, developing and managing personnel resource for the management of Health & Safety</li>
              <li>Identifying and ensuring compliance with all known legal and other requirements</li>
              <li>Taking into account all OH&S opportunities and risks, in relation to the management and improvement of the OH&S Management System and working environments, processes and procedures</li>
              <li>Incorporating into the management system the needs and expectations of externally and internally interested parties, and where applicable workers’ representatives</li>
              <li>Regularly evaluating the performance of the OH&S Management System, and ensuring a suitably sufficient plan for identifying and correcting non-conformances</li>
              <li>Adopting and managing a program of targets and objectives, based on business needs and projected activities, to manage the continual improvement and application of the effective and sustainable OH&S Management System</li>
              <li>Ensuring this policy statement and other management system documents are communicated and available to all relevant interested parties</li>
              <li>Determining, developing and implementing suitably sufficient plans and resources for emergency preparedness</li>
            </ul>
          </div>
          <div>
            <h5>SAFEGUARDING</h5>
            <p>Although the company does not routinely or frequently employ children, young or other vulnerable persons, this safeguarding policy is implemented for exceptions to this rule. However, through the course of its usual business, there may be persons on its premises who are considered as children, young or vulnerable persons. There may also be times when working in the homes of customers, where Safeguarding of children, young or vulnerable persons must be considered.</p>
            <p>The presence of such personnel in any instance where their safety and wellbeing may be affected by our activities, requires the company to provide extra vigilance and consideration to the needs of those people, who may be at risk.</p>
            <p>The company will ensure that at all times, when such people are at risk, they are provided the appropriate level of care and protection through the Health & Safety Management System, as well as any other applicable measure. </p>
            <p>Such instances where this policy may provide cover are</p>
            <ul>
              <li>	Young person’s attending on work experience/training programs, or as an employee</li>
              <li>	Chaperoned children attending for promotional video/photography purposes</li>
              <li>	Home Delivery technicians undertaking work in customers’ homes or in the public domain</li>
            </ul>
            <p>This safeguarding policy is set to align with the Health & Safety Management System to ensure</p>
            <ul>
              <li>	No person is subject to exploitation</li>
              <li>	All personnel are afforded the correct protection and care</li>
              <li>	That all applicable regulation and legislation is identified for each individual case, and appropriate compliance standards are met</li>
              <li>	That all colleagues, and people at risk are protected, valued, listened to and respected</li>
            </ul>
            <p>All instances of young persons, young adults or children in attendance at site will be subject to unique and specific risk assessments, hazard management and reviews by the HSE Manager. Where Home Delivery Technicians undertake work in the public domain, or customers’ homes where vulnerable persons are present. They shall include consideration ofthe same in any dynamic or other risk assessment that is required. </p>
          </div>
          <div>
            <h5>DRUG & ALCOHOL</h5>
            <p>In line with its legal duties under identified legislation and regulations, Product Care Group assumes responsibility, so far as is reasonably practicable, to ensure that staff performing operations on its behalf  </p>
            <ul>
              <li>Do not bring alcohol or non-prescribed drugs onto company premises</li>
              <li>Attend for work, free from the effects and impairments associated with alcohol and non-prescribed drugs</li>
              <li>Are aware of the dangers of the effects of alcohol and drugs in the workplace</li>
              <li>Inform their managers of any prescribed medication, that may impact on their ability to work safely</li>
              <li>Do not operate machinery or vehicles whilst under the effects of non-prescribed drugs or alcohol</li>
              <li>Seek assistance from their GP in relation to any dependency issue</li>
              <li>Are aware they can contact their line manager, the Head of Human Resources or The Health & Safety teamin confidence, to discuss any dependency for which they require help, and colleaguesshould appreciate that the company will look to work with any colleague who comes forward to discuss and try to solve such issues</li>
              <li>Be aware of the effects of non-prescribed drugs and alcohol in the workplace and offer support to colleagues and co-operate with the employer in all matters relating to Health & Safety</li>
              <li>Understand their legal duty under The Health & Safety at Work Act, to take reasonable care for the health &safety of themselves and anyone who can be affected by their acts and omissions</li>
              <li>Understand their obligations under The Road Traffic Act, where colleagues drive a company vehicle or their own on the public highways or in public places</li>
            </ul>
            <p>Product Care Group will conduct screening as part of a planned program of risk management, and in response to a specific event, to implement this policy, which will involve either breath testing for alcohol, or drug screening through the use of an appropriatesampling kit for the purpose of detecting non-prescribed drugs. When required, the company may request further analysis be conducted by a duly appointed laboratory. Where the presence of non-prescribed drugs is detectedand/or further analysis from a specialist laboratory may be required, staff may be suspended without pay or on full pay, pending the results from the laboratory or the outcome of appropriate meetings. All decisions regarding paid or unpaid suspension are taken at Director discretion. </p>
            <p>Penalties that may be imposed on staff as a result of one or more breaches of this policy include, but are not limited to</p>
            <ul>
              <li>Suspension without pay for one or more days</li>
              <li>Disciplinary action</li>
              <li>Termination of Employment</li>
            </ul>
            <p>This policy shall be applied to all staff within Product Care Group, at all operational sites and where an illegal act has been committed, the company may contact the police for guidance and assistance. This includes the discovery of illegal drugs in the workplace, where illegal drugs have been consumed on company premises or whilst an employee is at work and any suspected breach of The Road Traffic Act.</p>
            <p>The full policy and procedures for managing the risks associated with drugs & alcohol in the workplace are held by the Group Risk & Compliance Manager, the Head of Human Resources and is also contained within the Health & Safety Management System.</p>
          </div>
        </div>
        <div>
          <h2>INFORMATION SECURITY & DATA PROTECTION</h2>
          <p>Throughout its operational activities, Product Care Group will view, hold, manipulate, manage, store and dispose of information in relation to a wide range of stakeholders. Such information will be managed according to known legal requirements, including any requirement arising from contractual agreements, changing legislative requirements or any requirement the company identifies as being applicable to its operations. Such controls used by the company shall apply to all data and information being used, accessed, held, stored or otherwise manipulated by the company.</p>
          <p>The management system implemented by the company is under the continual direction and leadership of the senior and executive management team, who are committed to ensuring that the efforts to protect information are achieved through the continual monitoring or performance against targets and objectives.</p>
          <p>To ensure continued suitability of this ISMS, through continual improvement, and the application of the “Plan-do-Check-Act” methodology, Product Care Group will also set and monitor performance towards objectives. These objectives will be agreed by the Executive and Senior Management teams, performance towards which will be managed and reviewed at agreed intervals by the management teams, with objectives being assigned owners and the management of objectives undertaken within the documented ISMS.</p>
          <p>These targets will be determined and set as part of an annual management review program, based on issues such as the results of audits, inspections and certification assessments conducted by external bodies.</p>
          <p>The approach to information security is managed by colleagues with assigned responsibilities for the technical and administrative approach, and these efforts are supported directly by Board of Executive Directors. The arrangements for Information Security are communicated to all staff, and are available in electronic and paper formats, and these arrangements are also subjected to regular reviews and continual improvement where practicable.</p>
          <p>This policy is also a commitment to a suitable program of training, education and awareness for all staff who are expected to comply with the Data Protection Act and the General Data Protection Regulations which impose regulatory authority on our activities. Product Care Group will also respond as required to lawful requests from data subjects in relation to data obtained and held, and ensure the principles of the GDPR are followed, and these are summarised to follow.</p>
          <p>Data shall be</p>
          <ul>
            <li>	Processed lawfully and fairly</li>
            <li>	Collected only for specified and legitimate purposes</li>
            <li>	Adequate, relevant and limited to what is necessary to fulfil the requirement of collection</li>
            <li>	Accurate, and kept updated as and when required</li>
            <li>	Kept in a manner which permits identification of data subjects for no longer than is necessary</li>
            <li>	Processed in a manner that ensures appropriate security</li>
            <li>	Provided with all required security and protection whilst it remains under the control of the company</li>
          </ul>
        </div>
        <div>
          <h2>MODERN SLAVERY & HUMAN TRAFFICKING</h2>
          <p>Product Care Group operates as a group of limited companies that collectively provide the following products and services.</p>
          <ul>
            <li>Import and distribution of household electrical appliances to retailers in the EEU and end users purchasing through e-commerce</li>
            <li>In-house media production including lifestyle video, photography and graphic design to support sales and as an externally available service</li>
            <li>Home delivery of our own products and those of partner brands to end users</li>
            <li>Third party storage and logistics of electrical appliances to end users</li>
            <li>After sales support, products and services to own customers and those of brand partners</li>
          </ul>
          <p>Our services are used by more than 800,000 customers annually across our operational sectors, and these services are achieved with circa 200 employees across 2 sites within the UK and 8 colleagues in our China office.We import own brand products from Asia and Europe, and source additional products from UK based suppliers. Our goods not for resale suppliers are predominantly based in the UK, although some suppliers have offices overseas.</p>
          <p>Our corporate responsibilities are adopted across all levels of the corporate structure and are embedded in business practices ranging from Employment Contracts, Anti Bribery & Corruption, Safeguarding, Health & Safety and Supplier & Contractor Acquisition and Management. All companies within the Group are expected to maintain compliance to all adopted responsibilities, as are Group Partners, Suppliers and Sub-Contractors.</p>
          <p>Our responsibilities and intentions towards compliance are integral to all relations, and these ensure that we are fair to our employees, who are treated fairly and with respect through the adoption of standards such as Minimum Wage and Human Rights.</p>
          <p>An in-house team responsible for management of compliance ensures that the group is consistently achieving the minimum requirements and working towards continual improvement through the adoption of annual targets and objectives which include, as a minimum, the following:</p>
          <ul>
            <li style={ { listStyle: 'decimal' } }>Pre-qualification of suppliers to ensure we only work with like-minded companies</li>
            <li style={ { listStyle: 'decimal' } }>Communicating our intentions towards all known legal requirements, to all interested parties</li>
            <li style={ { listStyle: 'decimal' } }>Identifying all risks within our supply chain and ensuring sufficient mitigation is in place</li>
            <li style={ { listStyle: 'decimal' } }>Implement a suitable program of training, awareness and information for all interested parties including allowances for consultation and participation</li>
            <li style={ { listStyle: 'decimal' } }>Adopt a whistle blowers’ policy and to ensure that all issues raised are investigated thoroughly and fairly, and if necessary, by an independent body</li>
            <li style={ { listStyle: 'decimal' } }>Where issues are discovered with suppliers, supportive actions will be implemented to remediate identified issues. Where unsatisfactory improvements are made, a more suitable working partner shall be sourced.</li>
          </ul>
          <p>All policies and schemes within the operations of Product Care Group shall be subject to continual improvement in an effort to go beyond the basic social and human rights regulations and to ensure the highest standards in our supply chain.</p>
        </div>
        <div>
          <h2>ENVIRONMENTAL POLICY STATEMENT</h2>
          <p>Throughout its operational activities, Product Care Group will seek to minimise its impact on the environment and act in a responsible manner. With a responsibility to care for and protect the environment in which we operate, the Board have authorised and appointed an Environmental & Sustainability Working Group under the leadership of the Executive Director.</p>
          <p>The Environmental & Sustainability Working Group are fully committed to improving our environmental performance through actions associated with our primary and overarching objective of reducing our carbon footprint.</p>
          <p>Product Care Group will encourage all areas of the business to</p>
          <ul>
            <li>Adopt all possible best practices and environmental standards</li>
            <li>Continually comply with all legislative and regulatory articles appliable to our activities</li>
            <li>Source and promote the use of recyclable and sustainable products and resources</li>
            <li>Continually promote and train our colleagues in sound environmental practices</li>
            <li>Include ethical, environmental, and sustainable options in all business decisions</li>
            <li>Continually assess, review, evaluate and analyse our environmental impact</li>
          </ul>
          <p>The Environmental & Sustainability Working Group operate under full transparency within the organisation and is comprised of colleagues from across the whole organisation. Targets and Objectives are set and reviewed at regular periods to drive continual improvement, with regular reporting to the Executive Board and Senior Management Teams.</p>
          <p>Current controls to help the group achieve its intended environmental outcomes include</p>
          <ul>
            <li>	Route planning software to ensure vehicle efficiency</li>
            <li>	Adoption of, and compliance with an approved WEEE Compliance Scheme</li>
            <li>	Installation of electric vehicle charging points at all operational sites</li>
            <li>	Introduction of Hybrid Working</li>
            <li>	Increased recycling of waste materials</li>
          </ul>
          <p>This policy statement is reviewed and approved by Executive Management.</p>
          <p>Version: 1.4 - Authorised By: Darren Veevers (Chief Operating Officer), 06/07/2022</p>
          <span>Signed:</span><img src={ sign } alt='sign' />
        </div>
      </div>
    </div >
  );
}

export default Home;
