import React from 'react';
import HeadImg from '../../images/icons/ico_heart.png';
function Charities() {
  return (
    <div className="charities-box">
      <h2>Community Support</h2>
      <h4>
        <img src={HeadImg} alt="HeadImg" className="HeadImg" />
        Charities
      </h4>
      <p>
        The Product Care Group has been involved with a number of charities over the years, helping to raise
        significant amounts of money for causes close to our hearts, voted for by our colleagues.
      </p>
      <p>
        As  a  company,  we  enjoy  giving  something  back  to  our  local  community  and  encourage  all  our
        employees to get involved with fundraising events wherever possible. From charity golf days to bake
        sales, sponsored head shaves to sporting events, our staff get stuck in to whatever challenges we throw
        their way, as long as it’s for a good cause!
      </p>
      <p>
        Over the past few years, we have opted to put all our efforts into raising money for specific charities
        doing vital work in our community. Our past and present chosen charities are outlined below.
      </p>
    </div>
  );
}

export default Charities;
