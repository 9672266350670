import React from 'react';

// Image
import culture_icon from '../../images/icons/ico_shake.png';

export const Culture = () => {
  return (
    <section className="culture-section">
      <div className="culture-box">
        <h4 className="popinFontH2">
          <img src={culture_icon} alt="culture_icon" />
          Culture
        </h4>
        <p className="popinFontH6">
          At the Product Care Group, we consistently strive for excellence in everything we do. Our
          people are paramount to the success of the group. The culture we have built stems from a
          mutual passion for the business and a real sense of excitement about the future. We invest
          in our people through training and development to meet key business targets and achieve
          individual goals.
        </p>
      </div>
    </section>
  );
};
