
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/pcg-logo.png";
import Bus from "../../images/icons/ico_van.png"

function NavigationBar() {
  return (
    <div>
      <header>
        <figure className="brand">
          <Link to="/"><img src={logo} alt="logo" height="70" /></Link>
        </figure>
        <nav className="menu">
          <a href="https://myorder.productcaregroup.com/login">
          <img src={Bus} alt="logo" className="menu-icon-bus" height="20px" width="35px" /></a>
          <input type="checkbox" id="menuToggle" />
          <label htmlFor="menuToggle" className="menu-icon">
            <i className="fa fa-bars"></i>
          </label>
          <ul id="main_ul">
            <Link to="/">
              <li>
                <span className="navbar-custom-nav" id="nav-font">
                  About Us
                </span>
              </li>
            </Link>
            <Link to="/what-we-do">
              <li>
                <span className="navbar-custom-nav">What We Do</span>
              </li>
            </Link>
            <Link to="/our-brands">
              <li>
                <span className="navbar-custom-nav">Our Brands</span>
              </li>
            </Link>
            <Link to="/services">
              <li>
                <span className="navbar-custom-nav">Services</span>
              </li>
            </Link>
            <Link to="/our-people">
              <li>
                <span className="navbar-custom-nav">Our People</span>
              </li>
            </Link>
            <Link to="/ethical-social-responsibility">
              <li>
                <span className="navbar-custom-nav navbar-custom-Ethical">
                  Ethical Social<br></br>
                  <span className="nav-custom-sub"> Responsibility</span>
                </span>
              </li>
            </Link>
            <Link to="/customer-bookings">
              <li>
                <span className="navbar-custom-nav navbar-custom-Ethical">
                  Customer Bookings<br></br>
                  <span className="nav-custom-sub"> and Support</span>
                </span>
              </li>
            </Link>
            <Link to="/news">
              <li>
                <span className="navbar-custom-nav">News</span>
              </li>
            </Link>
            <div id="menu-right">
              <a href="https://myorder.productcaregroup.com/login" className="bus-logo-box" target="_blank" rel="noreferrer">
                  <img src={Bus} alt="logo" className="bus-logo" height="30%" width="25%" />
              </a>
              <a href="https://myorder.productcaregroup.com/login" target="_blank" rel="noreferrer">
                <div className="menuspace">
                <li>
                  <span className="navbar-custom-nav">Book or Track a Delivery</span>
                </li>
                </div>
              </a>
            </div>
           
          </ul>
        </nav>
      </header>
    </div>
  );
}

export default React.memo(NavigationBar);
