import React from 'react';
import DeliveryDay_1 from '../../images/page-customer-bookings-and-support/delivery-day-1a.jpg';
import DeliveryDay_2 from '../../images/page-customer-bookings-and-support/delivery-day-1b.jpg';
import DeliveryDay_3 from '../../images/page-customer-bookings-and-support/delivery-day-2.jpg';
// import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrowserView, MobileOnlyView, TabletView, isIOS } from 'react-device-detect';
import yesCircle from '../../images/icons/yes.png'

function DeliveryDay() {
  return (
    <div className="delivery-day-box">
      <h4>Your Delivery Day</h4>
      <h3>
        {/* We take pride in the service we offer to you. As home delivery experts, we have high
        standards of what you should expect from us. We want your delivery experience to be a
        positive one. */}
      </h3>
      <>
        <BrowserView>
          <div className={(isIOS ? 'IpadViewNone' : 'delivery-day-sub')}>
          
            <div className="delivery-day-sub-one">
              <img src={DeliveryDay_1} alt="Delivery_7" className="DeliveryDay_1" width="50%" />
              <img src={DeliveryDay_2} alt="Delivery_7" className="DeliveryDay_2" width="70%" />
              <img src={DeliveryDay_3} alt="Delivery_7" className="DeliveryDay_3" width="70%" />
            </div>
            <div className="delivery-day-sub-two">
              <h4>What to expect from us</h4>
              <p>
                {/* <FontAwesomeIcon icon={faCheckCircle} className="delivery-day-icon" /> */}
                <img src={yesCircle} className="iconImg" alt="yes"></img>We’ll send a Skilled Home
                Delivery Technician who will deliver to the room of your choice.
              </p>
              <p>
                {/* <FontAwesomeIcon icon={faCheckCircle} className="delivery-day-icon" /> */}
                <img src={yesCircle} className="iconImg" alt="yes"></img>
                We’ll unpack your product and give you time to inspect it.
              </p>
              <p>
                {/* <FontAwesomeIcon icon={faCheckCircle} className="delivery-day-icon" /> */}
                <img src={yesCircle} className="iconImg" alt="yes"></img>
                We’ll remove packaging and take your old appliance to be recycled if this is an
                option you have selected.
              </p>
              <br />
              <p className="text-decord popinFontS4S1">
                If you’ve requested installation/assembly:
              </p>
              <p>
                {/* <FontAwesomeIcon icon={faCheckCircle} className="delivery-day-icon" /> */}
                <img src={yesCircle} className="iconImg" alt="yes"></img>
                We’ll assemble and install your new product.
              </p>
              <p>
                <img src={yesCircle} className="iconImg" alt="yes"></img>
                We’ll carry out a full evaluation of appliance positioning and the connections (if
                applicable).
              </p>
              <p>
                {/* <FontAwesomeIcon icon={faCheckCircle} className="delivery-day-icon" /> */}
                <img src={yesCircle} className="iconImg" alt="yes"></img>
                To make sure there are no problems when we deliver, we’ve listed some things you
                might need to consider before we arrive at your home.
              </p>
              <br />
              <h4>Some things to think about before we arrive</h4>
              <p>
                {/* <FontAwesomeIcon icon={faCheckCircle} className="delivery-day-icon" /> */}
                <img src={yesCircle} className="iconImg" alt="yes"></img>
                Make sure you are at home when you have arranged for us to deliver.
              </p>
              <p>
                {/* <FontAwesomeIcon icon={faCheckCircle} className="delivery-day-icon" /> */}
                <img src={yesCircle} className="iconImg" alt="yes"></img>
                Make sure we have clear access to your room of choice. This might mean moving things
                (such as furniture) that might obstruct the team from delivering your new products.
              </p>
              <br />
              <p className="text-decord popinFontS4S1">
                If you’ve requested installation/assembly:
              </p>
              <p>
                {/* <FontAwesomeIcon icon={faCheckCircle} className="delivery-day-icon" /> */}
                <img src={yesCircle} className="iconImg" alt="yes"></img>
                Check that any necessary electrical or water connections are within 1.5m of where
                you would like your appliance installed.
              </p>
              <p>
                {/* <FontAwesomeIcon icon={faCheckCircle} className="delivery-day-icon" /> */}
                <img src={yesCircle} className="iconImg" alt="yes"></img>
                If you are in any doubt, just give us a call on 0345 209 7461.
              </p>
            </div>
          </div>
        </BrowserView>
        <TabletView>
          <div className="delivery-day-sub">
            <div className="delivery-day-sub-one"></div>
            <div className="delivery-day-sub-two">
              <h4>What to expect from us</h4>
                <img src={DeliveryDay_1} alt="Delivery_7" className="DeliveryDay_1 subImg" width="57%" />
              <p>
                We’ll send a Skilled Home Delivery Technician who will deliver to the room of your
                choice.
              </p>
              <p>
                We’ll unpack your product and give you time to inspect it.
              </p>
              <p>
                We’ll remove packaging and take your old appliance to be recycled if this is an
                option you have selected
              </p>
              <p className="text-decord  mobiletext popinFontS4S1">
                If you’ve requested installation/assembly:
                <br />
              </p>
              <p>
                We’ll assemble and install your new product.
              </p>
              <p>
                We’ll carry out a full evaluation of appliance positioning and the connections (if
                applicable).
              </p>
              <p>
                To make sure there are no problems when we deliver, we’ve listed some things you
                might need to consider before we arrive at your home.
              </p>
              <h4>Some things to think about before we arrive</h4>
              <img src={DeliveryDay_3} alt="Delivery_7" className="DeliveryDay_3 subImg" width="57%" />
              <p>
                Make sure you are at home when you have arranged for us to deliver.
              </p>
              <p>
                Make sure we have clear access to your room of choice. This might mean moving things
                (such as furniture) that might obstruct the team from delivering your new products.
              </p>
              <p className="text-decord mobiletext popinFontS4S1">
                If you’ve requested installation/assembly:
              </p>
              <p>
                Check that any necessary electrical or water connections are within 1.5m of where
                you would like your appliance installed.
              </p>
              <p>
                If you are in any doubt, just give us a call on 0345 209 7461.
              </p>
            </div>
          </div>
        </TabletView>
        <MobileOnlyView>
          <div className="delivery-day-sub">
            <div className="delivery-day-sub-one"></div>
            <div className="delivery-day-sub-two">
              <h4>What to expect from us</h4>
                <img src={DeliveryDay_1} alt="Delivery_7" className="DeliveryDay_1 " width="60%" />

              <p>
                We’ll send a Skilled Home Delivery Technician who will deliver to the room of your
                choice.
              </p>
              <p>
                We’ll unpack your product and give you time to inspect it.
              </p>
              <p>
                We’ll remove packaging and take your old appliance to be recycled if this is an
                option you have selected
              </p>
              <p className="text-decord  mobiletext popinFontS4S1">
                If you’ve requested installation/assembly:
                <br />
              </p>
              <p>
                We’ll assemble and install your new product.
              </p>
              <p>
                We’ll carry out a full evaluation of appliance positioning and the connections (if
                applicable).
              </p>
              <p>
                To make sure there are no problems when we deliver, we’ve listed some things you
                might need to consider before we arrive at your home.
              </p>
              <h4>Some things to think about before we arrive</h4>
              <img src={DeliveryDay_3} alt="Delivery_7" className="DeliveryDay_3" width="60%" />
              <p>
                Make sure you are at home when you have arranged for us to deliver.
              </p>
              <p>
                Make sure we have clear access to your room of choice. This might mean moving things
                (such as furniture) that might obstruct the team from delivering your new products.
              </p>
              <p className="text-decord mobiletext popinFontS4S1">
                If you’ve requested installation/assembly:
              </p>
              <p>
                Check that any necessary electrical or water connections are within 1.5m of where
                you would like your appliance installed.
              </p>
              <p>
                If you are in any doubt, just give us a call on 0345 209 7461.
              </p>
            </div>
          </div>
        </MobileOnlyView>
      </>
    </div>
  );
}

export default DeliveryDay;
