import React from 'react';
import Banner from './banner';
import AboutUs from './about-us';
// import AboutUsFont from './aboutUsFont';
import WhatWeDo from './what-we-do';
import Evoluation from './evaluation';
import Employee from './employee';
import Group from './group';
import OurPeople from './our-people';
import AboutUsCard from './about-us-card';
import Director from './director';
import Bord from './board';
import BoardSub from './board-sub';
import NavigationBar from '../common/navigation-bar';
import TrustPilotWidget from '../trust-pilot-widget/trustPilotWidget';
function Home() {
  return (
    <div>
      <NavigationBar />
      <Banner />
      <AboutUs />
      {/* <AboutUsFont /> */ }
      <Director className="about-us-box" />
      <div className="about-us-box">
        <Bord />
        <BoardSub />
        <Evoluation />
        <AboutUsCard />
        <Employee />
        <OurPeople />
        <Group />
      </div>
      <TrustPilotWidget />
      <WhatWeDo />
    </div>
  );
}

export default Home;
