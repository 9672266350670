import React from 'react';

function Journey() {
  return (
    <section className="journey-box">
      <div className="journey">
        <h4>Our Sourcing Journey</h4>
        <div className="journey-sub">
          {/* <img src={img4} alt="journey" width="4%" height="4%" className="journey-img" /> */}
          <p>
            <b>
              <span className="journey-head">Sourcing Products: </span>
            </b>{' '}
            Between our team and 3rd party agencies, we conduct over 1300 detailed factory inspections across a 12-
            month period. We cover a wide range of product categories and are dedicated to ensuring our factories are meeting the high production standards we insist on.
            Our loyal and committed team of Inspectors, Engineers & Merchandisers oversee every stage of the supply chain from supplier approval to on time shipment through regular audits.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Journey;
