import React from 'react';
import Banner from './banner';
import BannerDetails from './banner-details';
import OurSustainability from './our-sustainability';
import EmploymentStatement from './employment-statement';
import HealthSafety from './health-safety';
import InformationSecurity from './information-security';
import Charities from './charities';
import InspireChorley from './inspire-chorley';
import DerianHouse from './derian-house';
import BoltonNeonatal from './bolton-neonatal';
import NavigationBar from '../common/navigation-bar';
import Present from './present';

function home() {
  return (
    <div>
      <NavigationBar />
      <Banner />
      <div className="about-us-box">
        <BannerDetails />
        <OurSustainability />
        <EmploymentStatement />
        {/* <HealthSafety /> */}
        {/* <InformationSecurity /> */}
        <Charities />
        <Present />
        <InspireChorley />
        <DerianHouse />
        <BoltonNeonatal />
      </div>
    </div>
  );
}

export default home;
