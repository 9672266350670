import React from 'react';
import work from '../../images/page-services/what-we-offer.jpg';

function Offer() {
  return (
    <div className="offer">
      <h4 className="text-center custom-color">What We Offer</h4>
      <img src={work} width="100%" alt="offer" height="100%" />
    </div>
  );
}

export default Offer;
