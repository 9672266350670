import React from 'react';
import group from '../../images/page-about-us/chart-group-turnover.png';

function Group() {
  return (
    <div className="group">
      <img src={group} alt="Group" />
    </div>
  );
}

export default Group;
