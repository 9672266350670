import React from 'react';
import InspireChorley1 from '../../images/page-ethical-social-resposinility/photo_chorley_youth_zone_1.jpg';
import InspireChorley2 from '../../images/page-ethical-social-resposinility/photo_chorley_youth_zone_2.jpg';
import InspireChorley3 from '../../images/page-ethical-social-resposinility/photo_chorley_youth_zone_3.jpg';
import HeadImg from '../../images/icons/ico_people3.png';
import { BrowserView, MobileOnlyView, TabletView, isIOS } from 'react-device-detect';

function InspireChorley() {
  return (
    <>
      <BrowserView>
        <div className={ (isIOS ? 'IpadViewNone' : 'inspire-chorley-box') }>

          <div className="inspire-chorley-sub">
            <h4>
              <img src={ HeadImg } alt="HeadImg" className="HeadImg" /> 2018-2021 - Inspire Chorley
              Youth Zone
            </h4>
            <p>Product Care Group are a founder patron of Onside Inspire Chorley Youth Zone and supported them
              for 3 years from their opening.  In total we contributed £75,000 to the running of their operation as well
              as giving time to participate in and support many of their activities.</p>
            <p>
              OnSide Youth Zones is a charity which supports the wellbeing, aspirations and life chances of young
              people  in  the  UK’s  most  socio-economically  deprived  areas.  Inspire  Chorley  Youth  Zone  opened  in
              May  2018  and  is  one  of  sixteen  youth  zones  which  have  been  set  up  within  the  Onside  Youth  Zone
              organisation.
            </p>
            <p>
              The Youth Zones ensure individuals aged between 8-19 years old have a safe place to go to for a small
              fee where they can make friends, get a hot meal, learn new life skills and enjoy a number of fun activities
              including  sports,  health  and  beauty  and  music.   They  also  counselling/mentoring  support  for  young
              people.
            </p>
            {/* <p>
              The activities Chorley youth zone facilitate are amazing and include a fully equipped
              gym, a boxing ring, a beauty room, a recording and music studio plus an underground 3D
              AstroTurf football pitch. The youth zone also has a confidential area to discuss
              anything personal with the wonderful team of staff and volunteers.
            </p>
            <p>
              By providing these facilities, the young people can discover new skills they may not
              have realised they had which will in turn help them gain confidence and better prepare
              them for later life. The facilities and staff are truly amazing and we are extremely
              proud to be a Founder Patron. With the Youth Zone being on our head offices door step
              we can really get involved, not only through the fundraising events we hold but also
              by providing the time and skills of our own people to coach and mentor those who visit
              the zone, as they develop into young Adults of the future.
            </p> */}
          </div>
          <div className="inspire-chorley-sub">
            <img src={ InspireChorley1 } alt="InspireChorley1" className="InspireChorley1" />
            <div className="inspire-chorley-img">
              <img src={ InspireChorley2 } alt="InspireChorley2" className="InspireChorley2" />
              <img src={ InspireChorley3 } alt="InspireChorley3" className="InspireChorley3" />
            </div>
          </div>
        </div>
      </BrowserView>
      <TabletView>
        <div className="inspire-chorley-box">
          <div className="inspire-chorley-sub">
            <h4>
              <img src={ HeadImg } alt="HeadImg" className="HeadImg" /> 2018-2021 - Inspire Chorley
              Youth Zone
            </h4>
            <p>Product Care Group are a founder patron of Onside Inspire Chorley Youth Zone and supported them
              for 3 years from their opening.  In total we contributed £75,000 to the running of their operation as well
              as giving time to participate in and support many of their activities.</p>
            <p>
              OnSide Youth Zones is a charity which supports the wellbeing, aspirations and life chances of young
              people  in  the  UK’s  most  socio-economically  deprived  areas.  Inspire  Chorley  Youth  Zone  opened  in
              May  2018  and  is  one  of  sixteen  youth  zones  which  have  been  set  up  within  the  Onside  Youth  Zone
              organisation.
            </p>
            <p>
              The Youth Zones ensure individuals aged between 8-19 years old have a safe place to go to for a small
              fee where they can make friends, get a hot meal, learn new life skills and enjoy a number of fun activities
              including  sports,  health  and  beauty  and  music.   They  also  counselling/mentoring  support  for  young
              people.
            </p>
          </div>
          <div className="inspire-chorley-sub">
            <img src={ InspireChorley1 } alt="InspireChorley1" className="InspireChorley1" />
            <div className="inspire-chorley-img">
              <img src={ InspireChorley2 } alt="InspireChorley2" className="InspireChorley2" />
              <img src={ InspireChorley3 } alt="InspireChorley3" className="InspireChorley3" />
            </div>
          </div>
        </div>
      </TabletView>
      <MobileOnlyView>
        <div className="inspire-chorley-box">
          <div className="inspire-chorley-sub">
            <h4>
              <img src={ HeadImg } alt="HeadImg" className="HeadImg" /> 2018-2021 - Inspire Chorley
              Youth Zone
            </h4>
            <div className="inspire-chorley-sub">
              <img src={ InspireChorley1 } alt="InspireChorley1" className="InspireChorley1" />
              <div className="inspire-chorley-img">
                <img src={ InspireChorley2 } alt="InspireChorley2" className="InspireChorley2" />
                <img src={ InspireChorley3 } alt="InspireChorley3" className="InspireChorley3" />
              </div>
            </div>
            <p>Product Care Group are a founder patron of Onside Inspire Chorley Youth Zone and supported them
              for 3 years from their opening.  In total we contributed £75,000 to the running of their operation as well
              as giving time to participate in and support many of their activities.</p>
            <p>
              OnSide Youth Zones is a charity which supports the wellbeing, aspirations and life chances of young
              people  in  the  UK’s  most  socio-economically  deprived  areas.  Inspire  Chorley  Youth  Zone  opened  in
              May  2018  and  is  one  of  sixteen  youth  zones  which  have  been  set  up  within  the  Onside  Youth  Zone
              organisation.
            </p>
            <p>
              The Youth Zones ensure individuals aged between 8-19 years old have a safe place to go to for a small
              fee where they can make friends, get a hot meal, learn new life skills and enjoy a number of fun activities
              including  sports,  health  and  beauty  and  music.   They  also  counselling/mentoring  support  for  young
              people.
            </p>
          </div>
        </div>
      </MobileOnlyView>
    </>
  );
}

export default InspireChorley;
