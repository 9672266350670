import React from 'react';
import img1 from '../../images/page-what-we-do/scoping.jpg';
import img3 from '../../images/page-what-we-do/design.jpg';
import img4 from '../../images/page-what-we-do/brand-dev-and-marketing.jpg';
import img5 from '../../images/page-what-we-do/quality-assurance.jpg';
import img6 from '../../images/page-what-we-do/quality-control.jpg';
import img7 from '../../images/page-what-we-do/specialist-home-delivery.jpg';
import img8 from '../../images/page-what-we-do/returns-logistics.jpg';
import img9 from '../../images/page-what-we-do/after-sales-support.jpg';
import img10 from '../../images/page-what-we-do/accountmanager.jpg';
function Challenging() {
  return (
    <div className="challenge-box">
      <h4>Challenging Industry Best Practice</h4>
      <p>
        From cradle to grave we manage the entire product journey; from sourcing and brand development, through to product delivery and returns management. We add value at every stage and delight our retailers and end users with our product proposition and after-sales support. We are also a trusted partner for retailers and
        brands, offering third party logistic solutions and services.
      </p>
      <div className="challenge">
        <div className="challenge-sub">
          <img src={img1} width="80%" height="60%" alt="challenge" />
          <h6>Scoping and Sourcing</h6>
          <p>
          We undertake an in-depth analysis of the market and our experienced teams in the UK and the Far East are
responsible for sourcing new products.
          </p>
        </div>
        <div className="challenge-sub">
        <img src={img3} width="80%" height="60%" alt="challenge" />
          <h6>Design</h6>
          <p>
            As well as innovative product design we have a team of skilled creative specialists
            offering bespoke video, photography and design.
          </p>
        </div>
        <div className="challenge-sub">
        <img src={img4} width="80%" height="60%" alt="challenge" />
          <h6>
            Brand Development<br></br> & Marketing
          </h6>
          <p>
            We liaise with brand owners to ensure that all content, including PR, meets brand
            guidelines and markets products to the highest standards.
          </p>
        </div>
      </div>
      <div className="challenge challengeHeight">
        <div className="challenge-sub">
          <img src={img10} width="80%" height="60%" alt="challenge" />
          <h6>
          Dedicated Account <br></br> Management
          </h6>
          <p>
          We allocate dedicated account managers to all our clients. They provide detailed reporting, insights and
analysis for the performance of our products.
          </p>
        </div>
        <div className="challenge-sub">
          <img src={img5} width="80%" height="60%" alt="challenge" />
          <h6>Quality Assurance</h6>
          <p>
            All of our products are manufactured to the highest standards, adopting ISO9001:2015
            principals during all manufacturing processes.
          </p>
        </div>
        <div className="challenge-sub">
          <img src={img6} width="80%" height="60%" alt="challenge" />
          <h6>IT Technical Expertise</h6>
          <p>
          Responsible for online platforms and portals, communication & data protection; We pride ourselves
on our ability to integrate with trusted 3rd parties quickly and efficiently.
          </p>
        </div>
      </div>
      <div className="challenge challengeHeight">
        <div className="challenge-sub">
          <img src={img7} width="80%" height="60%" alt="challenge" />
          <h6>Specialist Home Delivery</h6>
          <p>
            Our skilled Route Planners use the latest software to maximise delivery capacity. Our
            driver technicians are fully trained on installation and delivery processes to ensure
            maximum customer satisfaction.
          </p>
        </div>
        <div className="challenge-sub">
          <img src={img8} width="80%" height="60%" alt="challenge" />
          <h6>Returns Logistics</h6>
          <p>
            As part of our reverse logistics operation, we are committed to recycling as many
            returned products as possible and are signed up with Recycling Lives to manage our WEEE
            obligation.
          </p>
        </div>
        <div className="challenge-sub">
          <img src={img9} width="80%" height="60%" alt="challenge" />
          <h6>After Sales Support</h6>
          <p>
            Our Customer Engagement Team are trained to provide best-in-class support; from helping
            customers book their delivery, through to product guidance and customer service queries.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Challenging;
