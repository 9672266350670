import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import yesImg from "../../images/yes.png";
import NewContact from "./NewContact";
import SupportSub from "./SupportSub";
import ProductSupportSub from "./productSupportSub";
import { BrowserView, MobileOnlyView, TabletView, isIOS } from "react-device-detect";

class productSupport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showcontactus: false,
      showSupportSub: false,
      showproductSupportSub: false,
      brandName: "",
      productRegistration:"",
      brandsList: [
        {
          id: 1,
          brand_name: "Russell Hobbs",
          img: "/static/media/russell-hobbs.fdd6a9f6243680c0f5a0.png",
          productRegistration: "https://mda.russellhobbs.com/product-registration",
        },
        {
          id: 2,
          brand_name: "George Foreman",
          img: "/static/media/george-foreman.329573349f72de813525.png",
          productRegistration: "",
        },
        {
          id: 3,
          brand_name: "Rangemaster",
          img: "/static/media/rangemaster.0fd3c94ec96d910592c3.png",
          productRegistration: "https://rangemaster.sdawarranty.co.uk/",
        },
        {
          id: 4,
          brand_name: "Zanussi",
          img: "/static/media/zanussi.570b046ec620a75bdad6.png",
          productRegistration: "https://www.zanussicomfortandoutdoors.com/product_registration",
        },
        {
          id: 5,
          brand_name: "Kuhla",
          img: "/static/media/kuhla.65f663a8035491a8d5a1.png",
          productRegistration: "https://kuhla.product-registration.productcaregroup.com/",
        },
        {
          id: 6,
          brand_name: "Lowry",
          img: "/static/media/lowry.a9339c3dd14cf1a15eca.png",
          productRegistration: "https://lowry.product-registration.productcaregroup.com/",
        },
        {
          id: 7,
          brand_name: "Abode",
          img: "/static/media/abode.5b84b7cfa8f937e99b0d.png",
          productRegistration: "https://www.thisabode.co.uk/product_registration",
        },
        {
          id: 8,
          brand_name: "Holme",
          img: "/static/media/holme.b4431abe4d6b68504012.png",
          productRegistration: "",
        },
      ],
    };
  }

  hideComponent(name) {
    switch (name) {
      case "showcontactus":
        this.setState({ showcontactus: !this.state.showcontactus });
        break;
      case "showSupportSub":
        this.setState({ showSupportSub: !this.state.showSupportSub });
        break;
      default:
        return null;
    }
  }
  render() {
    const {
      showcontactus,
      showSupportSub,
      showproductSupportSub,
      brandsList,
    } = this.state;
    return (
      <>
        <BrowserView>
        <section  className={(isIOS ? 'IpadViewNone' : 'Brand-New-box')}>
          <div>
            <div>
              {showcontactus && <NewContact />}
              {showSupportSub && <SupportSub brand={this.state.brandName} productRegistration={this.state.productRegistration}/>}
              {showproductSupportSub && (
                <ProductSupportSub brand={this.state.brandName} productRegistration={this.state.productRegistration}/>
              )}
            </div>
            {!showcontactus && !showSupportSub && (
              <div>
                <article className="decision-tree">
                  <div aria-hidden="false" className="delivery-decision">
                    <img src={yesImg} alt="yes" className="yesImg" />
                    <h6 className="deliveryText font-weight-bold">
                      Product Query
                    </h6>
                    <button
                      className="change-box"
                      onClick={() =>
                        this.setState({ showcontactus: !showcontactus })
                      }
                    >
                      Change
                    </button>
                  </div>
                </article>
                {!showcontactus && !showSupportSub && (
                  <div className="brandsList">
                    <div className="grid-container">
                          {brandsList.map((brand, index) => (
                            <button
                              className="brandList-style"
                              id={index}
                              onClick={() =>
                                this.setState({
                                  showSupportSub: !showSupportSub,
                                  brandName: brand.brand_name,
                                  productRegistration: brand.productRegistration
                                })
                              }
                            >
                              <div>
                                <img
                                  src={brand.img}
                                  alt={brand.brand_name}
                                  width="100%"
                                />
                                </div>
                            </button>
                          ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          </section>
        </BrowserView>
        <TabletView>
          <section className="Brand-New-box">
          <div>
            <div>
              {showcontactus && <NewContact />}
              {showSupportSub && <SupportSub brand={this.state.brandName} productRegistration={this.state.productRegistration}/>}
              {showproductSupportSub && (
                <ProductSupportSub brand={this.state.brandName} />
              )}
            </div>
            {!showcontactus && !showSupportSub && (
              <div>
                <article className="decision-tree">
                  <div aria-hidden="false" className="delivery-decision">
                    <img src={yesImg} alt="yes" className="yesImg" />
                    <h6 className="deliveryText font-weight-bold">
                      Product Query
                    </h6>
                    <button
                      className="change-box"
                      onClick={() =>
                        this.setState({ showcontactus: !showcontactus })
                      }
                    >
                      Change
                    </button>
                  </div>
                </article>
                {!showcontactus && !showSupportSub && (
                  <div className="brandsList">
                     <div className="grid-container">
                          {brandsList.map((brand, index) => (
                            <button
                              className="brandList-style"
                              id={index}
                              onClick={() =>
                                this.setState({
                                  showSupportSub: !showSupportSub,
                                  brandName: brand.brand_name,
                                  productRegistration: brand.productRegistration
                                })
                              }
                            >
                              <div>
                                <img
                                  src={brand.img}
                                  alt={brand.brand_name}
                                  width="100%"
                                />
                                </div>
                            </button>
                          ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          </section>
        </TabletView>
        <MobileOnlyView>
        <section className="Brand-New-box">
          <div>
            <div>
              {showcontactus && <NewContact />}
              {showSupportSub && <SupportSub brand={this.state.brandName} productRegistration={this.state.productRegistration} />}
              {showproductSupportSub && (
                <ProductSupportSub brand={this.state.brandName} />
              )}
            </div>
            {!showcontactus && !showSupportSub && (
              <div>
                <article className="decision-tree">
                  <div aria-hidden="false" className="delivery-decision">
                    <img src={yesImg} alt="yes" className="yesImg" />
                    <h6 className="deliveryText font-weight-bold">
                      Product Query
                    </h6>
                    <button
                      className="change-box change-support"
                      onClick={() =>
                        this.setState({ showcontactus: !showcontactus })
                      }
                    >
                      Change
                    </button>
                  </div>
                </article>
                {!showcontactus && !showSupportSub && (
                  <div className="brandsList">
                  <div className="grid-container">
                        {brandsList.map((brand, index) => (
                          <button
                            className="brandList-style"
                            id={index}
                            onClick={() =>
                              this.setState({
                                showSupportSub: !showSupportSub,
                                brandName: brand.brand_name,
                                productRegistration: brand.productRegistration
                              })
                            }
                          >
                            <div>
                              <img
                                src={brand.img}
                                alt={brand.brand_name}
                                width="100%"
                              />
                              </div>
                          </button>
                        ))}
                  </div>
                </div>
                )}
              </div>
            )}
          </div>
          </section>
        </MobileOnlyView>
      </>
    );
  }
}

export default productSupport;
