import React from 'react';

// image
import value from '../../images/page-our-people/be-proud.png';

export const Values = () => {
  return (
    <div className="values-box">
      <div className="values-box-sub">
        <img src={value} alt="value" />
      </div>
      <div className="values-box-sub">
        <h4 className="popinFontH2">Values</h4>
        <p className="popinFontH6">
          Our values sum up not only what we will stand for in the future, but what we have proven
          we stand for now.
          Our colleagues have continuously demonstrated all of these values individually and
          collectively, and we have every reason to be <b>‘Proud’. </b>
          We encourage everyone within the group to embrace our positive, inclusive and supportive
          culture and to live and breathe our values.
        </p>
        <p>
          <h6 className="bold popinFontH4">One Team, One Vision</h6>
        </p>
        <a href="#vacancies">
          <button className="popinFontH5">Current vacancies</button>
        </a>
      </div>
    </div>
  );
};
