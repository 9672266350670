import React, { useState } from 'react';
import Banner from './banner';
import Book from './book';
import ProductSupport from './product-support';
import DeliveryTimeline from './delivery-timeline';
import DeliveryBox from './delivery-box';
// import Message from './message';
import AdditionalServices from './additional-services';
import Product from './product';
import DeliveryDay from './delivery-day';
import NavigationBar from '../common/navigation-bar';
import TrustPilotWidget from '../trust-pilot-widget/trustPilotWidget';



function Home() {
  const [isLoaded] = useState(true);

  return (
    <div>
      <NavigationBar />
      <Banner />
      <div className="about-us-box">
        <Book />
        <ProductSupport />
        <DeliveryBox />
        <Product isLoaded={isLoaded} />
        <DeliveryTimeline />
        <DeliveryDay />
        <AdditionalServices />
        <TrustPilotWidget />
      </div>
    </div>
  );
}

export default Home;
