import React from 'react';
import image6 from '../../images/page-services/delivery-booking-and-tracking.png';
function Tracking() {
  return (
    <div className="Tracking-box">
      <div className="Tracking-item">
        <h4 className="delivery-txt custom-color">Delivery Booking and Tracking</h4>
        <p className="custom-color">
          Our bespoke delivery booking portal enables customers to book a delivery date up to 4
          weeks in advance, and our order tracking software keeps customers up-to-date with progress
          from order placement through to delivery.
        </p>
          <a href="https://myorder.productcaregroup.com " target="_blank" rel="noreferrer">
          <button className="delivery-book">
            Book or track your delivery
            </button>
          </a>
        <br></br>
        {/* <button className="delivery-Track">
          <a href="https://myorder.productcaregroup.com " target="_blank" rel="noreferrer">
            Track your delivery
          </a>
        </button> */}
      </div>
      <div className="Tracking-item">
        <img src={image6} alt="mobile" width="50%" height="100%" />
      </div>
    </div>
  );
}

export default Tracking;
