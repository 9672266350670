import React from 'react';
import image1 from '../../images/page-services/fleet-vehicle-management-2.jpg';
import image2 from '../../images/page-services/white-glove-home-delivery-service.jpg';
import image3 from '../../images/page-services/electronic-proof-of-delivery.jpg';
import image4 from '../../images/page-services/skilled-delivery-technicians.jpg';
import image5 from '../../images/page-services/on-time-delivery.jpg';

function AboutUs() {
  return (
    <section>
      <div className="about-us">
        <div className="item-one">
          <img className="card-img-top about-us-card-img" src={image3} alt="Card one" />
          <h5 className="custom-color">
            Electronic Proof of Delivery
          </h5>
          <p className="card-text card-custom-txt custom-color">
            We utilise an electronic proof of
            timings and signatures, enabling order status updates to be provided.
          </p>
        </div>
        <div className="item-one">
          <img className="card-img-top about-us-card-img" src={image5} alt="Card two" />
          <h5 className="custom-color">On-time Delivery</h5>
          <p className="card-text card-custom-txt about-message custom-color">
            With support from specialist
            software, our highly trained
            Transport Team plan and optimise routes to ensure on-time delivery.
          </p>
        </div>
        <div className="item-one">
          <img className="card-img-top about-us-card-img" src={image4} alt="Card three" />
          <h5 className="custom-color">
            Skilled Delivery Technicians
          </h5>
          <p className="card-text card-custom-txt custom-color">
            Extensive training is provided in our Training Academy. Our skilled
            delivery technicians undergo competency assessments to guarantee service standards are consistently met.
          </p>
        </div>
      </div>
      <div className="about-us-sub">
        <div className="item-one">
          <img className="card-img-top   about-us-card-img-sub Subipadimg" src={image2} alt="Card four" />
          <h5 className="custom-color">
            White Glove Home Delivery Service
          </h5>
          <p className="card-text card-custom-txt  custom-color">
            Our professional service offer includes:
             delivery to the room of choice,
            unpacking and removal of packaging,
             installation and removal of the old
            appliance.
          </p>
        </div>
        <div className="item-one">
          <img className="card-img-top about-us-card-img-sub Subipadimg" src={image1} alt="Card five" />
          <h5 className="custom-color">
            Fleet and Vehicle Management
          </h5>
          <p className="card-text card-custom-txt custom-color">
            Our fleet is modern, Product Care
            branded and appropriately equipped to
            contribute towards Right First Time outcomes.
          </p>
        </div>
      </div>
    </section>
  );
}

export default React.memo(AboutUs);
