import React from 'react';

function OfferingQuality() {
  return (
    <div className="OfferingQuality-box">
      <h4>Offering quality products and creating solutions for our evolving customers…</h4>
      <p>
      The Product Care Group specialises in consumer products, managing and distributing market-leading licenced and partner brands throughout the UK and internationally. 
      We supply quality, innovative products across a range of categories: Large Appliances, Small Domestic Appliances, Microwaves, Floorcare, BBQs, Pizza Ovens, Heating, Cooling and Air Treatment.
      </p>
    </div>
  );
}

export default OfferingQuality;
