import React from 'react';

function Book() {
  return (
    <div className="book-box">
      <h4>Book Your Delivery Here</h4>
      <p>
        We want to take the confusion out of the delivery process by keeping
        you informed every step of the way.
      </p>
    </div>
  );
}

export default Book;
