import React from 'react';

import Careers_img from '../../images/page-our-people/careers.jpg';
function Careers({ isLoaded }) {
  return (
    <div>
    <div className="careers-sec" id="careers">
      <div className="careers-box">
        <h4 className="popinFontH2">Careers at the Product Care Group</h4>
        <p className="popinFontH5">
          As the group continues to grow, we are always on the lookout for great talent – people who
          are as passionate as we are and, just as importantly, embrace change and innovation. We
          have a real sense of excitement about the future and the possibilities it can bring.
        </p>
        <img src={Careers_img} width="100%" height="100%" alt="Careers" />
        <p className="popinFontH5">
          If you have the imagination and dedication to help take us to the next level, take a look
          at our current job vacancies.<br></br> We look forward to hearing from you.
        </p>
        <br></br>
      </div>
    </div>
    <div>
      <br></br>
    </div>   
    </div>
  );
}

export default Careers;
