import Banner from "../about-us/banner";
import NavigationBar from '../common/navigation-bar';
import { Link } from "react-router-dom";
import React from 'react'
import './styles.scss'

export default function index() {
    return (
        <div>
            <NavigationBar />
            <Banner />
            <div className="generic-page-content privacy-policy-box">
                <div className="margindata">
                    <div className="pro-text">
                        <h1>THE PRODUCT CARE GROUP PRIVACY POLICY</h1>
                        <p>This Privacy Policy relates to the entities which form the Product Care Group, as may change from time to time. At the point in time in which this Policy was last updated, this includes:</p>
                        <ul>
                            <li>Product Care Group Limited;</li>
                            <li> Product Care Trading Limited;</li>
                            <li> Product Care Services Limited;</li>
                            <li>Product Care Hub Limited; and</li>
                            <li>Product Care Online Limited.</li>
                        </ul>
                        <p>It is important that you read this Privacy Policy together with any other privacy policy or fair processing policy we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This Privacy Policy supplements other notices and privacy policies and is not intended to override them.</p>
                    </div>
                    <div className="contc-deti">
                        <h3>OUR CONTACT DETAILS</h3>
                        <p>Product Care Group Limited</p>
                        <p>Unit 1, Greenbank Business Park</p>
                        <p>Swan Lane</p>
                        <p>Hindley Green</p>
                        <p>Wigan</p>
                        <p>WN2 4AY</p>
                    </div>
                    <div className="tel">
                        <p>Tel: <a href="tel:01942521918" target="_blank">01942 521 918</a></p>
                        <p>E-mail : <a href="mailto:compliance@productcaregroup.com" target="_blank">compliance@productcaregroup.com</a></p>
                        <p>Website : <a href="https://www.productcaregroup.com" target="_blank"> www.productcaregroup.com</a></p>
                    </div>
                    <p>The Product Care Group is made up of different legal entities, details of which can be found here (<a href="https://www.productcaregroup.com" target="_blank"> www.productcaregroup.com</a>). This Privacy Policy is issued on behalf of the Product Care Group so when we mention "Product Care", "we", "us" or "our" in this Privacy Policy, we are referring to the relevant company in the Product Care Group responsible for processing your data. When you purchase a product or service with us, the controller for your data will be the entity with which you place your order. Product Care Group Limited is the controller and responsible for this website.</p>
                    <p>Our Group Risk and Compliance Manager is responsible for overseeing questions in relation to this Privacy policy. If you have any questions about this Privacy Policy, including any requests to exercise your legal rights, please contact us using the contact details above.</p>
                    <div className="contc-deti">
                        <h3>INFORMATION COVERED BY THIS POLICY</h3>
                    </div>
                    <p>This website is not intended for children and we do not knowingly collect data relating to children.</p>
                    <p>Product Care respects your privacy and is committed to protecting your personal data. This Privacy Policy will inform you as to how we look after your personal data when you visit our website (regardless of where you visit it from) and tell you about your privacy rights and how the law protects you.</p>
                    <p>This Policy aims to give you information on how Product Care collects and processes your personal data and provides governance for our treatment of personal information collected, such as that information which is:</p>
                    <span>automatically collected by our websites, such as preferences selected by the visitor; and
                        manually provided by visitors to our websites when requesting services, purchasing products, registering products or submitting an enquiry which requires a response.</span>
                    <div className="contc-deti">
                        <h3>TYPES OF PERSONAL INFORMATION WE COLLECT</h3>
                    </div>
                    <p>Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).</p>
                    <p>We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:</p>
                    <ul>
                        <li> <b>Identity Data</b> includes first name, maiden name, last name, username or similar identifier, title, date of birth and gender.</li>
                        <li><b>Contact Data</b> includes billing address, delivery address, email address and telephone numbers.</li>
                        <li><b>Financial Data</b> includes bank account and payment card details.</li>
                        <li><b>Transaction Data</b> includes details about payments to and from you and other details of products and services you have purchased from us.</li>
                        <li><b>Technical Data </b>includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.</li>
                        <li><b>Profile Data</b> includes your username and password, purchases or orders made by you, your interests, preferences, feedback and survey responses.</li>
                        <li><b>Usage Data</b> includes information about how you use our website, products and services.</li>
                        <li><b>Marketing and Communications Data</b> includes your preferences in receiving marketing from us and our third parties and your communication preferences.</li>
                    </ul>
                    <p>We also collect, use and share <b>Aggregated Data</b> such as statistical or demographic data for any purpose. Aggregated Data could be derived from your personal data but is not considered personal data in law as this data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this Privacy Policy.</p>
                    <p>We do not collect any Special <b>Categories of Personal Data</b> about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.</p>
                    <p><b>IF YOU FAIL TO PROVIDE PERSONAL DATA</b></p>
                    <p>Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with goods or services). In this case, we may have to cancel a product or service you have with us but we will notify you if this is the case at the time.</p>
                    <div className="contc-deti">
                        <h3>HOW WE COLLECT THIS INFORMATION </h3>
                    </div>
                    <p>The majority of personal information we collect is provided either:</p>
                    <ul>
                        <li>
                            directly by your through interaction with our websites, retail platforms or delivery booking portals;
                        </li>
                        <li>
                            automatically by any systems through which access to our websites is gained (see “information collected Automatically” below); and / or indirectly by a company through whom we have a contract to provide goods or services to you.
                        </li>
                    </ul>
                    <p><b>Information Collected Automatically</b></p>
                    <p>Interaction with our websites or delivery booking portals will generate server logs from your visit, which may record the browser used (including Google, Firefox and Edge), your IP address and other information such as the pages you visit in order to record your preferences to make subsequent visits more user friendly, through transfer of data through to your terminal in the form of cookies. This information helps us understand which parts of our websites are visited and by how many people, which in turn can help us make any visit to our websites more accessible and of use to everyone.</p>
                    <p>The Product Care Group operates several websites as part of our business. Each website uses various cookies that may differ from one another. If you wish to review the cookies policy for any of our websites, please visit the corresponding website and select the "Cookies Policy" link to access detailed information on the specific cookies used on that website.</p>
                    <p>By continuing to use any of our websites, you agree to the use of cookies in accordance with our cookies policy. Please note that any changes made to our cookies policy will be reflected on our websites, and your continued use of any of our websites after such changes will indicate your acceptance of the updated cookies policy.</p>
                    <p>You can change your own preferences as to the use of cookies through the “system” settings on your device, but this may prevent access to some of our website features.</p>
                    <p><b>Information provided By You</b></p>
                    <p>We will receive and store any information you knowingly provide for us in order to complete any contracted service, request for information or product purchases. Such information will usually be limited to Identity Data, Contact Data, Financial Data, Transaction Data and Profile Data.</p>
                    <p>Some of our website features may require you to create a profile requiring a log-in or username and a password. Such information will be stored on our systems to ensure your return visits are hassle-free, but you can also change this option on your device by enabling/disabling the “remember me” or “keep me signed in” functions.</p>
                    <div className="contc-deti">
                        <h3>WHY WE HAVE, OR NEED, THIS INFORMATION  </h3>
                    </div>
                    <p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</p>
                    <ul>
                        <li>where we need to perform the contract we are about to enter into or have entered into with you;</li>
                        <li>where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests; and / or  where we need to comply with a legal obligation.</li>
                    </ul>
                    <p>The lawful basis that we rely on for processing personal information includes: </p>
                    <ul>
                        <li><b>Consent of data Subject</b> You may withdraw your consent at any time by contacting us using the contact details on the front page of this document</li>
                        <li><b>We have a contractual obligation.</b> Processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.</li>
                        <li><b> We have a legal obligation. </b>Processing your personal data where it is necessary for compliance with a legal obligation that we are subject to.</li>
                    </ul>
                    <p><b>We have a legitimate interest to do so. </b>The interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.</p>
                    <p>We have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.</p>
                    <p>Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please contact us if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below.</p>
                    <table>
                        <thead>
                            <tr>
                                <th>Purpose/Activity</th>
                                <th>Type of data</th>
                                <th>Lawful basis for processing including basis of legitimate interest</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>To register you as a new customer</td>
                                <td>(a) Identity<br />
                                    (b) Contact
                                </td>
                                <td>Performance of a contract with you</td>
                            </tr>
                            <tr>
                                <td>To process and deliver your order including:<br />(a) Manage payments, fees and charges<br />
                                    (b) Collect and recover money owed to us
                                </td>
                                <td>(a) Identity<br />
                                    (b) Contact<br />
                                    (c) Financial<br />
                                    (d) Transaction<br />
                                    (e) Marketing and Communications<br />
                                </td>
                                <td>(a) Performance of a contract with you<br />
                                    (b) Necessary for our legitimate interests (to recover debts due to us)<br />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    To manage our relationship with you which will include:<br />(a) Notifying you about changes to our terms or Privacy Policy<br />
                                    (b) Asking you to leave a review or take a survey

                                </td>
                                <td>
                                    (a) Identity<br />
                                    (b) Contact<br />
                                    (c) Profile<br />
                                    (d) Marketing and Communications<br />
                                </td>
                                <td>(a) Performance of a contract with you<br />
                                    (b) Necessary to comply with a legal obligation<br />
                                    (c) Necessary for our legitimate interests (to keep our records updated and to study how customers use our products/services)
                                </td>
                            </tr>
                            <tr>
                                <td>To manage, register or hold information on any product related warranties or extra services requested by you, including to  hold this information for the unlikely event of product recalls or safety notices that must be issued and to assist in processing any action under any warranty scheme.</td>
                                <td>(a) Identity<br />
                                    (b) Contact<br />
                                    (c) Profile<br />
                                    (d) Transaction<br />
                                    (e) Marketing and Communications
                                </td>
                                <td>
                                    (a) Performance of a contract with you<br />
                                    (b) Necessary to comply with a legal obligation

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    To use data analytics to improve our website, products/services, marketing, customer relationships and experiences
                                </td>
                                <td>
                                    (a) Technical<br />
                                    (b) Usage

                                </td>
                                <td>
                                    Necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated and relevant, to develop our business and to inform our marketing strategy)
                                </td>
                            </tr>
                            <tr>
                                <td>To make suggestions and recommendations to you about goods or services that may be of interest to you</td>
                                <td>
                                    (a) Identity<br />
                                    (b) Contact<br />
                                    (c) Technical<br />
                                    (d) Usage<br />
                                    (e) Profile<br />
                                    (f) Marketing and Communications<br />

                                </td>
                                <td>
                                    Necessary for our legitimate interests (to develop our products/services and grow our business)
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>As a provider of goods or services where a financial transaction is involved, we are also required to retain details as to those financial transactions in line with relevant taxation and financial accounting laws. Personal and other Information retained may include Identity Data, Contact Data, Transaction Data and Financial Data. At no point will credit or debit card numbers, start/expiry dates or security numbers be retained.</p>
                    <p>Where a request for data deletion is received relating to data, we are legally required to retain for a certain period (e.g., for financial and taxation purposes), we will anonymise all relevant data, but retain the data required to retain compliance with the governing legislation.</p>
                    <p><b>CHANGE OF PURPOSE</b></p>
                    <p>We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us.</p>
                    <p>If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.</p>
                    <p>Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.</p>
                    <div className="contc-deti">
                        <h3>SHARING OF DATA </h3>
                    </div>
                    <p>Circumstances in which we may share your data include:</p>
                    <ul>
                        <li>utilisation of third party delivery agents where we cannot deliver goods;</li>
                        <li>where a legal requirement enforces us to do so;</li>
                        <li>where you provide specific consent for us to do so; and / or </li>
                        <li>in order to discuss the provision of services or goods to you, with any relevant and /or reasonably interested parties. This may include any company for whom we are acting as a delivery agent, where your original transaction or contract was arranged through them.</li>
                    </ul>
                    <p>We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>
                    <p>We may share your persona data within the Product Care Group for the purposes set out in this Policy. Where we do so, we ensure that your personal data is protected by requiring all members of the Product Care Group to follow the same rules when processing your personal data.</p>
                    <p>We may transfer your data outside of the UK. Whenever we transfer your personal data out of the UK, we ensure a similar degree of protection is afforded to it.</p>
                    <p>Please note that our website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy of every website you visit.</p>
                    <div className="contc-deti">
                        <h3>HOW WE STORE PERSONAL INFORMATION. </h3>
                    </div>
                    <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</p>
                    <p>All personal information is securely stored on suitably provisioned systems, either cloud-based or provided by 3rd party suppliers, or on physically accessible servers on one or more of our operational sites, over which we have complete control. </p>
                    <p>We are certified to ISO/IEC 27001: 2013 Information Security Management Systems, and conduct regular internal audits, as well as subjecting our processes and policies to rigorously applied audits provided by 3rd party certification bodies. We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>
                    <p>All personal information will only be retained for as long as is required to fulfill any arrangement with you, for the provision of services or registration and management of product warranties, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. Where a financial transaction has taken place between us and you, records of this will be kept for no more than 6 years after the end of the financial year in which the transaction took place. Our financial years are laid out from 1 July to 30 June. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.</p>
                    <p>Where you register for any marketing, mailing, promotion or other special offer/services, your data will be retained until such a time as: </p>
                    <ul>
                        <li>you withdraw your consent (see Data Protection Rights below); </li>
                        <li>you exercise your right to be forgotten (see Data Protection Rights below); or</li>
                        <li>the purpose for which consent was gained has been completed.</li>
                    </ul>
                    <div className="contc-deti">
                        <h3>DATA PROTECTION RIGHTS </h3>
                    </div>
                    <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data. This includes:</p>
                    <p>a.   <b>Right of access</b> to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it. </p>
                    <p>b.  <b> Right to rectification</b> of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</p>
                    <p>c.   <b>Right to erasure</b> of your personal data. Also known as the “right to be forgotten”. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</p>
                    <p>d.   <b>Right to restriction of processing</b> of your personal data. This enables you to ask us to suspend the processing of your personal data if you want us to establish the data’s accuracy, where our use if unlawful but you do not want us to erase it, where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims or if you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</p>
                    <p>e.   <b>Right to object to processing</b> of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</p>
                    <p>f.   <b>Request the transfer</b> of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you. </p>
                    <p>g.   <b>Withdraw consent at any time </b>where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</p>
                    <p>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.</p>
                    <p>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>
                    <p>We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>
                    <div className="contc-deti">
                        <h3>DELETION OF DATA </h3>
                    </div>
                    <p>At any time where data is deleted, this shall be done securely and in accordance with all known legal and regulatory requirements, as well as in line with our Data Retention, Storage and Disposition Policy under our ISO/IEC 27-001: 2013 Information Security Management System. </p>
                    <div className="contc-deti">
                        <h3>
                            HOW TO COMPLAIN
                        </h3>
                    </div>
                    <p>If you have any concerns about our use, storage, use or deletion of your data, you should in the first instance contact us through the details on the front page of this document.</p>
                    <p>You may also complain to the Information Commissioners Office, if you have raised concerns with us, that you feel have not been suitably addressed.</p>
                    <p>The Information Commissioners Office <br />
                        Wycliffe House <br />
                        Water lane<br />
                        Wilmslow <br />
                        Cheshire<br />
                        SK9 5AF
                    </p>
                    <div className="tel">

                        <p>Helpline Number : <a href="tel:03031231113" target="_blank"> 0303 123 1113</a></p>
                        <p>ICO Website : <a href="https://www.ico.org.uk" target="_blank"> https://www.ico.org.uk </a></p>
                    </div>
                    <div className="contc-deti">
                        <h3>
                            CHANGES TO THE PRIVACY POLICY AND YOUR DUTY TO INFORM US OF CHANGES
                        </h3>
                    </div>
                    <p>We keep our Privacy Policy under regular review. This version was last updated on { new Date().toLocaleString("en-US", { day: '2-digit' }) }/{ new Date().toLocaleString("en-US", { month: '2-digit' }) }/{ new Date().getFullYear() }. </p>
                    <p>It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.</p>
                </div>
            </div>
        </div>
    )
}
