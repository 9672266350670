import React from 'react';

// Images
import img1 from '../../images/page-our-people/people-asif.jpg';
import img2 from '../../images/page-our-people/people-lauren.jpg';
import img3 from '../../images/page-our-people/people-louis.jpg';
import img4 from '../../images/page-our-people/people-nasrin.jpg';
import img5 from '../../images/page-our-people/people-andy.jpg';
import img6 from '../../images/page-our-people/people-gabby.jpg';

export const OurPeopleDetails = () => {
  return (
    <section className="our-people-details-box">
      <div className="our-people-details-sec-one">
        <div className="our-people-details-sec-sub-one">
          <img src={img1} alt="asif" />
          <h6 className="popinFontH4">Asif, joined in 2014</h6>
          <p className="popinFontH5">
            I started working for the group 7 years ago as a Management Accountant. When I first
            joined, I worked in a small team of 4 people, which allowed me to work ‘hands on’,
            focusing on many different tasks. This meant I could broaden my skills and learn new
            aspects of the business. I was promoted to Financial Controller at the start of 2019,
            and I now manage a team of 7. My hard working, positive attitude has allowed me to
            further my own expertise, as well as help support and build the skillset of my own team.
          </p>
        </div>
        <div className="our-people-details-sec-sub-one">
          <img src={img2} alt="lauren" />
          <h6 className="popinFontH4">Nasrin, joined 2014</h6>
          <p className="popinFontH5">
            I started as a Trading Assistant in Sales and now lead the Commercial Team. The business
            has supported me to pursue my ambitions and take on roles that will progress my career.
            I think it is important to keep asking questions and keep learning as well as
            maintaining a positive outlook regardless of the hurdles and challenges that you face.
            My ambition has been recognised and being supported by the group has helped me deliver
            better outcomes for myself and for my team.
          </p>
        </div>
        <div className="our-people-details-sec-sub-one">
          <img src={img3} alt="louis" />
          <h6 className="popinFontH4">Louis, joined in 2016</h6>
          <p className="popinFontH5">
            I started in the role of Buyer supporting the Trading Manager. I now hold full
            responsibility for all branded relationships and lead on integrating new brands and
            categories into the group portfolio. One of the key characteristics needed to succeed in
            the business is open mindedness and having respect for your colleagues and clients. This
            means, not only being prepared to take on board the views of both brands and retailers,
            but also being receptive to new directions that the business takes.{' '}
          </p>
        </div>
      </div>
      <div className="our-people-details-sec-two">
        <div className="our-people-details-sec-sub-two">
          <img src={img4} alt="nasrin" />
          <h6 className="popinFontH4">Lauren, joined in 2014</h6>
          <p className="popinFontH5">
            I started as an Apprentice in the Finance department. With the help and support from the
            business, I completed my apprenticeship and am now part of the Supply Team working as a
            Supply Chain Analyst. The business looks to support employee’s ambitions, helping them
            to develop in their careers through training opportunities and additional
            responsibilities that help grow their confidence. I have adapted over the years, learnt
            new skills and grown with the company.
          </p>
        </div>
        <div className="our-people-details-sec-sub-two">
          <img src={img5} alt="nasrin" />
          <h6 className="popinFontH4">Andy, joined in 2016</h6>
          <p className="popinFontH5">
            I joined the group with the goal of creating and running an internal IT services team.
            Since then, the company has changed shape many times, and the IT team I created
            continues to provide all areas of the business with the committed service needed to
            ensure changes occur smoothly and well controlled, regardless of how challenging the
            task! In return, the company has supported my team with a resource commitment, which has
            allowed us to move forward with new technologies and implement best practices ensuring
            we are fit for the future.
          </p>
        </div>
        <div className="our-people-details-sec-sub-two">
          <img src={img6} alt="elle" />
          <h6 className="popinFontH4">Gabby, joined in 2022</h6>
          <p className="popinFontH5">
          I joined PCG as a Sales Analyst and transitioned to working as an Account Executive across multiple accounts. Working within different roles in the sales team has allowed me to gain a deeper understanding of different aspects of the business. Interacting with different people on a daily basis makes my job exciting, and being part of a supportive and friendly team boosts my confidence. I look forward to working on additional accounts over the next few months and aspire to become an Account Manager. My ultimate goal is to become a National Account Manager.
          </p>
        </div>
      </div>
    </section>
  );
};
