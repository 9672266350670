import React from 'react';

function BannerDetails() {
  return (
    <div className="ethical-social-details-box">
      <p>
        The Product Care Group believe that we have a responsibility to care for and protect the
        environment, our staff, customers and the community. Therefore, we are fully committed to
        providing fairness, honesty and equality across all our business activities, our workplace,
        information security and working towards protecting the environment. We encourage our
        business partners and members of the wider community to join us in this effort. These are
        our key priority areas where we have measurable targets in place.
      </p>
    </div>
  );
}

export default BannerDetails;
